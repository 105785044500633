<div class="number-component">
  <p class="name-count" *ngIf="fieldName">
    <span>{{fieldName}}</span>
  </p>
  <div class="email-input-container">
    <input type="email" 
      [placeholder]="placeholder" 
      [(ngModel)]="inputEmail" 
      [disabled]="disabled"
      (keypress)="checkLength($event)" 
      (keyup)="restrictLength()"
      (keyup.enter)="enterClicked.emit(inputEmail.toLowerCase())"
      (focusout)="focusout.emit(true)"
      (ngModelChange)="emailChange.emit(inputEmail.toLowerCase())">
      <span class="text-count" *ngIf="maxChars">{{inputEmail?.length}}/{{maxChars}}</span>
  </div>
</div>