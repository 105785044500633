<div class="container-fluid">
  <div class="container-heading">
    <div class="logo-wrapper">
      <img [src]="agencyService.darkLogo" />
    </div>
  </div>
  <div class="container-body">
    <div class="logo-content-wrapper">
      <div class="content-wrapper">
        <p class="error-code">404</p>
        <h1 class="heading">Page not found</h1>
        <p class="heading-message">This is not the web page you are looking for.</p>
        <save-button [saveButtonText]="'Go Home'" (saveClicked)="routeToChats()"></save-button>
      </div>
    </div>
  </div>
</div>