export class Agent {
  private _id: number;
  private _name: string;
  private _email: string;
  private _is_active: boolean;
  private _date_joined: Date;
  private _user_type: number;
  private _accepted: boolean;

    public get accepted(): boolean {
        return this._accepted;
    }

    public set accepted(accepted: boolean) {
        this._accepted = accepted;
    }

  public get id(): number {
    return this._id;
  }

  public set id(id: number) {
    this._id = id;
  }

  public get name(): string {
    return this._name;
  }

  public set name(name: string) {
    this._name = name;
  }

  public get email(): string {
    return this._email;
  }

  public set email(email: string) {
    this._email = email;
  }

  public get is_active(): boolean {
    return this._is_active;
  }

  public set is_active(is_active: boolean) {
    this._is_active = is_active;
  }

  public get date_joined(): Date {
    return this._date_joined;
  }

  public set date_joined(date_joined: Date) {
    this._date_joined = date_joined;
  }

  public get user_type(): number {
    return this._user_type;
  }

  public set user_type(user_type: number) {
    this._user_type = user_type;
  }

  constructor(teamMemberJson: any) {
    this._id = teamMemberJson?.id;
    this._name = teamMemberJson?.name;
    this._email = teamMemberJson?.email;
    this._is_active = teamMemberJson?.is_active;
    this._date_joined = teamMemberJson?.date_joined;
    this._user_type = teamMemberJson?.user_type;
    this._accepted = teamMemberJson?.accepted;
  }
}