import { Component, Input, OnInit } from '@angular/core';
import { UserStatus } from 'src/app/constants';

@Component({
  selector: 'expiry-icon',
  templateUrl: './expiry-icon.component.html',
  styleUrls: ['./expiry-icon.component.css']
})
export class ExpiryIconComponent implements OnInit {

  @Input() name!: string | undefined;
  letters: string = '';
  

  constructor() { }

  ngOnInit(): void {
    if(this.name) {
      let words = this.name.split(' ');
      console.log(words)
      if(words?.length > 1) {
        this.letters = words[0][0] + words[1][0];
        this.letters = this.letters.toUpperCase();
      } else {
        this.letters = words[0].substring(0,2).toUpperCase();
      }
    }
  }

  ngOnChanges(): void {
    this.ngOnInit();
  }

  
}
