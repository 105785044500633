<div class="main-container" *ngIf="plansScreen">
  <div class="expiry-header">
    <button hlmBtn variant='ghost' (click)="plansScreen = false">
      <mat-icon style="width: 16px;margin-right: 8px;" svgIcon="arrow_back"></mat-icon>
      Back
    </button>
    <button hlmBtn variant='ghost' (click)="logout()">Logout</button>
  </div>
  <div class="agent-details">
    <div class="card-fields" style="align-items: center;">
      <span class="heading-subscription">Choose the plan that’s right for you</span>
      <span class="subheading">Flexible Pricing to Fit Your Unique Needs</span>
    </div>
    <hlm-tabs tab='monthly' class='block mx-auto'>
      <hlm-tabs-list style="width: fit-content;margin: 0 calc(50% - 160px);min-width: 180px;" class='w-full grid grid-cols-2' aria-label='tabs example'>
        <button hlmTabsTrigger='monthly'>Monthly</button>
        <button hlmTabsTrigger='annually'>
          <div class="tab-percentage">
            Annually 
            <span *ngIf="agencyService.brand.toLowerCase() !== 'oneinbox'">Save {{percentage}}%</span>
            <span *ngIf="agencyService.brand.toLowerCase() === 'oneinbox'">Save 30%</span>
          </div>
        </button>
      </hlm-tabs-list>
      <div hlmTabsContent='monthly'>
        <div class="plans-list">
          <div class="plan-card" [ngClass]="plan?.is_recommended ? 'recommended' : ''" *ngFor="let plan of subscriptionService.plans; let i = index">
            <div class="plan-details">
              <div class="plan-title">
                <div>
                  <span class="plan-name">{{plan?.name}}</span>
                  <!-- <span class="plan-description">{{plan?.description}}</span> -->
                </div>
                <span *ngIf="plan?.is_recommended" class="recommended-tag">Most popular</span>
              </div>
              <span class="plan-price">${{plan?.monthly_price}}/m</span>
              <div class="plan-features">
                <ng-container *ngFor="let feature of plan?.features">
                  <div class="plan-feature-description">
                    <mat-icon [ngClass]="feature?.is_available ? 'check-icon' : 'cross-icon'" [svgIcon]="feature?.is_available ? 'check' : 'x'"></mat-icon>
                    <span>
                      {{feature?.text}}
                    </span>
                  </div>
                </ng-container>
                <ng-container *ngFor="let addon of plan?.add_ons">
                  <div class="plan-feature-description">
                    <mat-icon class="add-icon" svgIcon="add"></mat-icon>
                    <span>
                      {{addon?.text}}
                    </span>
                  </div>
                </ng-container>
              </div>
            </div>
            <save-button
              class="w-100"
              *ngIf="plan?.is_recommended" 
              [saveButtonText]="subscriptionService?.currentPlan?.id === plan?.id ? 'Current plan' : ('Buy '+ plan?.name)"
              (saveClicked)="buyPlan(plan, 'monthly')"
              [loading]="loaderBuyPlan === plan?.id"
            ></save-button>
            <cancel-button 
              *ngIf="!plan?.is_recommended" 
              [cancelButtonText]="subscriptionService?.currentPlan?.id === plan?.id ? 'Current plan' : ('Buy '+ plan?.name)"
              (cancelClicked)="buyPlan(plan, 'monthly')"
              [loading]="loaderBuyPlan === plan?.id"
            ></cancel-button>
          </div>
        </div>
      </div>
      <div hlmTabsContent='annually'>
        <div class="plans-list">
          <div class="plan-card" [ngClass]="plan?.is_recommended ? 'recommended' : ''" *ngFor="let plan of subscriptionService.plans; let i = index">
            <div class="plan-details">
              <div class="plan-title">
                <div>
                  <span class="plan-name">{{plan?.name}}</span>
                  <!-- <span class="plan-description">{{plan?.description}}</span> -->
                </div>
                <span *ngIf="plan?.is_recommended" class="recommended-tag">Most popular</span>
              </div>
              <span class="plan-price">${{plan?.yearly_price}}/m</span>
              <div class="plan-features">
                <ng-container *ngFor="let feature of plan?.features">
                  <div class="plan-feature-description">
                    <mat-icon [ngClass]="feature?.is_available ? 'check-icon' : 'cross-icon'" [svgIcon]="feature?.is_available ? 'check' : 'x'"></mat-icon>
                    <span>
                      {{feature?.text}}
                    </span>
                  </div>
                </ng-container>
                <ng-container *ngFor="let addon of plan?.add_ons">
                  <div class="plan-feature-description">
                    <mat-icon class="add-icon" svgIcon="add"></mat-icon>
                    <span>
                      {{addon?.text}}
                    </span>
                  </div>
                </ng-container>
              </div>
            </div>
            <save-button
              class="w-100"
              *ngIf="plan?.is_recommended" 
              [saveButtonText]="subscriptionService?.currentPlan?.id === plan?.id ? 'Current plan' : ('Buy '+ plan?.name)"
              (saveClicked)="buyPlan(plan, 'yearly')"
              [loading]="loaderBuyPlan === plan?.id"
            ></save-button>
            <cancel-button 
              class="w-100"
              *ngIf="!plan?.is_recommended"
              [cancelButtonText]="subscriptionService?.currentPlan?.id === plan?.id ? 'Current plan' : ('Buy '+ plan?.name)"
              (cancelClicked)="buyPlan(plan, 'yearly')"
              [loading]="loaderBuyPlan === plan?.id"
            ></cancel-button>
          </div>
        </div>
      </div>
    </hlm-tabs>
  </div>
</div>
<div class="main-container" *ngIf="!plansScreen">
  <div class="expiry-header">
    <span></span>
    <button hlmBtn variant='ghost' (click)="logout()">Logout</button>
  </div>
  <div class="expiry-card">
    <img src="../../../../assets/images/subscription_expired.png" alt="">
    <div class="card-fields">
      <span class="expiry-heading">Your subscription has expired!</span>
      <span class="expiry-description">To continue using {{agencyService.brand}}, you need to pick a plan</span>
    </div>
    <div class="renew-option">
      <save-button *ngIf="user?.user_type === 1" [saveButtonText]="'Renew Now'" (saveClicked)="plansScreen = true;"></save-button>
      <span>or</span>
      <cancel-button *ngIf="agencyService.isAgency" [cancelButtonText]="'Contact Us'" (cancelClicked)="mailTo()"></cancel-button>
      <cancel-button *ngIf="!agencyService.isAgency" [cancelButtonText]="'Contact Us'" (cancelClicked)="openIntercom()"></cancel-button>
    </div>
  </div>

</div>

<billing-form *ngIf="billingForm" (close)="billingForm = false" (defaultBilling)="billingAdded($event)"></billing-form>