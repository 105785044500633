<div class="white-card">
  <div class="card-fields">
    <div class="card-details">
      <span><img [src]="agent?.voice?.avatar_url ?? icon" alt=""></span>
      <span class="ver-2px">
        <span class="field-name">{{agent?.agent_name}}</span>
        <span class="field-description">{{agent?.category | pascalCase}}</span>
      </span>
    </div>
    <div class="create-agent-btns">
      <span outlineOnClick [ngClass]="(loading || disable) ? 'disable' : ''" *ngIf="!audioPlaying" class="icon" (click)="!(loading || disable) ? playAudio(agent?.voice?.preview_url ?? '') : ''">
        <mat-icon style="height: 28px;" svgIcon="play_circle"></mat-icon>
      </span>
      <span outlineOnClick [ngClass]="(loading || disable) ? 'disable' : ''" *ngIf="audioPlaying" class="icon" (click)="!(loading || disable) ? pauseAudio() : ''">
        <mat-icon style="height: 28px;" svgIcon="pause"></mat-icon>
      </span>
      <cancel-button *ngIf="!isPrompt" [cancelButtonText]="'Create agent'" [icon]="'add'" [loading]="loading" [disableCancel]="disable"
        (cancelClicked)="create.emit(true)"></cancel-button>
      <cancel-button *ngIf="isPrompt" [cancelButtonText]="'Select template'" [loading]="loading" [disableCancel]="disable"
        (cancelClicked)="create.emit(true)"></cancel-button>
    </div>
  </div>
  <div class="perfect-for">
    <span class="perfect-for-title">Perfect for</span>
    <div class="perfect-labels">
      <ng-container *ngFor="let labels of agent?.perfect_for">
        <div class="labels">
          {{labels?.name}}
        </div>
      </ng-container>
    </div>
  </div>
  <div class="footer-buttons">
    <!-- <cancel-button [cancelButtonText]="'Test with webcall'" (cancelClicked)="testCall()" [icon]="'calls'"></cancel-button> -->

  </div>
</div>