<div id="main-wrapper">
  <ng-container *ngIf="!sharedService.remoteConfig.downtime">
    <ng-container *ngIf="!sharedService.inAuthModule">
      <menu class="menu-bar"></menu>
    </ng-container>
    <main>
      <notification-bar *ngIf="sharedService.isLoggedIn"></notification-bar>
      <router-outlet></router-outlet>
      <ng-container *ngIf="retellService.callLive">
        <live-call></live-call>
      </ng-container>
      <dailer *ngIf="sharedService.dialerPopup"></dailer>
    </main>
  </ng-container>
  <ng-container *ngIf="sharedService.remoteConfig.downtime">
    <downtime class="w-100" *ngIf="sharedService.remoteConfig.downtime"></downtime>
  </ng-container>
  <div class="toastr" *ngIf="toastr.visibility">
    <toastr></toastr>
  </div>
</div>