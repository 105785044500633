export class RateCard {
  private _authentication: string
  private _country_code: string
  private _country_name: string
  private _currency: string
  private _id: number
  private _marketing: string
  private _service: string
  private _utility: string
  private _inbound_rate: string
  private _outbound_rate: string
  private _pulse: number

  constructor(rateCardJson: any) {
    this._authentication = rateCardJson?.authentication;
    this._country_code = rateCardJson?.country_code;
    this._country_name = rateCardJson?.country_name;
    this._currency = rateCardJson?.currency;
    this._id = rateCardJson?.id;
    this._marketing = rateCardJson?.marketing;
    this._service = rateCardJson?.service;
    this._utility = rateCardJson?.utility;
    this._inbound_rate = rateCardJson?.inbound_rate;
    this._outbound_rate = rateCardJson?.outbound_rate;
    this._pulse = rateCardJson?.pulse;
  }

    public get authentication(): string {
        return this._authentication;
    }

    public set authentication(authentication: string) {
        this._authentication = authentication;
    }

    public get country_code(): string {
        return this._country_code;
    }

    public set country_code(country_code: string) {
        this._country_code = country_code;
    }

    public get country_name(): string {
        return this._country_name;
    }

    public set country_name(country_name: string) {
        this._country_name = country_name;
    }

    public get currency(): string {
        return this._currency;
    }

    public set currency(currency: string) {
        this._currency = currency;
    }

    public get id(): number {
        return this._id;
    }

    public set id(id: number) {
        this._id = id;
    }

    public get marketing(): string {
        return this._marketing;
    }

    public set marketing(marketing: string) {
        this._marketing = marketing;
    }

    public get service(): string {
        return this._service;
    }

    public set service(service: string) {
        this._service = service;
    }

    public get utility(): string {
        return this._utility;
    }

    public set utility(utility: string) {
        this._utility = utility;
    }

    public get inbound_rate(): string {
        return this._inbound_rate;
    }

    public set inbound_rate(inbound_rate: string) {
        this._inbound_rate = inbound_rate;
    }

    public get outbound_rate(): string {
        return this._outbound_rate;
    }

    public set outbound_rate(outbound_rate: string) {
        this._outbound_rate = outbound_rate;
    }

    public get pulse(): number {
        return this._pulse;
    }

    public set pulse(pulse: number) {
        this._pulse = pulse;
    }

}