import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { AgentTemplate } from 'src/app/models/agent-templates';
import { RetellService } from 'src/app/services/retell.service';

@Component({
  selector: 'agent-template',
  templateUrl: './agent-template.component.html',
  styleUrls: ['./agent-template.component.css']
})
export class AgentTemplateComponent implements OnChanges{

  @Input() loading: boolean = false;
  @Input() agent!: AgentTemplate;
  @Input() disable: boolean = false;
  @Input() playing: number = 0;
  @Input() icon: string = '../../../../assets/svg/avatar.svg';
  @Output() create = new EventEmitter<boolean>();
  @Output() audioStarted = new EventEmitter<boolean>();
  @Input() isPrompt: boolean = false;

  audio = new Audio();
  audioPlaying:boolean=false
  constructor(
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
    private _retellService: RetellService,
  ) {
    this.matIconRegistry.addSvgIcon(
      'play_circle',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/play_circle.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'pause',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/pause.svg'
      )
    );
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['playing']) {
      if(this.playing !== this.agent?.id) {
        if(this.audioPlaying) this.pauseAudio();
      }
    }
  }

  // testCall() {
  //   let input = {
  //     agent_id: this.agent?.id,
  //     is_test_call: true,
  //   }
  //   this._retellService.liveAgent = this.agent;
  //   this._retellService.callLive = true;
  //   this._retellService.registerCall(input).subscribe({
  //     next: resp => {
  //       this._retellService.startCall(resp.body.data.call_id)
  //     }
  //   });
  // }

  playAudio(url: string) {
    this.audioStarted.emit(true)
    this.audioPlaying=true;
    this.audio = new Audio();
    this.audio.src = url;
    this.audio.load();
    this.audio.play();
    this.audio.addEventListener('ended', () => {
      this.audioPlaying = false;
    });
  }

  pauseAudio() {
    this.audioPlaying = false;
    this.audio.pause();
  }
}
