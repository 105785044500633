<div class="customer-tag-container">
  <div class="tags-input-container">
    <tag 
      *ngFor="let tag of tagList" 
      [tagColor]="tag.color" 
      [tagText]="tag.text"
    ></tag>
    <input 
      class="tag-input" 
      type="text" 
      [(ngModel)]="newTag" 
      (keyup)="restrictLength()"
      [placeholder]="'Add a tag'" 
    >
  </div>
</div>