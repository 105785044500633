import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MentionConfig } from 'angular-mentions';
import { GlobalStrings } from 'src/app/constants';
import { v4 as uuidv4 } from 'uuid';
import { marked } from  'marked'

@Component({
  selector: 'commands-input',
  templateUrl: './commands-input.component.html',
  styleUrl: './commands-input.component.css'
})
export class CommandsInputComponent implements OnInit, AfterViewInit{

  constructor(
  ) {
    
  }

  @Input() variables: any[] = []
  @Input() inputText: string = '';
  @Input() placeholder: string = '';
  @Output() change = new EventEmitter<string>();
  @Input() height: string = '200px';
  @Input() count: boolean = false;

  public mentionConfig: MentionConfig = {
    triggerChar: '/',
    items: this.variables,
    labelKey: 'label',
    disableSort: true,
    mentionSelect: (e: any) => {
      return '##' + e.label + '##';
    },
  };

  getSortedMentions() {
    // Custom sort logic (e.g., sort alphabetically by name)
    return this.variables.sort((a, b) => a.id - b.id);
  }

  public htmlText!: string;
  public htmlDoc!: HTMLElement | null;

  ngOnInit(): void {
    console.log(this.variables)
  }

  async ngAfterViewInit(): Promise<void> {
    this.htmlDoc = document.getElementById('my-content');
    if(this.htmlDoc) {
      this.htmlDoc.innerHTML = this.removeH2Tags(this.htmlDoc.innerHTML);
      console.log(this.htmlDoc.innerHTML)
      this.htmlDoc.innerHTML = this.addBoldItalicStyles(this.inputText);
      console.log(this.htmlDoc.innerHTML);
    }
    let htmlText = await marked(this.inputText);
    if(htmlText && this.htmlDoc) {
      htmlText = this.removeH2Tags(htmlText);
      this.htmlDoc.innerHTML = htmlText;
      console.log(htmlText);
    } else console.log("html text empty")
  }

  itemSelected(event: any) {
    console.log(event);
    setTimeout(() => {
      this.htmlDoc = document.getElementById('my-content');
      console.log(this.htmlDoc?.innerText);
      if(this.htmlDoc)
      this.htmlDoc.innerHTML = this.htmlDoc.innerHTML.replace(
        '##' + event.label + '##',
        ' <span variable="'+event.field+'" variableType="'+event.type+'" contenteditable="false" style="background:#F3E8FF; font-weight: 600; color: #7E22CE;border-radius: 4px; padding: 0 2px;">' +
          event.label +
          '</span>&nbsp;'
      );
      if(this.htmlDoc) this.htmlDoc.innerHTML = this.addBoldItalicStyles(this.htmlDoc.innerHTML);
      // put the cursor to the end of field again...
      console.log(this.htmlDoc?.innerText);
      this.selectEnd();
      console.log(this.htmlDoc?.innerHTML);
      this.change.emit(this.htmlDoc?.innerHTML);
    }, 10);
  }

  emitChange() {
    this.htmlDoc = document.getElementById('my-content');
    if(this.htmlDoc) this.change.emit(this.htmlDoc?.innerHTML);
    if(this.htmlDoc) this.htmlText = this.htmlDoc.innerText;
  }

  selectEnd() {
    let range, selection;
    range = document.createRange();
    if(this.htmlDoc) range.selectNodeContents(this.htmlDoc);
    range.collapse(false);
    selection = window.getSelection();
    selection!.removeAllRanges();
    selection!.addRange(range);
    if(this.htmlDoc) this.change.emit(this.htmlDoc?.innerHTML);
  }

  addBoldItalicStyles(value: string | undefined) {
    if(value == undefined) return '';
    const regex = new RegExp(GlobalStrings.boldRegex, 'gi');
    value = value?.replace(
      regex,
      (match) => `<b>${match.replace(/\*/g, '')}</b>`
    );
    //value = value.replace(/\n/g, '<br/>');
    const italicRegex = new RegExp(GlobalStrings.italicRegex, 'gi');
    value = value?.replace(
      italicRegex,
      (match) => `<i>${match.replace(/\_/g, '')}</i>`
    );
    const urlRegex = new RegExp(GlobalStrings.newUrlRegex, 'gi');
    let matchedUrls = value?.match(urlRegex);
    if(matchedUrls) {
      matchedUrls?.forEach((url)=>{
        value = value?.replace(url,'<a href="'+url+'" target="_blank">'+url+'</a>');
      });
    }
    const strikeRegex = new RegExp(GlobalStrings.strikeRegex, 'gi');
    return value?.replace(
      strikeRegex,
      (match) => `<del>${match.replace(/\~/g, '')}</del>`
    );
  }

  removeH2Tags(text: string) {
    if(text.startsWith('<h2>')) text = text.slice(4)
    if(text.endsWith('</h2>')) text = text.slice(0,text?.length - 5);
    text = text.replaceAll('<strong>', '');
    text = text.replaceAll('</strong>', '');
    return text;
  }
}
