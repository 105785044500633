import { HostListener, Injectable } from '@angular/core';
import { SwUpdate, VersionEvent } from '@angular/service-worker';
import { interval } from 'rxjs';
import { SharedDataService } from './shared-data.service';

@Injectable({
  providedIn: 'root'
})
export class WorkerService {

  newUpdate: boolean = false;

  constructor(updates: SwUpdate, public sharedDataService: SharedDataService) {
    console.log("Worker service initiated")
    updates.versionUpdates.subscribe(event => {
      console.log(event);
      if(event.type === 'VERSION_DETECTED') {
        sharedDataService.newVersionEnabled = true;
      }
    });
  }
}


