import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ddmmyyyy',
})
export class DatePipe implements PipeTransform {
  transform(date: Date | null | undefined | string): string {
    if(date == null || date == undefined) return '';
    if(date === '') return '';
    else {
      let newDate = new Date(date)
      let finalString = newDate.toUTCString().slice(5,16);
      let dates = finalString.split(' ');
      let month = dates[1];
      dates[1] = Number(dates[0]).toString()+',';
      dates[0] = month
      return dates.join(' ');
    }
  }
}
