import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GlobalStrings } from 'src/app/constants';

@Component({
  selector: 'website-input',
  templateUrl: './website-input.component.html',
  styleUrls: ['./website-input.component.css']
})
export class WebsiteInputComponent  {

  @Input() inputText!: string;
  @Input() maxChars!: number;
  @Input() fieldName!: string;
  @Input() placeholder: string = 'Enter URL';
  @Input() isValid: boolean = true;
  @Input() isOptional: boolean = false;
  @Output() textChange = new EventEmitter<string>();
  @Output() isValidURL = new EventEmitter<boolean>();

  checkLength(event: any){
    if(this.maxChars){
      if(this.inputText?.length < this.maxChars) return true;
      else return false;
    } else return true;
  }

  checkIfValid(){
    if (
      this.inputText?.substring(0, 7) == 'http://' ||
      this.inputText?.substring(0, 8) == 'https://'
    ) {
      let regex = new RegExp(GlobalStrings.websiteRegex);
      let validatedWebsite = regex.test(this.inputText);
      if (validatedWebsite) {
        this.isValid = true;
      } else {
        this.isValid = false;
      }
    } else {
      let websiteWithHttp = 'http://' + this.inputText;
      let regex = new RegExp(GlobalStrings.websiteRegex);
      let validatedWebsite = regex.test(websiteWithHttp);
      if (validatedWebsite) {
        this.isValid = true;
      } else {
        this.isValid = false;
      }
    }
    this.isValidURL.emit(this.isValid);
  }
}
