import { Pipe, PipeTransform } from '@angular/core';
import { Label } from '../models/label';

@Pipe({
  name: 'label',
})
export class LabelPipe implements PipeTransform {
  transform(label: any): Label {
    let input = {
      name: label?.name,
      color: label?.color,
      description: '',
      id: label?.label_id,
      isChecked: false,
      bg_color: label?.bg_color,
    }
    return new Label(input);
  }
}
