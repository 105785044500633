<div class="main-container">
  <div class="container-body" *ngIf="sharedDataService.newVersionEnabled">
    <span>New Version Available! Click 'Update' to access exciting new features.</span>
    <button (click)="reload()">
      <mat-icon svgIcon="refresh"></mat-icon>
      Update now
    </button>
  </div>
  <!-- <div class="container-body dismissable" *ngIf="sharedDataService.remoteConfig.downtime">
    <span>Apologies for the inconvenience – we're facing technical difficulties, and some features may be temporarily unavailable.</span>
    <button (click)="sharedDataService.remoteConfig.downtime= false">Dismiss</button>
  </div> -->
  <!-- <div class="container-body subscription-expired" *ngIf="sharedDataService.business.subscription.is_expired">
    <span>Your subscription has expired, renew now to continue conversations! </span>
    <button (click)="routeToSubscription()">Renew</button>
  </div>
  <div class="container-body subscription-expired" *ngIf="!sharedDataService.business.subscription.is_expired && sharedDataService.subscriptionDaysLeft < 4">
    <span>Your subscription is expiring {{sharedDataService.subscriptionDaysLeft > 0 ? 'in '+ sharedDataService.subscriptionDaysLeft + ' days' : 'Today'}} – renew now to avoid any interruption in conversations! </span>
    <button (click)="routeToSubscription()">Renew</button>
    <button class="dismiss-button" (click)="sharedDataService.subscriptionDaysLeft = 4">Dismiss</button>
  </div> -->
</div>