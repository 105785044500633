<div class="message-input-box">
  <textarea 
    #body
    [rows]="rows"
    [(ngModel)]="message"
    (keyup)="emitChange()"
    id="body-content"
    class="message-textarea"
    placeholder="Enter text"
  ></textarea>
  <p class="message-buttons-variables"> 
    <span class="emoji-button">
      <button
        type="button"
        class="btn btn-emoji"
        id="italic"
        style="padding: 0; position: relative;"
        outlineOnClick
        (clickOutside)="showEmojiPicker = false"
        >
          <mat-icon svgIcon="emoji_icon" (click)="openEmojiPicker()"></mat-icon>
          <emoji-mart
            *ngIf="showEmojiPicker"
            class="emoji-mart"
            (emojiSelect)="addEmoji($event)"
            title="Pick your emoji…"
            [darkMode]="false"
          ></emoji-mart>
      </button>
    </span>
    <span class="send-arrow" outlineOnClick (click)="sendClicked.emit(message)"><mat-icon svgIcon="send"></mat-icon></span>
  </p>
</div>