import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AgencyService } from '../services/agency.service';

@Injectable({
  providedIn: 'root'
})
export class AgencyGuard implements CanActivate {
  constructor(private agencyService: AgencyService) {}
  
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if(this.agencyService.isPricingEnabled) return true;
      else return false;
  }
  
}
