import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'secondary-button',
  templateUrl: './secondary-button.component.html',
  styleUrl: './secondary-button.component.css'
})
export class SecondaryButtonComponent implements OnInit {

  @Input() buttonText!: string;
  @Input() hide: boolean = false;
  @Input() disable: boolean = false;
  @Output() clicked = new EventEmitter<boolean>();
  @Input() icon!: string
  @Input() align!: string
  @Input() loading: boolean = false;

  constructor(
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
  ) {
    
  }

  ngOnInit(): void {
    if(this.icon) {
      this.matIconRegistry.addSvgIcon(
        this.icon,
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../assets/svg/'+this.icon+'.svg'
        )
      );
    }
  }
}
