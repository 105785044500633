import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotesService {

  constructor(
    private _http: HttpClient,
  ) { }

  getNotes(params: any): Observable<any> {
    return this._http.get<any>('/note/', { 
      observe: 'response',
      params: params,
    });
  }

  addNotes(obj: any) : Observable<any> {
    return this._http.post<any>('/note/', obj, { 
      observe: 'response',
    });
  }

  updateNote(obj: any, id: number): Observable<any> {
    return this._http.put<any>('/note/'+id, obj, { observe: 'response'});
  }

  deleteNote(id: number): Observable<any> {
    return this._http.delete<any>('/note/'+id, { 
      observe: 'response',
    });
  }
}
