import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'keyValue',
})
export class KeyvaluePipe implements PipeTransform {

  transform(value: any): Record<string,any>[] {
    let keys = Object.keys(value);
    let map: Record<string,any>[] = [];
    keys?.forEach((element) => {
      let input = {
        key: element,
        value: value[element]
      }
      map.push(input);
    })
    return map;
  }

}
