import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AgencyService {

  brand: string = '';
  darkLogo: string = '';
  lightLogo: string = '';
  favicon: string = ''; 
  supportEmail: string = '';
  isAgency: boolean = true;
  isPricingEnabled: boolean = true;
  constructor(
    private _http: HttpClient,
  ) { }

  getAgency(): Observable<any> {
    return this._http.get<any>('/agency/', { observe: 'response'});
  }

  updateAgency(obj: any): Observable<any> {
    return this._http.post<any>('/agency/', obj, { observe: 'response'});
  }

  updateAgencyDomain(obj: any, id: number): Observable<any> {
    return this._http.put<any>('/agency/domain/'+id, obj, { observe: 'response'});
  }

  deleteAgencyDomain(id: number): Observable<any> {
    return this._http.delete<any>('/agency/domain/'+id, { observe: 'response'});
  }

  getAssets() :Observable<any> {
    return this._http.get<any>('/conf/', {
      observe: 'response',
    })
  }

  getAgencyPlans() :Observable<any> {
    return this._http.get<any>('/agency/plans', { observe: 'response'});
  }

  getAgencyAddOns() :Observable<any> {
    return this._http.get<any>('/agency/plans?plan_type=add-ons', { observe: 'response'});
  }

  updateAgencyPlan(id: number, obj: any) :Observable<any> {
    return this._http.put<any>('/agency/plans/'+id, obj, { observe: 'response'});
  }

  verifyDNS() :Observable<any> {
    return this._http.get<any>('/agency/verify/dns', { observe: 'response'});
  }

  verifyEmail() :Observable<any> {
    return this._http.get<any>('/agency/verify/email', { observe: 'response'});
  }

}
