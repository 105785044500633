<div class="dropdown">
  <button
    class="btn btn-custom-dropdown dropdown-toggle timeframe-dropdown"
    type="button"
    id="timeframeDropdown"
    data-toggle="dropdown"
    (click)="filterPopup = !filterPopup; filterType = prevFilterType; setDates(filterType)"
    >
    <span class="timeframe-selected-type"><span *ngIf="filter">Joining Date: </span><span *ngIf="dateFilter">Date: </span> {{ prevFilterType }}</span>
    <icon [icon]="'arrow_down'" [size]="'20px'" [iconColor]="'var(--secondary-color)'"></icon>
  </button>
  <div class="filter-popup" *ngIf="filterPopup">
    <div class="filter-white-card">
      <div class="filter-card-body">
        <div class="filter-card-body-left">
          <p class="filter-card-field">
            <span class="filter-card-field-border" [ngClass]="{active: filterType === 'Today'}"></span>
            <span class="filter-card-field-value" (click)="setDates('Today')" [ngClass]="{active: filterType === 'Today'}">Today</span>
          </p>
          <p class="filter-card-field">
            <span class="filter-card-field-border" [ngClass]="{active: filterType === 'Yesterday'}"></span>
            <span class="filter-card-field-value" (click)="setDates('Yesterday')" [ngClass]="{active: filterType === 'Yesterday'}">Yesterday</span>
          </p>
          <p class="filter-card-field">
            <span class="filter-card-field-border" [ngClass]="{active: filterType === 'Last 7 days'}"></span>
            <span class="filter-card-field-value" (click)="setDates('Last 7 days')" [ngClass]="{active: filterType === 'Last 7 days'}">Last 7 days</span>
          </p>
          <p class="filter-card-field">
            <span class="filter-card-field-border" [ngClass]="{active: filterType === 'Last 30 days'}"></span>
            <span class="filter-card-field-value" (click)="setDates('Last 30 days')" [ngClass]="{active: filterType === 'Last 30 days'}">Last 30 days</span>
          </p>
          <p class="filter-card-field">
            <span class="filter-card-field-border" [ngClass]="{active: filterType === 'Last 90 days'}"></span>
            <span class="filter-card-field-value" (click)="setDates('Last 90 days')" [ngClass]="{active: filterType === 'Last 90 days'}">Last 90 days</span>
          </p>
          <!-- <p class="filter-card-field">
            <span class="filter-card-field-border" [ngClass]="{active: filterType === 'Last year'}"></span>
            <span class="filter-card-field-value" (click)="setDates('Last year')" [ngClass]="{active: filterType === 'Last year'}">Last year</span>
          </p> -->
          <p class="filter-card-field">
            <span class="filter-card-field-border" [ngClass]="{active: filterType === 'Custom'}"></span>
            <span class="filter-card-field-value" (click)="setDates('Custom')" [ngClass]="{active: filterType === 'Custom'}">Custom</span>
          </p>
        </div>
        <div class="filter-card-body-right">
          <p class="filter-custom-range-heading">Range</p>
          <div class="filter-custom-dates">
            <span class="filter-custom-dates-from">
              <span>From</span>
              <!-- <mat-calendar [(selected)]="selectedRange"></mat-calendar> -->
              <date-picker [date]="fromDate" [startDate]="startDate" [endDate]="endDate" (dateChange)="fromDate = $event; filterType = fromDate?.toLocaleDateString()+' - '+toDate?.toLocaleDateString()"></date-picker>
            </span>
            <span class="filter-custom-dates-to">
              <span>To</span>
              <date-picker [date]="toDate" [startDate]="startDate" [endDate]="endDate" (dateChange)="toDate = $event; filterType = fromDate?.toLocaleDateString()+' - '+toDate?.toLocaleDateString()"></date-picker>
            </span>
          </div>
        </div>
      </div>
      <div class="filter-card-buttons">
        <cancel-button [cancelButtonText]="'Cancel'" (click)="true" (cancelClicked)="cancelFilter()"></cancel-button>
        <save-button [saveButtonText]="'Save'" (saveClicked)="applyTimeframe()"></save-button>
      </div>
    </div>
  </div>
</div>