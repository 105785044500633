import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { provideQuillConfig, QuillModule } from 'ngx-quill';
import Quill from 'quill';
import { Mention, MentionBlot } from 'quill-mention';

Quill.register('modules/mention', Mention);
Quill.register('blots/mention', MentionBlot);

@Component({
  selector: 'email-formatter',
  templateUrl: './email-formatter.component.html',
  styleUrl: './email-formatter.component.css'
})
export class EmailFormatterComponent implements OnInit, AfterViewInit {


  @Input() textContent: string = '';
  @Input() mentions: any[] = [];
  @Input() placeholder: string = '';
  changedContent: string = '';
  @Output() change = new EventEmitter<string>();
  isEditorActive: boolean = false;

  ngOnInit(): void {
    
  }

  modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'link'],        // toggled buttons
  
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],     // outdent/indent
  
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  
      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      [{ 'font': [] }],
      [{ 'align': [] }],
  
    ],
    mention: {
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      mentionDenotationChars: ["/"],
      source: (searchTerm: string, renderList: Function) => {

        if (searchTerm.length === 0) {
          renderList(this.mentions, searchTerm);
        } else {
          const matches = [];
          for (let i = 0; i < this.mentions.length; i++)
            if (
              ~this.mentions[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())
            )
              matches.push(this.mentions[i]);
          renderList(matches, searchTerm);
        }
      }
    }
  }

  ngAfterViewInit(): void {
  }

  contentChanged(event: any) {
    console.log(event);
    this.changedContent = event.html;
    this.change.emit(this.changedContent);
  }
}
