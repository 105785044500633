import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.css']
})
export class PopupComponent implements OnInit {

  constructor() {
    
  }

  @Input() heading!: string;
  @Input() footer: boolean = true;
  @Output() close = new EventEmitter<boolean>();
  @Output() save = new EventEmitter<any>();

  ngOnInit(): void {
    
  }

}
