import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'profile-pic',
  templateUrl: './profile-pic.component.html',
  styleUrls: ['./profile-pic.component.css']
})
export class ProfilePicComponent implements OnInit {

  @Input() details: any;

  ngOnInit(): void {
    
  }

  getFirstLetter(value: string){
    return value?.charAt(0).toUpperCase();
  }
}
