import { Component } from '@angular/core';
import { AgencyService } from 'src/app/services/agency.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-agency-expired',
  templateUrl: './agency-expired.component.html',
  styleUrl: './agency-expired.component.css'
})
export class AgencyExpiredComponent {

  constructor(
    private _commonService: CommonService,
    public agencyService: AgencyService,
  ) {
    
  }

  mailTo() {
    const mailtoLink = `mailto:${this.agencyService.supportEmail}`;
    window.location.href = mailtoLink;
  }

  logout() {
    this._commonService.logout();
  }
}
