<div class="add-custom-field" (clickOutside)="clickedOutside()">
  <icon *ngIf="type === 'Text' || type === 'text'" [icon]="'text'" [size]="'20px'"></icon>
  <icon *ngIf="type === 'Number' || type === 'int'" [icon]="'numbers'" [size]="'20px'"></icon>
  <icon *ngIf="type === 'Address' || type === 'address'" [icon]="'location_on'" [size]="'20px'"></icon>
  <icon *ngIf="type === 'Date' || type === 'date'" [icon]="'calendar'" [size]="'20px'"></icon>
  <icon *ngIf="type === 'URL' || type === 'url'" [icon]="'link'" [size]="'20px'"></icon>
  <icon *ngIf="type === 'Checkbox' || type === 'bool'" [icon]="'check_box'" [size]="'20px'"></icon>
  <text-input [maxChars]="32" [inputText]="name" 
    [placeholder]="placeholder" 
    (textChange)="name = $event" 
    (enterClicked)="loader ? '' : addCustomField()"
  ></text-input>
  <span class="add-custom-action" (click)="$event.stopPropagation(); (loader ? '' : addCustomField())">
    <icon *ngIf="!loader" [icon]="'check_circle_outline'" [iconColor]="'var(--secondary-color)'"></icon>
    <mat-progress-spinner
      *ngIf="loader"
      mode="indeterminate"
      diameter="22"
    ></mat-progress-spinner>
  </span>
  <span class="add-custom-action" (click)="closeAddCustomField()"><icon [icon]="'delete'" [iconColor]="'var(--secondary-color)'"></icon></span>
</div>