export class Conversation {
  private _id: number;
  private _last_customer_message_at: string;
  private _assignee: Assignee;

  constructor(conversationJson: any){
    this._id = conversationJson?.id;
    this._last_customer_message_at = conversationJson?.last_customer_message_at;
    this._assignee = new Assignee(conversationJson?.assignee);
  }

    public get id(): number {
        return this._id;
    }

    public set id(id: number) {
        this._id = id;
    }

    public get last_customer_message_at(): string {
        return this._last_customer_message_at;
    }

    public set last_customer_message_at(last_customer_message_at: string) {
        this._last_customer_message_at = last_customer_message_at;
    }

    public get assignee(): Assignee {
        return this._assignee;
    }

    public set assignee(assignee: Assignee) {
        this._assignee = new Assignee(assignee);
    }

}

export class Assignee {
  private _id: number;
  private _name: string;

  constructor(assigneeJson: any){
    this._id = assigneeJson?.id;
    this._name = assigneeJson?.name;
  }

    public get id(): number {
        return this._id;
    }

    public set id(id: number) {
        this._id = id;
    }

    public get name(): string {
        return this._name;
    }

    public set name(name: string) {
        this._name = name;
    }

    
}

export class CustomProperty {
  private _field: string
  private _datatype: string
  private _label: string
  private _text_value: string
  private _int_value: number
  private _bool_value: boolean
  private _url_value: string
  private _address_value: string
  private _date_value: Date

  constructor(customPropertyJson: any) {
    this._field = customPropertyJson?.field;
    this._datatype = customPropertyJson?.datatype;
    this._label = customPropertyJson?.label;
    this._text_value = customPropertyJson?.text_value;
    this._int_value = customPropertyJson?.int_value;
    this._bool_value = customPropertyJson?.bool_value;
    this._url_value = customPropertyJson?.url_value;
    this._address_value = customPropertyJson?.address_value;
    this._date_value = customPropertyJson?.date_value;
  }

    public get field(): string {
        return this._field;
    }

    public set field(field: string) {
        this._field = field;
    }

    public get datatype(): string {
        return this._datatype;
    }

    public set datatype(datatype: string) {
        this._datatype = datatype;
    }

    public get label(): string {
        return this._label;
    }

    public set label(label: string) {
        this._label = label;
    }

    public get text_value(): string {
        return this._text_value;
    }

    public set text_value(text_value: string) {
        this._text_value = text_value;
    }

    public get int_value(): number {
        return this._int_value;
    }

    public set int_value(int_value: number) {
        this._int_value = int_value;
    }

    public get bool_value(): boolean {
        return this._bool_value;
    }

    public set bool_value(bool_value: boolean) {
        this._bool_value = bool_value;
    }

    public get url_value(): string {
        return this._url_value;
    }

    public set url_value(url_value: string) {
        this._url_value = url_value;
    }

    public get address_value(): string {
        return this._address_value;
    }

    public set address_value(address_value: string) {
        this._address_value = address_value;
    }

    public get date_value(): Date {
        return this._date_value;
    }

    public set date_value(date_value: Date) {
        this._date_value = date_value;
    }

}

export class Address {
  private _city: string;
  private _state: string;
  private _line_1: string;
  private _line_2!: string;
  private _country: string;
  private _pin_code: number;

  public get city(): string {
    return this._city;
  }

  public set city(city: string) {
    this._city = city;
  }

  public get state(): string {
    return this._state;
  }

  public set state(state: string) {
    this._state = state;
  }

  public get line_1(): string {
    return this._line_1;
  }

  public set line_1(line_1: string) {
    this._line_1 = line_1;
  }

  public get line_2(): string {
    return this._line_2!;
  }

  public set line_2(line_2: string) {
    this._line_2 = line_2;
  }

  public get country(): string {
    return this._country;
  }

  public set country(country: string) {
    this._country = country;
  }

  public get pin_code(): number {
    return this._pin_code;
  }

  public set pin_code(pin_code: number) {
    this._pin_code = pin_code;
  }

  constructor(addressJson: any) {
    this._line_1 = addressJson?.line_1;
    this._line_2 = addressJson?.line_2;
    this._city = addressJson?.city;
    this._state = addressJson?.state;
    this._country = addressJson?.country;
    this._pin_code = addressJson?.pin_code;
  }
}

export class CreatedBy {
  private _id: number;
  private _name: string

  constructor(createdByJson: any) {
    this._id = createdByJson?.id;
    this._name = createdByJson?.name;
  }

    public get id(): number {
        return this._id;
    }

    public set id(id: number) {
        this._id = id;
    }

    public get name(): string {
        return this._name;
    }

    public set name(name: string) {
        this._name = name;
    }

}

export class Customer {
  private _id: number;
  private _name: string;
  private _mobile: string;
  private _email: string;
  private _address: Address[];
  private _fb_profile: string;
  private _linkedin_profile: string;
  private _twitter_profile: string;
  private _created_at: Date;
  private _updated_at: Date;
  private _is_block: boolean;
  private _source_type: string;
  private _source: string;
  private _gender: number;
  private _date_of_birth: string;
  private _is_opt_in: boolean;
  private _is_active: boolean;
  private _created_by: CreatedBy;
  private _updated_by: number;
  private _selected: boolean;
  private _conversation: Conversation;
  private _whatsapp_name: string;
  private _labels: CustomerLabel[];
  private _custom_field: any;
  private _country_code: string;
  private _bio: string;
  private _custom_property: CustomProperty[];

    public get custom_property(): CustomProperty[] {
        return this._custom_property;
    }

    public set custom_property(custom_property: CustomProperty[]) {
        this._custom_property = [];
        custom_property?.forEach((element) => {
          this._custom_property.push(new CustomProperty(element));
        })
    }


    public get bio(): string {
      return this._bio;
    }

    public set bio(bio: string) {
      this._bio = bio;
    }


  public get custom_field(): any {
    return this._custom_field;
  }

  public set custom_field(custom_field: any) {
    this._custom_field = custom_field ? custom_field : '';
  }

  public get labels(): CustomerLabel[] {
    return this._labels;
  }

  public set labels(labels: CustomerLabel[]) {
    this._labels = [];
    labels?.forEach((label)=>{
      this._labels.push(new CustomerLabel(label));
    });
  }

  public get country_code(): string {
    return this._country_code;
  }

  public set country_code(country_code: string) {
      this._country_code = country_code;
  }


  public get whatsapp_name(): string {
    return this._whatsapp_name;
  }

  public set whatsapp_name(whatsapp_name: string) {
    this._whatsapp_name = whatsapp_name;
  }

  public get conversation(): Conversation {
    return this._conversation;
  }

  public set conversation(conversation: Conversation) {
    this._conversation = new Conversation(conversation);
  }

  public get id(): number {
    return this._id;
  }

  public set id(id: number) {
    this._id = id;
  }

  public get name(): string {
    return this._name;
  }

  public set name(name: string) {
    this._name = name;
  }

  public get mobile(): string {
    return this._mobile;
  }

  public set mobile(mobile: string) {
    this._mobile = mobile;
  }

  public get email(): string {
    return this._email;
  }

  public set email(email: string) {
    this._email = email;
  }

  public get address(): Address[] {
    return this._address;
  }

  public set address(address: Address[]) {
    address?.forEach((addressElement) => {
      this._address.push(new Address(addressElement));
    });
  }

  public get fb_profile(): string {
    return this._fb_profile;
  }

  public set fb_profile(fb_profile: string) {
    this._fb_profile = fb_profile;
  }

  public get linkedin_profile(): string {
    return this._linkedin_profile;
  }

  public set linkedin_profile(linkedin_profile: string) {
    this._linkedin_profile = linkedin_profile;
  }

  public get twitter_profile(): string {
    return this._twitter_profile;
  }

  public set twitter_profile(twitter_profile: string) {
    this._twitter_profile = twitter_profile;
  }

  public get created_at(): Date {
    return this._created_at;
  }

  public set created_at(created_at: Date) {
    this._created_at = created_at;
  }

  public get updated_at(): Date {
    return this._updated_at;
  }

  public set updated_at(updated_at: Date) {
    this._updated_at = updated_at;
  }

  public get is_block(): boolean {
    return this._is_block;
  }

  public set is_block(is_block: boolean) {
    this._is_block = is_block;
  }

  public get source_type(): string {
    return this._source_type;
  }

  public set source_type(source_type: string) {
    this._source_type = source_type;
  }

  public get source(): string {
    return this._source;
  }

  public set source(source: string) {
    this._source = source;
  }

  public get gender(): number {
    return this._gender;
  }

  public set gender(gender: number) {
    this._gender = gender;
  }

  public get date_of_birth(): string {
    return this._date_of_birth;
  }

  public set date_of_birth(date_of_birth: string) {
    this._date_of_birth = date_of_birth;
  }

  public get is_opt_in(): boolean {
    return this._is_opt_in;
  }

  public set is_opt_in(is_opt_in: boolean) {
    this._is_opt_in = is_opt_in;
  }

  public get is_active(): boolean {
    return this._is_active;
  }

  public set is_active(is_active: boolean) {
    this._is_active = is_active;
  }

  public get created_by(): CreatedBy {
    return this._created_by;
  }

  public set created_by(created_by: CreatedBy) {
    this._created_by = new CreatedBy(created_by);
  }

  public get updated_by(): number {
    return this._updated_by;
  }

  public set updated_by(updated_by: number) {
    this._updated_by = updated_by;
  }

  public get selected(): boolean {
    return this._selected;
  }

  public set selected(selected: boolean) {
    this._selected = selected;
  }

  constructor(customerJson: any) {
    this._id = customerJson?.id;
    this._name = customerJson?.name;
    this._mobile = customerJson?.mobile;
    this._email = customerJson?.email;
    this._address = [];
    customerJson?.address?.forEach((address: any) => {
      this._address.push(new Address(address));
    });
    this._fb_profile = customerJson?.fb_profile;
    this._twitter_profile = customerJson?.twitter_profile;
    this._linkedin_profile = customerJson?.linkedin_profile;
    this._created_at = customerJson?.created_at;
    this._created_by = new CreatedBy(customerJson?.created_by);
    this._updated_at = customerJson?.updated_at;
    this._date_of_birth = customerJson?.date_of_birth;
    this._is_active = customerJson?.is_active;
    this._is_opt_in = customerJson?.is_opt_in;
    this._gender = customerJson?.gender;
    this._updated_by = customerJson?.updated_by;
    this._source_type = customerJson?.source_type;
    this._source = customerJson?.source;
    this._is_block = customerJson?.is_block;
    this._selected = customerJson?.selected;
    this._conversation = new Conversation(customerJson?.conversation);
    this._whatsapp_name = customerJson?.whatsapp_name;
    this._labels = [];
    customerJson?.labels?.forEach((label: any)=>{
      this._labels.push(new CustomerLabel(label));
    });
    this._custom_field = customerJson?.custom_field;
    this._country_code = customerJson?.country_code;
    this._bio = customerJson?.bio;
    this._custom_property = [];
    customerJson?.custom_property?.forEach((element: any) => {
      this._custom_property.push(new CustomProperty(element));
    })
  }
}

export class CustomerLabel {
  private _label_id: number;
  private _name: string;
  private _color: string;
  private _bg_color: string;

    public get bg_color(): string {
        return this._bg_color;
    }

    public set bg_color(bg_color: string) {
        this._bg_color = bg_color;
    }


  constructor(labelJson: any) {
    this._label_id = labelJson?.label_id;
    this._name = labelJson?.name;
    this._color = labelJson?.color;
    this._bg_color = labelJson?.bg_color;
  }

  public get label_id(): number {
    return this._label_id;
  }

  public set label_id(labelId: number) {
    this._label_id = labelId;
  }

  public get name(): string {
    return this._name;
  }

  public set name(name: string) {
    this._name = name;
  }

  public get color(): string {
    return this._color;
  }

  public set color(color: string) {
    this._color = color;
  }
}