import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';


if (environment.production) {
  enableProdMode();
  if (window) {
    window.console.log = function () {};
  }
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/service-worker.js')
      .then(registration => {
        console.log(registration);
        console.log("Service worker registration done");
      });
    }
  })
  .catch((err) => console.error(err));

const vapidKeys = {
  publicKey: 'BCSZFL3US6A8eUT4U8qe-FtSX61YiYWXBugLdEEWRuislh0VZfa86akAqHMcH7L3-Fee-Kb-mD1m6wf5OBX_iIg',
  privateKey: '7JpQV4qpR8ykjbY0WT-7XDk33oush6Uxtf99wJDPSw8',
}