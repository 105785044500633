import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { AppSettings } from '../app.settings';
import { Agent } from '../models/team-member';
import { Params } from '@angular/router';
import { Characteristics } from '../models/ai-agent';

@Injectable({
  providedIn: 'root',
})
export class AgentService {

  teamMember!: Agent;
  agentCharacteristics!: Characteristics[]

  constructor(private http: HttpClient) {
    let characteristics = JSON.parse(sessionStorage.getItem('characteristics')!);
    this.agentCharacteristics = [];
    characteristics?.forEach((element: any) => this.agentCharacteristics.push(new Characteristics(element)));
  }

  setData(teamMember: Agent) {
    this.teamMember = new Agent(teamMember);
  }

  getData(): Agent {
    return this.teamMember;
  }

  getActiveAgents(pageNumber: number): Observable<any> {
    return this.http.get<any>('/user/agents?active=1&page=' + pageNumber, {
      observe: 'response',
    });
  }

  getAllAgents(pageNumber: number): Observable<any> {
    return this.http.get<any>('/voice/ai-agent?page=' + pageNumber, {
      observe: 'response',
    });
  }
  
  deleteAgent(id: number): Observable<any> {
    return this.http.delete<any>('/user/agents/' + id + '/', {
      observe: 'response',
    });
  }

  addAgent(obj: any): Observable<any> {
    return this.http.post<any>('/voice/ai-agent', obj, { observe: 'response' });
  }

  duplicateAgent(id: number): Observable<any> {
    return this.http.post<any>('/voice/ai-agent/'+id+'/duplicate',null, { observe: 'response' });
  }

  addAgentAction(id: number,obj: any): Observable<any> {
    return this.http.post<any>('/oneinbox/agent/action/'+id, obj, { observe: 'response' });
  }

  updateAgentAction(agentId: number,id: number,obj: any): Observable<any> {
    return this.http.patch<any>('/oneinbox/agent/action/'+agentId+'/'+id, obj, { observe: 'response' });
  }

  getAgentAction(id: number): Observable<any> {
    return this.http.get<any>('/oneinbox/agent/action/'+id, { observe: 'response' });
  }

  deleteAgentAction(agentId: number,actionId: number): Observable<any> {
    return this.http.delete<any>('/oneinbox/agent/action/'+agentId+'/'+actionId, { 
      observe: 'response',
    });
  }

  getActionVariables(id: number, trigger: string) : Observable<any> {
    return this.http.get<any>('/oneinbox/var/action/' + id+'/'+trigger, {
      observe: 'response',
    });
  }

  getPromptActionVariables(id: number, trigger: string, params: Params) : Observable<any> {
    return this.http.get<any>('/oneinbox/var/action/' + id+'/'+trigger, {
      observe: 'response',
      params: params,
    });
  }

  updateAgent(obj: any, id: number): Observable<any> {
    return this.http.patch<any>('/voice/ai-agent/' + id, obj,{
      observe: 'response',
    });
  }

  deleteAiAgent(id: number): Observable<any> {
    return this.http.delete<any>('/voice/ai-agent/' + id, {
      observe: 'response',
    });
  }

  get11LabsVoices(): Observable<any> {
    return this.http.get<any>('/voice/options/agent_voice', {
      observe: 'response',
    })
  }

  getAiAgentCharacteristics() : Observable<any> {
    return this.http.get<any>('/voice/ai-agent/characteristics', {
      observe: 'response',
    })
  }

  getTimezones(): Observable<any> {
    return this.http.get<any>('/oneinbox/timezones', {
      observe: 'response',
    })
  }

  getAudioPresign(id: number): Observable<any> {
    return this.http.get<any>('/voice/call-recording/'+id+'/presign', {observe: 'response'})
  }

  assignAgentToNumber(id: number, obj: any): Observable<any> {
    return this.http.patch<any>('/voice/number/'+id+'/assign',obj, {observe: 'response'})
  }

  getActiveUsers(pageNumber: number): Observable<any> {
    return this.http.get<any>('/user?status=active&page=' + pageNumber, {
      observe: 'response',
    });
  }

  getPendingUsers(pageNumber: number): Observable<any> {
    return this.http.get<any>('/user?status=pending&page=' + pageNumber, {
      observe: 'response',
    });
  }

  getAllUsers(pageNumber: number): Observable<any> {
    return this.http.get<any>('/user?page=' + pageNumber, {
      observe: 'response',
    });
  }
  
  deleteUser(id: number): Observable<any> {
    return this.http.delete<any>('/user/' + id + '/', {
      observe: 'response',
    });
  }

  addUser(obj: any): Observable<any> {
    return this.http.post<any>('/user/', obj, { observe: 'response' });
  }

  searchUsers(searchTag: string, pageNumber:number): Observable<any> {
    return this.http.get<any>('/search/team-member?q='+searchTag+'&page='+pageNumber,{
      observe: 'response',
    });
  }

  verifyUser(code: string) :Observable<any> {
    return this.http.get<any>('/user/verify/'+code, { observe: 'response' });
  }

  testWebhook(obj: any) : Observable<any> {
    return this.http.post<any>('/test/webhook', obj, { observe: 'response' });
  }

  getAgentLanguages() :Observable<any> {
    return this.http.get<any>('/oneinbox/agent/lang', { observe: 'response' });
  }

  getAgentBackgroundNoise() :Observable<any> {
    return this.http.get<any>('/oneinbox/agent/background-noise', { observe: 'response' });
  }
}
