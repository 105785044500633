import { ErrorHandler, Injectable } from '@angular/core';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  handleError(error: any) {
    if (error?.name === 'ChunkLoadError') {
      console.error('Chunk load failed:', error);
      window.location.reload();
      // Optionally, you could navigate to an error page or show a message
    } else {
      // Handle other types of errors
      console.error('An error occurred:', error);
    }
  }
}
