<div class="dropdown">
  <button
  class="btn btn-custom-dropdown dropdown-toggle variable-dropdown"
  type="button"
  id="variableDropdownMenu"
  data-bs-toggle="dropdown"
  aria-expanded="false"
  [disabled]="disabled"
  >
    <ng-container *ngIf="selectedFilter === 'all'">
      <span class="cloud-number-name-number">
        <span class="number-icon">
          <span><mat-icon svgIcon="calls"></mat-icon></span>
        </span>
        <span>
          <p class="name">All calls</p>
        </span>
      </span> 
    </ng-container>
    <ng-container *ngIf="selectedFilter === 'number'">
      <span class="cloud-number-name-number">
        <span class="number-icon">
          <span>{{currentCloudNumber?.icon}}</span>
        </span>
        <span>
          <p class="name">{{currentCloudNumber?.name}} <span class="deleted" *ngIf="!currentCloudNumber?.is_active">(Deleted)</span></p>
          <p class="number">{{currentCloudNumber?.number | numberFormat}}</p>
        </span>
      </span>
    </ng-container>
    <ng-container *ngIf="selectedFilter === 'widget'">
      <span class="cloud-number-name-number">
        <span class="cloud-number-logo">
          <img [src]="currentWidget?.logo" uiImageLoader alt="">
        </span>
        <span>
          <p class="name">{{currentWidget?.name}}</p>
        </span> 
      </span>
    </ng-container>
    <mat-icon svgIcon="keyboard_arrow_down"></mat-icon>
  </button>
  <ul
    class="dropdown-menu custom-dropdown-menu variable-dropdown-menu"
    aria-labelledby="headerDropdownMenu"
  >
    <li class="variable-dropdown-item" (click)="allSelected()">
      <span class="cloud-number-name-number">
        <span class="number-icon">
          <span><mat-icon svgIcon="calls"></mat-icon></span>
        </span>
        <span>
          <p class="name">All calls</p>
        </span>
      </span> 
    </li>
    <ng-container *ngIf="sharedDataService.cloudNumbers.length > 0">
      <li class="variable-dropdown-item no-hover">Phone numbers</li>
      <li class="variable-dropdown-item" 
        *ngFor="let number of sharedDataService.cloudNumbers"
        (click)="numberChanged(number)"
        [ngClass]="{active : currentCloudNumber?.id === number?.id}"
      >
        <span class="cloud-number-name-number">
          <span class="number-icon">
            <span>{{number?.icon}}</span>
          </span>
          <span>
            <p class="name">{{number?.name}} <span class="deleted" *ngIf="!number?.is_active">(Deleted)</span></p>
            <p class="number">{{number?.number | numberFormat}}</p>
          </span>
        </span> 
      </li>
    </ng-container>
    <ng-container *ngIf="widgetList.length > 0">
      <li class="variable-dropdown-item no-hover">Widgets</li>
      <li class="variable-dropdown-item" 
        *ngFor="let widget of widgetList"
        (click)="widgetChanged(widget)"
        [ngClass]="{active : currentWidget?.id === widget?.id}"
      >
      <span class="cloud-number-name-number">
          <span class="cloud-number-logo" style="padding: 0;">
            <img [src]="widget?.logo" uiImageLoader alt="">
          </span>
          <span>
            <p class="name">{{widget?.name}}</p>
          </span>
        </span> 
      </li>
    </ng-container>
  </ul>
</div>