import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: 'save-button',
  templateUrl: './save-button.component.html',
  styleUrls: ['./save-button.component.css']
})

export class SaveButtonComponent implements OnInit {

  @Input() saveButtonText!: string;
  @Input() hideSave: boolean = false;
  @Input() disableSave: boolean = false;
  @Input() loading: boolean = false;
  @Input() icon!: string
  @Output() saveClicked = new EventEmitter<boolean>();

  constructor(
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
  ) {
    
  }

  ngOnInit(): void {
    if(this.icon) {
      this.matIconRegistry.addSvgIcon(
        this.icon,
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../assets/svg/'+this.icon+'.svg'
        )
      );
    }
  }

}