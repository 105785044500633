import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'negative-button',
  templateUrl: './negative-button.component.html',
  styleUrl: './negative-button.component.css'
})
export class NegativeButtonComponent {

  @Input() buttonText!: string;
  @Input() disable: boolean = false;
  @Output() clicked = new EventEmitter<boolean>();
  @Input() icon!: string
  @Input() align!: string
  @Input() loading: boolean = false;
}
