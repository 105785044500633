import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { AppSettings } from '../app.settings';

@Injectable({
  providedIn: 'root',
})
export class BusinessService {
  constructor(private http: HttpClient) {}

  getBusiness(): Observable<any> {
    return this.http.get<any>('/business/', { observe: 'response' });
  }

  updateBusiness(obj: any): Observable<any> {
    return this.http.post<any>('/business/', obj, { observe: 'response' });
  }

  updatePersonalProfile(obj: any): Observable<any> {
    return this.http.put<any>('/user/me/', obj, {
      observe: 'response',
    });
  }

  getBusinessCategories(): Observable<any> {
    return this.http.get<any>('/business/categories', { observe: 'response' });
  }

  getApiKeys() :Observable<any> {
    return this.http.get<any>('/user/api-keys', { observe: 'response' });
  }

  generateApiKeys() :Observable<any> {
    return this.http.post<any>('/user/api-keys',{}, { observe: 'response' });
  }

  deleteApiKey(id: number) :Observable<any> {
    return this.http.delete<any>('/user/api-keys/'+id, { observe: 'response' });
  }
  
  getWhatsappDetails() :Observable<any> {
    return this.http.get<any>('/channel/whatsapp', { observe: 'response'} );
  }

  updateWhatsappDetails(obj: any,waba_id: string) :Observable<any> {
    return this.http.put<any>('/channel/whatsapp/'+waba_id,obj, { observe: 'response'} );
  }

  uploadKycDocuments(obj: any): Observable<any> {
    return this.http.post<any>('/kyc/',obj, { observe: 'response'} );
  }

  getKycDocuments(): Observable<any> {
    return this.http.get<any>('/kyc', { observe: 'response'} );
  }

  updatePersonalStatus(obj: any) : Observable<any> {
    return this.http.put<any>('/user/me/status',obj, { observe: 'response'} );
  }

  downloadKycDocs(obj: any): Observable<any> {
    return this.http.put<any>('/kyc/download',obj, { observe: 'response'} );
  }

  getMiscellaneous() :Observable<any> {
    return this.http.get<any>('/miscellaneous', { observe: 'response'} );
  }

  getActivityLogs(params: any) :Observable<any> {
    return this.http.get<any>('/activity', { 
      observe: 'response',
      params: params
    } );
  }

  getMessagingHealthStatus(id: string) :Observable<any> {
    return this.http.get<any>('/channel/health-status/'+id, { 
      observe: 'response',
    });
  }

  getWelcomeMessage() :Observable<any> {
    return this.http.get<any>('/channel/whatsapp/components/?welcome_message=true', { observe: 'response'} );
  }

  getIceBreakers() :Observable<any> {
    return this.http.get<any>('/channel/whatsapp/components/?icebreakers=true', { observe: 'response'} );
  }

  updateWhatsAppComponents(obj: any) :Observable<any> {
    return this.http.post<any>('/channel/whatsapp/components/', obj, { observe: 'response'} );
  }

  getBusinessSettings() :Observable<any> {
    return this.http.get<any>('/business/settings', { observe: 'response'} );
  }

  addBusinessSettings(obj: any) :Observable<any> {
    return this.http.post<any>('/business/settings',obj, { observe: 'response'} );
  }

  getCustomProperty() :Observable<any> {
    return this.http.get<any>('/business/custom-property', { observe: 'response'} );
  }

  addCustomProperty(obj: any) :Observable<any> {
    return this.http.post<any>('/business/custom-property',obj, { observe: 'response'} );
  }

  updateCustomProperty(obj: any) :Observable<any> {
    return this.http.put<any>('/business/custom-property',obj, { observe: 'response'} );
  }

  deleteCustomProperty(name: string) :Observable<any> {
    return this.http.delete<any>('/business/custom-property?field='+name, { observe: 'response'} );
  }

}
