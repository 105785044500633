import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'number-stepper',
  templateUrl: './number-stepper.component.html',
  styleUrl: './number-stepper.component.css'
})
export class NumberStepperComponent implements OnInit{

  @Input() value: number = 1;
  @Input() min: number = 0;
  @Input() max: number = 5000;
  @Output() valueChange = new EventEmitter<number>();

  ngOnInit(): void {
    
  }

  increase() {
    if (this.value < this.max) {
      this.value++;
      this.valueChange.emit(this.value)
    }
  }

  checkLength(event: any){
    if(this.max){
      if(this.min <= this.value && this.value < this.max) return true;
      else return false;
    } else return true;
  }

  restrictLength(){
    this.value = this.value % this.max;
    this.valueChange.emit(this.value)
  }

  decrease() {
    if (this.value > this.min) {
      this.value--;
      this.valueChange.emit(this.value)
    }
  }

  onInputChange(event: any) {
    const inputValue = Number(event.target.value);
    if (inputValue >= this.min && inputValue <= this.max) {
      this.value = inputValue;
    } else if (inputValue < this.min) {
      this.value = this.min;
    } else if (inputValue > this.max) {
      this.value = this.max;
    }
    this.valueChange.emit(this.value)
  }

}
