export class CloudNumber {
  private _id: number
  private _name: string
  private _number: string
  private _country_code: string
  private _created_at: string
  private _updated_at: string
  private _icon: string
  private _agent: CloudNumberAgent
    private _provider: string;
    private _is_active: boolean
    private _deleted_at: Date

    public get is_active(): boolean {
        return this._is_active;
    }

    public set is_active(is_active: boolean) {
        this._is_active = is_active;
    }

    public get deleted_at(): Date {
        return this._deleted_at;
    }

    public set deleted_at(deleted_at: Date) {
        this._deleted_at = deleted_at;
    }


    public get provider(): string {
        return this._provider;
    }

    public set provider(provider: string) {
        this._provider = provider;
    }


    public get agent(): CloudNumberAgent {
        return this._agent;
    }

    public set agent(agent: CloudNumberAgent) {
        this._agent = agent;
    }


  constructor(cloudNumberJson: any) {
    this._id = cloudNumberJson?.id;
    this._name = cloudNumberJson?.name;
    this._number = cloudNumberJson?.number;
    this._country_code = cloudNumberJson?.country_code;
    this._created_at = cloudNumberJson?.created_at;
    this._updated_at = cloudNumberJson?.updated_at;
    this._icon = cloudNumberJson?.icon;
    this._agent = new CloudNumberAgent(cloudNumberJson?.agent);
    this._provider = cloudNumberJson?.provider;
    this._is_active = cloudNumberJson?.is_active;
    this._deleted_at = cloudNumberJson?.deleted_at;
  }

    public get id(): number {
        return this._id;
    }

    public set id(id: number) {
        this._id = id;
    }

    public get name(): string {
        return this._name;
    }

    public set name(name: string) {
        this._name = name;
    }

    public get number(): string {
        return this._number;
    }

    public set number(number: string) {
        this._number = number;
    }

    public get country_code(): string {
        return this._country_code;
    }

    public set country_code(country_code: string) {
        this._country_code = country_code;
    }

    public get created_at(): string {
        return this._created_at;
    }

    public set created_at(created_at: string) {
        this._created_at = created_at;
    }

    public get updated_at(): string {
        return this._updated_at;
    }

    public set updated_at(updated_at: string) {
        this._updated_at = updated_at;
    }

    public get icon(): string {
        return this._icon;
    }

    public set icon(icon: string) {
        this._icon = icon;
    }

}

export class CloudNumberAgent {
    private _id: number
    private _agent_name: string
    private _language: string
    private _profile_pic: string;

    public get profile_pic(): string {
        return this._profile_pic;
    }

    public set profile_pic(profile_pic: string) {
        this._profile_pic = profile_pic;
    }


    constructor(agentJson: any) {
        this._id = agentJson?.id;
        this._agent_name = agentJson?.agent_name;
        this._language = agentJson?.language;
        this._profile_pic = agentJson?.profile_pic;
    }

    public get id(): number {
        return this._id;
    }

    public set id(id: number) {
        this._id = id;
    }

    public get agent_name(): string {
        return this._agent_name;
    }

    public set agent_name(agent_name: string) {
        this._agent_name = agent_name;
    }

    public get language(): string {
        return this._language;
    }

    public set language(language: string) {
        this._language = language;
    }

  }