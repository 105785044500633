import { Component, Input, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'hover-information',
  templateUrl: './hover-information.component.html',
  styleUrls: ['./hover-information.component.css']
})
export class HoverInformationComponent implements OnInit {

  @Input() hoverInfo!: string; 
  @Input() hoverHeading!: string;
  @Input() position!: string;
  @Input() hoverLink!: string;
  @Input() bottom!: string;
  @Input() left: string = '-50%';
  constructor(
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
  ) {
    this.matIconRegistry.addSvgIcon(
      'info_icon_grey',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/info_icon_grey.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'hover_video_green',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/hover_video_green.svg'
      )
    );
   }

  ngOnInit(): void {
  }

}
