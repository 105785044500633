import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'customer-tags',
  templateUrl: './customer-tags.component.html',
  styleUrl: './customer-tags.component.css'
})
export class CustomerTagsComponent implements OnInit{

  newTag: string = '';
  tagList: {
    text: string,
    color: string,
  }[] = [
    {
      text: 'Gray',
      color: '#64748B'
    },
    {
      text: 'Red',
      color: '#DC2626'
    },
    {
      text: 'Orange',
      color: '#F97316'
    },
    {
      text: 'Yellow',
      color: '#FACC15'
    },
    {
      text: 'Green',
      color: '#16A34A'
    },
    {
      text: 'Blue',
      color: '#2563EB'
    },
    {
      text: 'Purple',
      color: '#9333EA'
    },
    {
      text: 'Pink',
      color: '#EC4899'
    },
  ]

  ngOnInit(): void {
    
  }

  restrictLength() {
    
  }
}
