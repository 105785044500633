import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'edit-note',
  templateUrl: './edit-note.component.html',
  styleUrl: './edit-note.component.css'
})
export class EditNoteComponent implements OnInit, OnChanges {

  @Input() note!: string;
  @Input() contact = false;
  inputNote: string = ''
  @Input() isEdit: boolean = false;
  @Input() disable: boolean = false;
  @Output() save = new EventEmitter<string>();
  @Output() delete = new EventEmitter<boolean>();
  @Output() close = new EventEmitter<boolean>();
  showEmojiPicker: boolean = false;
  messageCursorPosition: number = 0;
  @ViewChild('body') body!: ElementRef;

  ngOnInit(): void {
    if(this.note) this.inputNote = this.note;
  }

  ngOnChanges(changes: SimpleChanges): void {
    
  }

  saveNote() {
    this.note = this.inputNote;
    this.isEdit = false;
    this.save.emit(this.note);
  }

  cancelNote() {
    this.inputNote = this.note;
    this.isEdit = false;
    this.close.emit(true);
  }

  openEmojiPicker() {
    this.showEmojiPicker = !this.showEmojiPicker;
    this.messageCursorPosition = this.body.nativeElement.selectionStart;
  }
  addEmoji(event: any) {
    let updatedString = this.inputNote.substring(0, this.messageCursorPosition) +event.emoji.native +this.inputNote.substring(this.messageCursorPosition);
    this.inputNote = updatedString;
    this.messageCursorPosition++;
  }
}
