export class SubscriptionPlan {
    private _id: number;
    private _features: any;
    private _name: string;
    private _description: string;
    private _is_free: boolean;
    private _is_trial: boolean;
    private _monthly_price: number;
    private _yearly_price: number;
    private _type: string;
    private _is_recommended: boolean;
    private _conversation_rate: number;
    private _price: any;
    private _outseta_uid: string;
    private _add_ons: AddOn[];
    private _bring_own_twilio: boolean;
    private _outbound_call: boolean;
    private _white_label: boolean;
    private _total_numbers: number;
    private _total_consumed_numbers: number;
    private _total_agent: number;
    private _total_consumed_agent: number;
    private _add_on_type: string;
    private _index: number;

    public get index(): number {
        return this._index;
    }

    public set index(index: number) {
        this._index = index;
    }


    public get add_on_type(): string {
        return this._add_on_type;
    }

    public set add_on_type(add_on_type: string) {
        this._add_on_type = add_on_type;
    }


    public get total_numbers(): number {
        return this._total_numbers;
    }

    public set total_numbers(total_numbers: number) {
        this._total_numbers = total_numbers;
    }

    public get total_consumed_numbers(): number {
        return this._total_consumed_numbers;
    }

    public set total_consumed_numbers(total_consumed_numbers: number) {
        this._total_consumed_numbers = total_consumed_numbers;
    }

    public get total_agent(): number {
        return this._total_agent;
    }

    public set total_agent(total_agent: number) {
        this._total_agent = total_agent;
    }

    public get total_consumed_agent(): number {
        return this._total_consumed_agent;
    }

    public set total_consumed_agent(total_consumed_agent: number) {
        this._total_consumed_agent = total_consumed_agent;
    }


    public get bring_own_twilio(): boolean {
        return this._bring_own_twilio;
    }

    public set bring_own_twilio(bring_own_twilio: boolean) {
        this._bring_own_twilio = bring_own_twilio;
    }

    public get outbound_call(): boolean {
        return this._outbound_call;
    }

    public set outbound_call(outbound_call: boolean) {
        this._outbound_call = outbound_call;
    }

    public get white_label(): boolean {
        return this._white_label;
    }

    public set white_label(white_label: boolean) {
        this._white_label = white_label;
    }


    public get add_ons(): AddOn[] {
        return this._add_ons;
    }

    public set add_ons(add_ons: AddOn[]) {
        this._add_ons = add_ons;
    }


    public get outseta_uid(): string {
        return this._outseta_uid;
    }

    public set outseta_uid(outseta_uid: string) {
        this._outseta_uid = outseta_uid;
    }

  
    constructor(subscriptionJson: any) {
      this._id = subscriptionJson?.id;
      this._features = subscriptionJson?.features;
      this._name = subscriptionJson?.name;
      this._description = subscriptionJson?.description;
      this._is_free = subscriptionJson?.is_free;
      this._is_trial = subscriptionJson?.is_trial;
      this._monthly_price = subscriptionJson?.monthly_price;
      this._yearly_price = subscriptionJson?.yearly_price;
      this._type = subscriptionJson?.type;
      this._is_recommended = subscriptionJson?.is_recommended;
      this._conversation_rate = subscriptionJson?.conversation_rate;
      this._price = subscriptionJson?.price;
      this._outseta_uid = subscriptionJson?.outseta_uid;
      this._add_ons = [];
      subscriptionJson?.add_ons?.forEach((element: any) => {
        this._add_ons.push(new AddOn(element));
      })
      this._bring_own_twilio = subscriptionJson?.bring_own_twilio;
        this._outbound_call = subscriptionJson?.outbound_call;
        this._white_label = subscriptionJson?.white_label;
        this._total_numbers = subscriptionJson?.total_numbers ?? 0;
        this._total_consumed_numbers = subscriptionJson?.total_consumed_numbers ?? 0;
        this._total_agent = subscriptionJson?.total_agent ?? 0;
        this._total_consumed_agent = subscriptionJson?.total_consumed_agent ?? 0;
        this._add_on_type = subscriptionJson?.add_on_type;
        this._index = subscriptionJson?.index;
    }
  
    public get id(): number {
        return this._id;
    }
    public set id(id: number) {
        this._id = id;
    }
    public get features(): any {
        return this._features;
    }
    public set features(features: any) {
        this._features = features;
    }
    public get name(): string {
        return this._name;
    }
    public set name(name: string) {
        this._name = name;
    }
    public get description(): string {
        return this._description;
    }
    public set description(description: string) {
        this._description = description;
    }
    public get is_free(): boolean {
        return this._is_free;
    }
    public set is_free(is_free: boolean) {
        this._is_free = is_free;
    }
    public get is_trial(): boolean {
        return this._is_trial;
    }
    public set is_trial(is_trial: boolean) {
        this._is_trial = is_trial;
    }
    public get monthly_price(): number {
        return this._monthly_price;
    }
    public set monthly_price(monthly_price: number) {
        this._monthly_price = monthly_price;
    }
    public get yearly_price(): number {
        return this._yearly_price;
    }
    public set yearly_price(yearly_price: number) {
        this._yearly_price = yearly_price;
    }
    public get type(): string {
        return this._type;
    }
    public set type(type: string) {
        this._type = type;
    }
    public get is_recommended(): boolean {
        return this._is_recommended;
    }
    public set is_recommended(is_recommended: boolean) {
        this._is_recommended = is_recommended;
    }
    public get conversation_rate(): number {
        return this._conversation_rate;
    }
    public set conversation_rate(conversation_rate: number) {
        this._conversation_rate = conversation_rate;
    }
    public get price(): any {
      return this._price;
    }
    public set price(price: any) {
        this._price = price;
    }
  
  }

export class AddOn {
    private _text: string;

    public get text(): string {
        return this._text;
    }

    public set text(text: string) {
        this._text = text;
    }

    constructor(addOnJson: any) {
        this._text = addOnJson?.text;
    }
}