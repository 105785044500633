import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { BoldItalicPipe } from '../../pipes/bold-italic.pipe';
import { ClickOutsideDirective } from '../../directives/clickOutside.directive';

import { ImageLoaderDirective } from '../../directives/imageLoader.directive';
import { FormsModule } from '@angular/forms';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule, MAT_DATE_LOCALE} from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

// import { LeftNavigationComponent } from './left-navigation/left-navigation.component';
import { SearchInputComponent } from './search-input/search-input.component';
import { ToggleButtonComponent } from './toggle-button/toggle-button.component';
import { LabelComponent } from './label/label.component';
import { FooterCancelSaveComponent } from './footer-cancel-save/footer-cancel-save.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { SaveButtonComponent } from './save-button/save-button.component';
import { TagComponent } from './tag/tag.component';
import { TextInputComponent } from './text-input/text-input.component';
import { TimePickerComponent } from './time-picker/time-picker.component';
import { CancelButtonComponent } from './cancel-button/cancel-button.component';
import { DocumentPreviewComponent } from './document-preview/document-preview.component';
import { NumberInputComponent } from './number-input/number-input.component';
import { WebsiteInputComponent } from './website-input/website-input.component';
import { EmailInputComponent } from './email-input/email-input.component';
import { EditAddressComponent } from './edit-address/edit-address.component';
import { EditCustomerComponent } from './edit-customer/edit-customer.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ConfirmationPopupComponent } from './confirmation-popup/confirmation-popup.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NumberFormatPipe } from '../../pipes/number-format.pipe';
import { GenderPipe } from '../../pipes/gender.pipe';
import { ExpiryIconComponent } from './expiry-icon/expiry-icon.component';
import { MapPipe } from '../../pipes/map.pipe';
import { TextareaInputComponent } from './textarea-input/textarea-input.component';
import { TertiaryButtonComponent } from './tertiary-button/tertiary-button.component';
import { VariableDropdownComponent } from './variable-dropdown/variable-dropdown.component';
import { SearchPipe } from '../../pipes/search.pipe';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ToastrComponent } from './toastr/toastr.component';
import { HoverInformationComponent } from './hover-information/hover-information.component';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { PaiseToRupeePipe } from '../../pipes/paise-to-rupee.pipe';
import { NamePipe } from '../../pipes/name.pipe';
import { DatePipe } from '../../pipes/dd-mm-yyyy.pipe';
import { PaginationComponent } from './pagination/pagination.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { CustomerProfileComponent } from './customer-profile/customer-profile.component';
import { LabelPipe } from '../../pipes/label.pipe';
import { BarInformationComponent } from './bar-information/bar-information.component';
import { MenuComponent } from './menu/menu.component';
import { AfterSlashPipe } from '../../pipes/after-slash.pipe';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationBarComponent } from './notification-bar/notification-bar.component';
import { BillingFormComponent } from './billing-form/billing-form.component';
import { RateTableComponent } from './rate-table/rate-table.component';
import { CurrencyConversationPipe } from '../../pipes/currency-conversation.pipe';
import { AudioStreamComponent } from './audio-stream/audio-stream.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { InboxDropdownComponent } from './inbox-dropdown/inbox-dropdown.component';
import { ProfilePicComponent } from './profile-pic/profile-pic.component';
import { TimerPipe } from 'src/app/pipes/timer.pipe';
import { CallAddNoteComponent } from './call-add-note/call-add-note.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DateDropdownComponent } from './date-dropdown/date-dropdown.component';
import { AfterDotPipe } from 'src/app/pipes/after-dot.pipe';
import { MessageInputComponent } from './message-input/message-input.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { SafePipe } from 'src/app/pipes/safe.pipe';
import { HoursPipe } from 'src/app/pipes/hours.pipe';
import { MinutesPipe } from 'src/app/pipes/minutes.pipe';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { PopupComponent } from './popup/popup.component';
import { PascalCasePipe } from 'src/app/pipes/pascal-case.pipe';
import { ListTimePipe } from 'src/app/pipes/list-time.pipe';
import { HhmmssPipe } from 'src/app/pipes/hhmmss.pipe';
import { AgentTemplateComponent } from './agent-template/agent-template.component';
import { LiveCallComponent } from './live-call/live-call.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { BrnDialogContentDirective, BrnDialogTriggerDirective } from '@spartan-ng/ui-dialog-brain';
import {
  HlmDialogComponent,
  HlmDialogContentComponent,
  HlmDialogDescriptionDirective,
  HlmDialogFooterComponent,
  HlmDialogHeaderComponent,
  HlmDialogTitleDirective,
} from 'spartan/ui-dialog-helm/src';
import { HlmButtonDirective } from 'spartan/ui-button-helm/src';
import { HlmIconComponent } from '@spartan-ng/ui-icon-helm';
import { BrnSheetContentDirective, BrnSheetTriggerDirective } from '@spartan-ng/ui-sheet-brain';
import {
  HlmSheetComponent,
  HlmSheetContentComponent,
  HlmSheetDescriptionDirective,
  HlmSheetFooterComponent,
  HlmSheetHeaderComponent,
  HlmSheetTitleDirective,
} from '@spartan-ng/ui-sheet-helm';
import { HlmInputDirective } from '@spartan-ng/ui-input-helm';
import { HlmLabelDirective } from '@spartan-ng/ui-label-helm';
import { KeyvaluePipe } from 'src/app/pipes/keyvalue.pipe';

import {
	HlmTabsComponent,
	HlmTabsContentDirective,
	HlmTabsListComponent,
	HlmTabsTriggerDirective,
} from '@spartan-ng/ui-tabs-helm';
import { SubscriptionExpiredComponent } from './subscription-expired/subscription-expired.component';
import { DowntimeComponent } from './downtime/downtime.component';
import { DailerComponent } from './dialer/dailer.component';
import { NameTagComponent } from './name-tag/name-tag.component';
import { SubscriptionPlanComponent } from './subscription-plan/subscription-plan.component';
import { EditNoteComponent } from './edit-note/edit-note.component';
import { IconComponent } from './icon/icon.component';
import { CommandsInputComponent } from './commands-input/commands-input.component';
import { SecondaryButtonComponent } from './secondary-button/secondary-button.component';
import { NumberStepperComponent } from './number-stepper/number-stepper.component';
import { CustomFieldEditDeleteComponent } from './custom-field-edit-delete/custom-field-edit-delete.component';
import { HlmMenuComponent, HlmSubMenuComponent, HlmMenuItemDirective, HlmMenuItemSubIndicatorComponent, HlmMenuLabelComponent, HlmMenuShortcutComponent, HlmMenuSeparatorComponent, HlmMenuItemIconDirective, HlmMenuGroupComponent } from '@spartan-ng/ui-menu-helm';
import { MentionModule } from 'angular-mentions'
import { AgencyExpiredComponent } from './agency-expired/agency-expired.component';
import { InvalidPageComponent } from './invalid-page/invalid-page.component';
import { MatProgressBar } from '@angular/material/progress-bar';
import {
  BrnHoverCardComponent,
  BrnHoverCardContentDirective,
  BrnHoverCardTriggerDirective,
} from '@spartan-ng/ui-hovercard-brain';

import {
  HlmHoverCardContentComponent
} from '@spartan-ng/ui-hovercard-helm';
import { SplitTextPipe } from 'src/app/pipes/split-text.pipe';
import { GettingStartedComponent } from '../getting-started/getting-started.component';
import { GettingStartedStepsComponent } from './getting-started-steps/getting-started-steps.component';
import { EmailFormatterComponent } from './email-formatter/email-formatter.component';
import { QuillModule } from 'ngx-quill';
import { ConnectCalendarComponent } from './connect-calendar/connect-calendar.component';
import { CalConnectionComponent } from './cal-connection/cal-connection.component';
import { OutlineOnClickDirective } from 'src/app/directives/outlineOnClick.directive';
import { NegativeButtonComponent } from './negative-button/negative-button.component';
import { CustomerTagsComponent } from './customer-profile/customer-tags/customer-tags.component';
import { ProfileIconComponent } from './profile-icon/profile-icon.component';



export const Spartan = [
  HlmDialogComponent,
  HlmDialogContentComponent,
  HlmDialogDescriptionDirective,
  HlmDialogFooterComponent,
  HlmDialogHeaderComponent,
  HlmDialogTitleDirective,
  BrnDialogContentDirective, 
  BrnDialogTriggerDirective,
  HlmButtonDirective,
  HlmIconComponent,
  HlmSheetComponent,
  HlmSheetContentComponent,
  HlmSheetDescriptionDirective,
  HlmSheetFooterComponent,
  HlmSheetHeaderComponent,
  HlmSheetTitleDirective,
  BrnSheetContentDirective, BrnSheetTriggerDirective,
  HlmInputDirective,
  HlmTabsComponent,
	HlmTabsContentDirective,
	HlmTabsListComponent,
	HlmTabsTriggerDirective,
  HlmMenuComponent,
  HlmSubMenuComponent,
  HlmMenuItemDirective,
  HlmMenuItemSubIndicatorComponent,
  HlmMenuLabelComponent,
  HlmMenuShortcutComponent,
  HlmMenuSeparatorComponent,
  HlmMenuItemIconDirective,
  HlmMenuGroupComponent,
  BrnHoverCardComponent,
  BrnHoverCardContentDirective,
  BrnHoverCardTriggerDirective,
  HlmHoverCardContentComponent,
]

export const Components = [
  SearchInputComponent,
  ToggleButtonComponent,
  LabelComponent,
  FooterCancelSaveComponent,
  DatePickerComponent,
  SaveButtonComponent,
  TagComponent,
  TextInputComponent,
  TimePickerComponent,
  CancelButtonComponent,
  DocumentPreviewComponent,
  NumberInputComponent,
  WebsiteInputComponent,
  EmailInputComponent,
  EditAddressComponent,
  EditCustomerComponent,
  ConfirmationPopupComponent,
  ExpiryIconComponent,
  TextareaInputComponent,
  TertiaryButtonComponent,
  VariableDropdownComponent,
  PageNotFoundComponent,
  ToastrComponent,
  HoverInformationComponent,
  PaginationComponent,
  CustomerProfileComponent,
  BarInformationComponent,
  MenuComponent,
  NotificationBarComponent,
  BillingFormComponent,
  RateTableComponent,
  AudioStreamComponent,
  InboxDropdownComponent,
  ProfilePicComponent,
  CallAddNoteComponent,
  DateDropdownComponent,
  MessageInputComponent,
  ConfirmationComponent,
  PopupComponent,
  AgentTemplateComponent,
  LiveCallComponent,
  SpinnerComponent,
  SubscriptionExpiredComponent,
  DowntimeComponent,
  DailerComponent,
  NameTagComponent,
  SubscriptionPlanComponent,
  EditNoteComponent,
  IconComponent,
  CommandsInputComponent,
  SecondaryButtonComponent,
  NumberStepperComponent,
  CustomFieldEditDeleteComponent,
  AgencyExpiredComponent,
  InvalidPageComponent,
  GettingStartedStepsComponent,
  EmailFormatterComponent,
  ConnectCalendarComponent,
  CalConnectionComponent,
  NegativeButtonComponent,
  CustomerTagsComponent,
  ProfileIconComponent
];

export const Pipes = [
  BoldItalicPipe,
  PascalCasePipe,
  ListTimePipe,
  HhmmssPipe,
  KeyvaluePipe,
  SafePipe,
  HoursPipe,
  MinutesPipe,
  AfterDotPipe,
  TimerPipe,
  CurrencyConversationPipe,
  AfterSlashPipe,
  LabelPipe,
  PaiseToRupeePipe,
  NamePipe,
  DatePipe,
  SearchPipe,
  MapPipe,
  NumberFormatPipe,
  GenderPipe,
  SplitTextPipe
];

export const Directives = [
  ClickOutsideDirective,
  ImageLoaderDirective,
  OutlineOnClickDirective
]

@NgModule({
  declarations: [
    ...Directives,
    ...Components,
    ...Pipes,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    FormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatRadioModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    NgbModule,
    NgbTooltipModule,
    NgxPaginationModule,
    NgxSkeletonLoaderModule.forRoot({ animation: 'pulse', loadingText: 'Loading...' }),
    NgOtpInputModule,
    NgxSkeletonLoaderModule,
    MatTooltipModule,
    PickerModule,
    ...Spartan,
    DragDropModule,
    MatTooltipModule,
    MentionModule,
    MatProgressBar,
    QuillModule.forRoot(),
  ],
  exports: [
    ...Spartan,
    ...Directives,
    ...Components,
    ...Pipes,
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: null },
  ]
})
export class SharedModule {}
