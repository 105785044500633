<div class="plan-card" [ngClass]="plan?.is_recommended ? 'recommended' : ''">
  <div class="plan-details">
    <div class="plan-title">
      <div>
        <span class="plan-name">{{plan?.name}}</span>
        <!-- <span class="plan-description">{{plan?.description}}</span> -->
      </div>
      <span *ngIf="plan?.is_recommended" class="recommended-tag">Most popular</span>
    </div>
    <span class="plan-price">${{isMonthly ? plan?.monthly_price : plan?.yearly_price }}/m</span>
    <div class="plan-features">
      <ng-container *ngFor="let feature of plan?.features">
        <div class="plan-feature-description">
          <mat-icon [ngClass]="feature?.is_available ? 'check-icon' : 'cross-icon'" [svgIcon]="feature?.is_available ? 'check' : 'x'"></mat-icon>
          <span>
            {{feature?.text}}
          </span>
        </div>
      </ng-container>
      <div class="plan-feature-description" *ngIf="numberAddOnDetails?.id">
        <icon [icon]="'add'" [iconColor]="'#7E22CE'" [size]="'16px'"></icon>
        <span>
          Add-on numbers are ${{numberAddOnDetails?.monthly_price}}/number/month
        </span>
      </div>
      <div class="plan-feature-description" *ngIf="aiAgentAddOnDetails?.id">
        <icon [icon]="'add'" [iconColor]="'#7E22CE'" [size]="'16px'"></icon>
        <span>
          Add-on agents are ${{aiAgentAddOnDetails?.monthly_price}}/agent/month
        </span>
      </div>
    </div>
  </div>
  <save-button
    class="w-100"
    *ngIf="plan?.is_recommended" 
    [saveButtonText]="subscriptionService?.currentPlan?.id === plan?.id ? 'Current plan' : ('Buy '+ plan?.name)"
    (saveClicked)="buyPlan()"
    [loading]="loader"
  ></save-button>
  <cancel-button 
    *ngIf="!plan?.is_recommended" 
    [cancelButtonText]="subscriptionService?.currentPlan?.id === plan?.id ? 'Current plan' : ('Buy '+ plan?.name)"
    (cancelClicked)="buyPlan()"
    [loading]="loader"
  ></cancel-button>
</div>