<div class="toastr" [class.show]="show" [style.top.px]="positionTop" [style.right.px]="positionRight" [style.bottom.px]="positionBottom" [style.left.px]="positionLeft">
  <span class="toast-icon">
    <mat-icon *ngIf="_toastr.type === 'success'" svgIcon="toast_success"></mat-icon>
    <mat-icon *ngIf="_toastr.type === 'warning'" svgIcon="toast_warning"></mat-icon>
    <mat-icon *ngIf="_toastr.type === 'error'" svgIcon="toast_error"></mat-icon>
    <mat-icon *ngIf="_toastr.type === 'info'" svgIcon="toast_info"></mat-icon>
  </span>
  <div class="toast-message">
    <h5 *ngIf="_toastr.type === 'success'">Success</h5>
    <h5 *ngIf="_toastr.type === 'warning'">Warning</h5>
    <h5 *ngIf="_toastr.type === 'error'">Error</h5>
    <h5 *ngIf="_toastr.type === 'info'">Info</h5>
    <div [innerHTML]="_toastr.message"></div>
  </div>
</div>