import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {

  public message: string = '';
  public type!: string;
  public visibility: boolean = false;
  public timeout: number = 3000;

  constructor() { }

  success(msg: string, timeout: number = 3000) {
    this.message = msg;
    this.type = 'success';
    this.visibility = true;
    this.timeout = timeout;
  }
  error(msg: string, timeout: number = 3000) {
    this.message = msg;
    this.type = 'error';
    this.visibility = true;
    this.timeout = timeout;
  }
  warning(msg: string) {
    this.message = msg;
    this.type = 'warning';
    this.visibility = true;
  }
  info(msg: string) {
    this.message = msg;
    this.type = 'info';
    this.visibility = true;
  }

  hide() {
    this.message = '';
    this.visibility = false;
  }
}
