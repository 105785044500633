import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SubscriptionPlan } from '../models/subscription_plan';
import { Subscription } from '../models/subscription';
import { BillingDetails } from '../models/billing-details';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  currentPlan!: SubscriptionPlan;
  plans: SubscriptionPlan[] = [];
  subscription!: Subscription;
  billingDetails: BillingDetails[] = [];

  allSubscriptions: Subscription[] = [];

  planToBuy!: SubscriptionPlan;
  orderDetails: any;

  newPlans: SubscriptionPlan[] = [];
  displayNewPlan: boolean = false;

  constructor(private http: HttpClient) { }

  //Setters & Getters
  getSubscription() {
    return this.subscription;
  }
  setSubscription(subscription: any) {
    this.subscription = new Subscription(subscription);
    console.log(subscription);
  }
  


  getCurrentSubscription(): Observable<any> {
    return this.http.get<any>('/subscription?active=1', {
      observe: 'response',
    })
  }

  getAllSubscriptions(): Observable<any> {
    return this.http.get<any>('/subscription', {
      observe: 'response',
    })
  }

  getPlans(): Observable<any> {
    return this.http.get<any>('/subscription/plans', {
      observe: 'response',
    });
  }

  getInvoices(type: string, page: number) :Observable<any> {
    if(type === 'invoices') {
      return this.http.get<any>('/subscription/payments', {
        observe: 'response',
        params: {
          page: page,
        }
      });
    } else {
      return this.http.get<any>('/subscription/payments', {
        observe: 'response',
        params: {
          page: page,
          trans: 'all',
        }
      });
    }
  }

  createSubscriptionOrder(obj: any) :Observable<any> {
    return this.http.post<any>('/subscription/order', obj, {
      observe: 'response'
    });
  }

  validateSubscriptionOrder(obj: any) :Observable<any> {
    return this.http.put<any>('/subscription/order', obj, {
      observe: 'response'
    });
  }

  getBillingDetails() :Observable<any> {
    return this.http.get<any>('/business/billing-address', {
      observe: 'response'
    })
  }

  addBillingDetails(obj: any) :Observable<any> {
    return this.http.post<any>('/business/billing-address', obj, {
      observe: 'response'
    })
  }

  updateBillingDetails(obj: any, id: number) :Observable<any> {
    return this.http.put<any>('/business/billing-address/'+id, obj, {
      observe: 'response'
    })
  }

  getRateCard(): Observable<any> {
    return this.http.get<any>('/rate/', {
      observe: 'response'
    })
  }

  getLoggedInRateCard() :Observable<any> {
    return this.http.get<any>('/rate/me', {
      observe: 'response'
    })
  }

  getLoggedInCallRateCard() :Observable<any> {
    return this.http.get<any>('/rate/voice/me', {
      observe: 'response'
    })
  }

  checkNewVersions() :Observable<any> {
    return this.http.get<any>('/subscription/plans/opt-in', {
      observe: 'response'
    })
  }

  optInNewVersions(obj: any) :Observable<any> {
    return this.http.post<any>('/subscription/plans/opt-in',obj, {
      observe: 'response'
    })
  }

  getAddOns() :Observable<any> {
    return this.http.get<any>('/subscription/plans?plan_type=add-ons', {
      observe: 'response',
    });
  }

  buyAddOns(obj: any) :Observable<any> {
    return this.http.post<any>('/subscription/add-on',obj, {
      observe: 'response',
    });
  }

  validateAddOns(obj: any) :Observable<any> {
    return this.http.put<any>('/subscription/add-on',obj, {
      observe: 'response',
    });
  }

  getAddOnsUsage() :Observable<any> {
    return this.http.get<any>('/subscription/usage', {
      observe: 'response',
    });
  }

  getLTD(coupon: string) :Observable<any> {
    return this.http.get<any>('/subscription/ltd?code='+ coupon, {
      observe: 'response',
    });
  }

  getStripeUrl() :Observable<any> {
    return this.http.get<any>('/subscription/billing', {
      observe: 'response',
    });
  }

  buyStripePlan(obj: any) :Observable<any> {
    return this.http.post<any>('/subscription/buy',obj, {
      observe: 'response',
    });
  }
}

