export class BillingDetails {
  private _id: number;
  private _gst_no: string;
  private _billing_name: string;
  private _address_line_1: string;
  private _address_line_2: string;
  private _zip: string;
  private _state: string;
  private _city: string;
  private _country: string;
  private _default: boolean;
  private _is_active: boolean;
  private _created_at: Date;
  private _updated_at: Date;
  private _business: number;

  constructor(billingDetailsJson: any) {
    this._id = billingDetailsJson?.id;
    this._gst_no = billingDetailsJson?.gst_no;
    this._billing_name = billingDetailsJson?.billing_name;
    this._address_line_1 = billingDetailsJson?.address_line_1;
    this._address_line_2 = billingDetailsJson?.address_line_2;
    this._zip = billingDetailsJson?.zip;
    this._state = billingDetailsJson?.state;
    this._city = billingDetailsJson?.city;
    this._country = billingDetailsJson?.country;
    this._default = billingDetailsJson?.default;
    this._is_active = billingDetailsJson?.is_active;
    this._created_at = billingDetailsJson?.created_at;
    this._updated_at = billingDetailsJson?.updated_at;
    this._business = billingDetailsJson?.business;
  }  

  public get id(): number {
      return this._id;
  }
  public set id(id: number) {
      this._id = id;
  }
  public get gst_no(): string {
      return this._gst_no;
  }
  public set gst_no(gst_no: string) {
      this._gst_no = gst_no;
  }
  public get billing_name(): string {
      return this._billing_name;
  }
  public set billing_name(billing_name: string) {
      this._billing_name = billing_name;
  }
  public get address_line_1(): string {
      return this._address_line_1;
  }
  public set address_line_1(address_line_1: string) {
      this._address_line_1 = address_line_1;
  }
  public get address_line_2(): string {
      return this._address_line_2;
  }
  public set address_line_2(address_line_2: string) {
      this._address_line_2 = address_line_2;
  }
  public get zip(): string {
      return this._zip;
  }
  public set zip(zip: string) {
      this._zip = zip;
  }
  public get state(): string {
      return this._state;
  }
  public set state(state: string) {
      this._state = state;
  }
  public get city(): string {
      return this._city;
  }
  public set city(city: string) {
      this._city = city;
  }
  public get country(): string {
      return this._country;
  }
  public set country(country: string) {
      this._country = country;
  }
  public get default(): boolean {
      return this._default;
  }
  public set default(_default: boolean) {
      this._default = _default;
  }
  public get is_active(): boolean {
      return this._is_active;
  }
  public set is_active(is_active: boolean) {
      this._is_active = is_active;
  }
  public get created_at(): Date {
      return this._created_at;
  }
  public set created_at(created_at: Date) {
      this._created_at = created_at;
  }
  public get updated_at(): Date {
      return this._updated_at;
  }
  public set updated_at(updated_at: Date) {
      this._updated_at = updated_at;
  }
  public get business(): number {
      return this._business;
  }
  public set business(business: number) {
      this._business = business;
  }

}