<div class="assign-label-popup">
  <div class="white-card">
    <div class="white-card-heading">
      <span class="card-heading-title">Address</span>
    </div>
    <div class="card-edit-information">
      <span>
        <p class="edit-field-title">Line 1<span class="asterisk">*</span></p>
        <text-input [inputText]="line_1" (textChange)="line_1 = $event"></text-input>
      </span>
      <span>
        <p class="edit-field-title">Line 2</p>
        <text-input [inputText]="line_2" (textChange)="line_2 = $event"></text-input>
      </span>
      <div class="city-state-fields">
      <span>
        <p class="edit-field-title">City<span class="asterisk">*</span></p>
        <text-input [inputText]="city" (textChange)="city = $event"></text-input>
      </span>
      <span>
        <p class="edit-field-title">State<span class="asterisk">*</span></p>
        <text-input [inputText]="state" (textChange)="state = $event"></text-input>
      </span></div>
      <span class="pincode-field">
        <p class="edit-field-title">Pin Code<span class="asterisk">*</span></p>
        <input type="text" [(ngModel)]="pincode"  (keypress)="checkPincode($event)">
      </span>
    </div>
    <div class="footer-buttons">
      <cancel-button 
        [cancelButtonText]="'Cancel'"
        (cancelClicked)="cancelEdit()"
      ></cancel-button>
      <save-button
        [saveButtonText]="'Apply'"
        [loading]="loaderUpdateCustomer"
        [disableSave]="line_1 == '' || city == '' || state == '' || pincode.toString().length !== 6"
        (saveClicked)="updateCustomer()"
      ></save-button>
    </div>
  </div>
</div>

<div class="shaded-screen"></div>