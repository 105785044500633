<span class="document-container" *ngIf="docType === 'pdf'">
  <i class="fa-solid fa-file-pdf"></i>
</span>
<span class="document-container" *ngIf="docType === 'docx'">
  <i class="fa-solid fa-file-lines"></i>
</span>
<span class="document-container" *ngIf="docType === 'pptx'">
  <i class="fa-solid fa-file-powerpoint"></i>
</span>
<span class="document-container" *ngIf="docType === 'xlsx'">
  <mat-icon
    style="font-size: 30px; width: 30px; height: 30px"
    svgIcon="excel_icon"
  ></mat-icon>
</span>