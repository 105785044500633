import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { BillingDetails } from 'src/app/models/billing-details';
import { LoggedUser } from 'src/app/models/logged-user';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { ToasterService } from 'src/app/services/toastr.service';

@Component({
  selector: 'billing-form',
  templateUrl: './billing-form.component.html',
  styleUrls: ['./billing-form.component.css']
})
export class BillingFormComponent implements OnInit {

  constructor(
    public subscriptionService: SubscriptionService,
    private _formBuiler: UntypedFormBuilder,
    public _sharedDataService: SharedDataService,
    private _toast: ToasterService,
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
  ) { 
    this.matIconRegistry.addSvgIcon(
      'edit',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/edit.svg'
      )
    );
  }

  @Output() close = new EventEmitter<boolean>();
  @Output() defaultBilling = new EventEmitter<BillingDetails>();
  isOpen: boolean = true;
  billingDetails: BillingDetails[] = this.subscriptionService.billingDetails;
  defaultBillingDetails!: BillingDetails;
  loaderBillingDetails: boolean = false;

  ngOnInit(): void {
    if(this.billingDetails?.length > 0) this.defaultBillingDetails = this.billingDetails[0];
    else {
      this.defaultBillingDetails = new BillingDetails(null);
      this.defaultBillingDetails.address_line_1 = '';
      this.defaultBillingDetails.city = '';
      this.defaultBillingDetails.country = '';
      this.defaultBillingDetails.state = '';
      this.defaultBillingDetails.zip = '';
    }
  }

  addBillingDetails() {
    this.loaderBillingDetails = true
    let input = {
      billing_name: this._sharedDataService.business?.name,
      address_line_1: this.defaultBillingDetails.address_line_1,
      zip: this.defaultBillingDetails.zip,
      state: this.defaultBillingDetails.state,
      city: this.defaultBillingDetails.city,
      country: this.defaultBillingDetails.country,
      default: true,
    };
    this.subscriptionService.addBillingDetails(input).subscribe({
      next: resp => {
        if(resp.body.code === 201) {
          this.defaultBillingDetails = new BillingDetails(resp.body.data);
          this.subscriptionService.billingDetails = [this.defaultBillingDetails];
          this.defaultBilling.emit(this.defaultBillingDetails);
        } else {
          this._toast.error(resp.body.message);
        }
        this.loaderBillingDetails = false;
      }, error: error => { 
        this.loaderBillingDetails = false;
        this._toast.error(error)
      }
    })
  }

}
