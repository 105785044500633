export class Widget {
  private _id: number
  private _name: string
  private _brand_name: string
  private _logo: string
  private _secret: string
  private _properties: Properties
  private _created_at: string
  private _updated_at: any
  private _is_active: boolean
  private _business: number
  private _added_by: number
  private _agent: WidgetAgent;

    public get agent(): WidgetAgent {
        return this._agent;
    }

    public set agent(agent: WidgetAgent) {
        this._agent = agent;
    }


  constructor(widgetJson: any) {
    this._id = widgetJson?.id;
    this._name = widgetJson?.name;
    this._brand_name = widgetJson?.brand_name;
    this._logo = widgetJson?.logo;
    this._secret = widgetJson?.secret;
    this._properties = widgetJson?.properties;
    this._created_at = widgetJson?.created_at;
    this._updated_at = widgetJson?.updated_at;
    this._is_active = widgetJson?.is_active;
    this._business = widgetJson?.business;
    this._added_by = widgetJson?.added_by;
    this._properties = new Properties(widgetJson?.properties);
    this._agent = new WidgetAgent(widgetJson?.agent)
  }

  public get properties(): Properties {
    return this._properties;
}

public set properties(properties: Properties) {
    this._properties = properties;
}

    public get id(): number {
        return this._id;
    }

    public set id(id: number) {
        this._id = id;
    }

    public get name(): string {
        return this._name;
    }

    public set name(name: string) {
        this._name = name;
    }

    public get brand_name(): string {
        return this._brand_name;
    }

    public set brand_name(brand_name: string) {
        this._brand_name = brand_name;
    }

    public get logo(): string {
        return this._logo;
    }

    public set logo(logo: string) {
        this._logo = logo;
    }

    public get secret(): string {
        return this._secret;
    }

    public set secret(secret: string) {
        this._secret = secret;
    }

    public get created_at(): string {
        return this._created_at;
    }

    public set created_at(created_at: string) {
        this._created_at = created_at;
    }

    public get updated_at(): any {
        return this._updated_at;
    }

    public set updated_at(updated_at: any) {
        this._updated_at = updated_at;
    }

    public get is_active(): boolean {
        return this._is_active;
    }

    public set is_active(is_active: boolean) {
        this._is_active = is_active;
    }

    public get business(): number {
        return this._business;
    }

    public set business(business: number) {
        this._business = business;
    }

    public get added_by(): number {
        return this._added_by;
    }

    public set added_by(added_by: number) {
        this._added_by = added_by;
    }

}

export class WidgetAgent {
    private _agent_name: string
    private _id: number
    private _profile_pic: string

    public get profile_pic(): string {
        return this._profile_pic;
    }

    public set profile_pic(profile_pic: string) {
        this._profile_pic = profile_pic;
    }


    constructor(widgetAgentJson: any) {
        this._agent_name  =widgetAgentJson?.agent_name;
        this._id = widgetAgentJson?.id;
        this._profile_pic = widgetAgentJson?.profile_pic;
    }

    public get agent_name(): string {
        return this._agent_name;
    }

    public set agent_name(agent_name: string) {
        this._agent_name = agent_name;
    }

    public get id(): number {
        return this._id;
    }

    public set id(id: number) {
        this._id = id;
    }

}

export class Properties {

    private _color: string
    private _subtitle: string
    private _position: string
    private _button_text: string
    private _welcome_text: string
    private _text_color: string

    public get text_color(): string {
        return this._text_color;
    }

    public set text_color(text_color: string) {
        this._text_color = text_color;
    }


    constructor(proprtiesJson: any) {
        this._color = proprtiesJson?.color ?? '#16BDCA';
        this._subtitle = proprtiesJson?.subtitle ?? 'Talk to customer support';
        this._position = proprtiesJson?.position ?? 'bottom-right';
        this._button_text = proprtiesJson?.button_text ?? 'Call us';
        this._welcome_text = proprtiesJson?.welcome_text ?? 'An agent is ready solve your questions.';
        this._text_color = proprtiesJson?.text_color ?? '#070A0B';
    }

    public get color(): string {
        return this._color;
    }

    public set color(color: string) {
        this._color = color;
    }

    public get subtitle(): string {
        return this._subtitle;
    }

    public set subtitle(subtitle: string) {
        this._subtitle = subtitle;
    }

    public get position(): string {
        return this._position;
    }

    public set position(position: string) {
        this._position = position;
    }

    public get button_text(): string {
        return this._button_text;
    }

    public set button_text(button_text: string) {
        this._button_text = button_text;
    }

    public get welcome_text(): string {
        return this._welcome_text;
    }

    public set welcome_text(welcome_text: string) {
        this._welcome_text = welcome_text;
    }

}