<div class="menu-container" [ngStyle]="{'padding-top' : sharedService.getNotificationBarSize()+ 'px'}">
  <div class="menu-heading">
    <img [src]="this.agencyService.darkLogo" style="height: 32px;" alt="">
  </div>
  <div class="menu-body" [ngStyle]="{'height' : agencyService.isAgency ? 'calc(100% - 44px)' : 'calc(100% - 64px)'}">
    <div class="menu-top">
      <a *ngIf="!(sharedService.business.is_agent_created && sharedService.business.is_agent_test && sharedService.business.is_agent_deployed && sharedService.business.is_live)" (click)="activeTab = 'getting-started'" [ngClass]="{active: activeTab === 'getting-started'}" class="menu-item"
        routerLink="/getting-started" style="position: relative;">
        <mat-progress-spinner
          mode="determinate"
          diameter="20"
          [value]="
            (sharedService.business.is_agent_created ? 25 : 0)  +
            (sharedService.business.is_agent_test ? 25 : 0)  +
            (sharedService.business.is_agent_deployed ? 25 : 0)  +
            (sharedService.business.is_live ? 25 : 0)
          "
          class="white-progress"
        ></mat-progress-spinner>
        <span class="background-spinner"></span>
        <span class="name">Getting Started</span>
      </a>
      <a (click)="activeTab = 'agents'" [ngClass]="{active: activeTab === 'agents'}" class="menu-item"
        routerLink="/agents">
        <mat-icon svgIcon="aiagents"></mat-icon>
        <span class="name">AI Agents</span>
      </a>
      <!-- <a (click)="activeTab = 'calls'" [ngClass]="{active: activeTab === 'calls'}" class="menu-item"
        routerLink="/calls">
        <mat-icon svgIcon="calls"></mat-icon>
        <span class="name">Calls</span>
      </a> -->
      <a (click)="activeTab = 'analytics'" [ngClass]="{active: activeTab === 'analytics'}" class="menu-item"
        routerLink="/analytics">
        <icon [icon]="'analytics'" [size]="'20px'"></icon>
        <span class="name">Analytics</span>
      </a>
      <a *ngIf="sharedService.business?.subscription?.subscription_type !== 1" (click)="activeTab = 'infobase'" [ngClass]="{active: activeTab === 'infobase'}" class="menu-item"
        routerLink="/infobase">
        <mat-icon svgIcon="infobase"></mat-icon>
        <span class="name">Infobase</span>
      </a>
      <a *ngIf="sharedService?.loggedUser?.is_agency" (click)="activeTab = 'clients'" [ngClass]="{active: activeTab === 'clients'}" class="menu-item"
        routerLink="/clients">
        <mat-icon svgIcon="clients"></mat-icon>
        <span class="name">Clients</span>
      </a>
      <a (click)="activeTab = 'contacts'" [ngClass]="{active: activeTab === 'contacts'}" class="menu-item"
        routerLink="/contacts">
        <mat-icon svgIcon="person"></mat-icon>
        <span class="name">Contacts</span>
      </a>
      <a (click)="activeTab = 'settings'" [ngClass]="{active: activeTab === 'settings'}" class="menu-item"
        routerLink="/settings/phone-numbers">
        <mat-icon svgIcon="settings"></mat-icon>
        <span class="name">Settings</span>
      </a>
      <div class="inbox-list">
        <span class="menu-item-inbox" (click)="inboxDropdown = !inboxDropdown">
          <span [ngStyle]="{'transform': inboxDropdown ? 'rotate(0deg)' : 'rotate(270deg)'}"><icon [iconColor]="'#64748B'" [icon]="'keyboard_arrow_down'" [size]="'20px'" [size]="'#64748B'"></icon></span> Inboxes
        </span>
        <div class="menu-list" *ngIf="inboxDropdown">
          <a *ngFor="let number of sharedService.cloudNumbers" style="padding: 2px 8px;" (click)="activeTab = ('inbox/'+number?.id)" [ngClass]="{active: activeTab === ('inbox/'+number?.id)}" class="menu-item"
            [routerLink]="'/inbox/'+number?.id">
            <span class="inbox-icon">
              {{number?.icon}}
              <span><icon [icon]="'calls'" [size]="'10px'"></icon></span>
            </span>
            <span class="ver-2px">
              <span class="inbox-name">{{number?.name}}</span>
              <span class="inbox-number">{{number?.number}}</span>
            </span>
          </a>
          <a *ngFor="let widget of sharedService.widgetList" style="padding: 2px 8px;" (click)="activeTab = ('inbox?widget='+widget?.id)" [ngClass]="{active: activeTab === ('inbox?widget='+widget?.id)}" class="menu-item"
            (click)="widgetClicked(widget)">
            <span class="inbox-icon">
              <img style="width: 20px;height: 20px;" *ngIf="widget?.logo" [src]="widget?.logo" uiImageLoader alt="">
              <ng-container *ngIf="!widget?.logo">
                {{widget?.name?.slice(0,2)?.toUpperCase()}}
              </ng-container>
              <span><icon [icon]="'widgets'" [size]="'10px'"></icon></span>
            </span>
            <span class="ver-2px">
              <span class="inbox-name">{{widget?.name}}</span>
            </span>
          </a>
        </div>
      </div>
      <div class="inbox-list">
        <span class="menu-item-inbox" (click)="agentsDropdown = !agentsDropdown">
          <span [ngStyle]="{'transform': agentsDropdown ? 'rotate(0deg)' : 'rotate(270deg)'}"><icon [iconColor]="'#64748B'" [icon]="'keyboard_arrow_down'" [size]="'20px'" [size]="'#64748B'"></icon></span> Your AI Agents 
        </span>
        <div class="menu-list" *ngIf="agentsDropdown">
          <a *ngFor="let agent of agentsList" style="padding: 4px 8px;" (click)="agentClicked(agent)" [ngClass]="{active: activeTab === ('inbox?agent='+agent?.agent_id)}" class="menu-item"
            >
            <span class="agent-profile-pic"><img [src]="agent?.profile_pic" uiImageLoader alt=""></span>
            <span class="ver-2px">
              <span class="inbox-name">{{agent?.agent_name}}</span>
            </span>
          </a>
        </div>
      </div>
    </div>
    <div class="menu-bottom">
      <!-- <div class="subscription-expired" *ngIf="sharedService.business.subscription?.is_expired">
        <span>Your subscription has expired!</span>
        <save-button class="w-100" [saveButtonText]="'Renew Now'" (saveClicked)="goToSubscription()"></save-button>
      </div> -->
      <ng-container *ngIf="agencyService.isPricingEnabled">
        <a [ngClass]="{active: activeTab === 'billing'}" (click)="activeTab = 'billing'" class="menu-item" routerLink="/billing/wallet">
          <span class="ver-2px">
            <span class="hor-8px">
              <icon [icon]="'usage'" [size]="'20px'"></icon>
              <span class="name">
                Usage <span *ngIf="walletService.totalMinutes">{{walletService.walletBalance}}/{{walletService.totalMinutes}}</span>
              </span>
            </span>
            <mat-progress-bar style="width: 100%;" mode="determinate" [value]="(walletService.totalMinutes  === 0) ? 100 : (walletService.walletBalance / walletService.totalMinutes)*100"></mat-progress-bar>
          </span>
        </a>
      </ng-container>
      <ng-container *ngIf="!agencyService.isPricingEnabled">
        <a class="menu-item" style="cursor: default;background: transparent;">
          <span class="ver-2px">
            <span class="hor-8px">
              <icon [icon]="'usage'" [size]="'20px'"></icon>
              <span class="name">
                Usage <span *ngIf="walletService.totalMinutes">{{walletService.walletBalance}}/{{walletService.totalMinutes}}</span>
              </span>
            </span>
            <mat-progress-bar style="width: 100%;" mode="determinate" [value]="(walletService.totalMinutes  === 0) ? 100 : (walletService.walletBalance / walletService.totalMinutes)*100"></mat-progress-bar>
          </span>
        </a>
      </ng-container>
      <div *ngIf="!agencyService.isAgency" style="position: relative;" (clickOutside)="handleClickedOutside()">
        <a (click)="helpDropdownClicked()" class="menu-item">
          <mat-icon svgIcon="help"></mat-icon>
          <span class="name">
            Help & Support
          </span>
        </a>
        <div *ngIf="showDropdown" class="dropdown-container">
          <a href="https://vibtree.notion.site/OneInbox-Docs-13a2cc6eaa794e90bf819c1243bc4a45" target="_blank" rel="noopener noreferrer" class="dropdwon-item menu-item">
            <mat-icon svgIcon="document_scanner"></mat-icon>
           <span>Guide</span>
          </a>
          <a href="http://feedback.oneinbox.ai/roadmap" target="_blank" rel="noopener noreferrer" class="dropdwon-item menu-item">
            <mat-icon svgIcon="roadmap"></mat-icon>
           <span>Roadmap</span>
          </a>
          <a href="javascript:void(0)" (click)="openIntercom()" class="dropdwon-item menu-item">
            <mat-icon svgIcon="support"></mat-icon>
           <span>Support</span>
          </a>
          <div (click)="communityClicked()" class="community-body menu-item" style="padding: 0px;">
            <div class="dropdwon-item" style="color: #070A0B;">
              <mat-icon svgIcon="user_group"></mat-icon>
              <span>Community</span>
            </div>
            <mat-icon svgIcon="navigate_next"></mat-icon>

          </div>
        </div>
        <div *ngIf="showSubDropdown" class="sub-dropdown">
          <a href="https://discord.com/invite/tAbnKbxCa8" target="_blank" rel="noopener noreferrer" class="subdropdown-item menu-item">Discord</a>
          <a href="https://www.facebook.com/groups/1128078304935828/" target="_blank" rel="noopener noreferrer" class="subdropdown-item menu-item">Facebook</a>
         
        </div>
      </div>
      <a (click)="activeTab = 'user'" [ngClass]="{active: activeTab === 'user'}" class="menu-item"
        routerLink="/user/profile">
        <mat-icon svgIcon="account_grey"></mat-icon>
        <span class="name">Profile</span>
      </a>
    </div>
  </div>
</div>