<button 
  class="container-footer-cancel" 
  [disabled]="disableCancel || loading" 
  [hidden]="hideCancel"
  (click)="cancelClicked.emit(true)"
  [ngClass]="loading ? 'loading' : ''"
  >
  <ng-container *ngIf="!loading">
    <mat-icon *ngIf="icon && !align" [svgIcon]="icon"></mat-icon>
    {{cancelButtonText}}
    <mat-icon *ngIf="align==='left'" [svgIcon]="icon"></mat-icon>
  </ng-container>
  <mat-progress-spinner
    *ngIf="loading"
    mode="indeterminate"
    diameter="22"
    class="white-progress"
  ></mat-progress-spinner>
</button>