<hlm-sheet style="width: 450px;" [state]="isOpen ? 'open' : 'closed'" [side]="'right'" (closed)="close.emit(customer)">
  <hlm-sheet-content style="overflow: auto;" *brnSheetContent="let ctx">
    <div class="white-card">
      <div class="card-heading">
        <span *ngIf="customer?.id">{{ customer | name }}</span>
        <span *ngIf="!customer?.id">Add contact</span>
        <div class="dropdown" *ngIf="customer?.id">
          <button class="btn-custom-dropdown dropdown-toggle variable-dropdown test-dropdown border-none" type="button"
            style="margin-right: 30px;" outlineOnClick
            id="variableDropdownMenu" data-bs-toggle="dropdown" aria-expanded="false">
            <icon [icon]="'more_vertical'" [iconColor]="'#64748B'" [size]="'24px'"></icon>
          </button>
          <ul class="dropdown-menu custom-dropdown-menu variable-dropdown-menu"
            aria-labelledby="headerDropdownMenu" style="width: auto;">
              <li class="variable-item" (click)="deleteContactConfirm()">
                <span>Delete</span>
              </li>
          </ul>
        </div>
      </div>
      <div class="white-card-body">
        <div class="ver-8px">
          <div class="customer-properties">
            <div class="property">
              <span class="field-key"><mat-icon svgIcon="person"></mat-icon> Name</span>
              <span [ngStyle]="{'color': (editedCustomer?.name && editedCustomer?.name !== '') ? 'var(--primary-color)' : 'var(--secondary-color)'}" *ngIf="activeInput !== 'name'" (click)="activeInput = 'name'" class="field-value">{{(editedCustomer?.name && editedCustomer?.name !== '') ? editedCustomer?.name : 'John Doe'}}</span>
              <text-input style="width: calc(100% - 120px);" [autofocus]="true" *ngIf="activeInput === 'name'" [inputText]="editedCustomer.name" (textChange)="editedCustomer.name = $event" (focusout)="activeInput = '';((editedCustomer.name !== customer.name) ? updateCustomer() : '')"></text-input>
            </div>
            <div class="property">
              <span class="field-key"><mat-icon svgIcon="calls"></mat-icon> Phone</span>
              <span [ngStyle]="{'color': (editedCustomer?.mobile && editedCustomer?.mobile !== '') ? 'var(--primary-color)' : 'var(--secondary-color)'}" *ngIf="activeInput !== 'mobile'" (click)="activeInput = 'mobile'" class="field-value">{{editedCustomer?.mobile ? (editedCustomer?.mobile | numberFormat) : '12512500508'}}</span>
              <text-input style="width: calc(100% - 120px);" [autofocus]="true" *ngIf="activeInput === 'mobile'" [inputText]="editedCustomer.mobile" (textChange)="editedCustomer.mobile = $event" (focusout)="activeInput = '';editedCustomer.mobile !== customer.mobile ? updateCustomer() : ''"></text-input>
            </div>
            <div class="property">
              <span class="field-key"><mat-icon svgIcon="email"></mat-icon> Email</span>
              <span [ngStyle]="{'color': (editedCustomer?.email && editedCustomer?.email !== '') ? 'var(--primary-color)' : 'var(--secondary-color)'}" *ngIf="activeInput !== 'email'" (click)="activeInput = 'email'" class="field-value">{{editedCustomer?.email ?? 'john@example.com'}}</span>
              <text-input style="width: calc(100% - 120px);" [autofocus]="true" *ngIf="activeInput === 'email'" [inputText]="editedCustomer.email" (textChange)="editedCustomer.email = $event" (focusout)="activeInput = '';editedCustomer.email !== customer.email ? updateCustomer() : ''"></text-input>
            </div>
            <ng-container>
              <ng-container *ngFor="let property of editedCustomer.custom_property; let i=index">
                <div class="property" *ngIf="property?.field">
                  <custom-field-edit-delete
                    *ngIf="isEditCustomField && editingCustomField?.field === property?.field"
                    [type]="editingCustomField.datatype"
                    [name]="editingCustomField?.label ?? ''"
                    [loader]="loaderAddCustomField"
                    (save)="saveEditCustomField($event)"
                    (delete)="deleteCustomFieldConfirm()"
                    (close)="isEditCustomField = false"
                  ></custom-field-edit-delete>
                  <span class="field-key" [matTooltip]="property?.label ?? ''" style="cursor: pointer;" (click)="editCustomField(property)">
                    <icon *ngIf="property?.datatype === 'text'" [iconColor]="'var(--secondary-color)'" [icon]="'text'" [size]="'16px'"></icon>
                    <icon *ngIf="property?.datatype === 'int'" [iconColor]="'var(--secondary-color)'" [icon]="'numbers'" [size]="'16px'"></icon>
                    <icon *ngIf="property?.datatype === 'address'" [iconColor]="'var(--secondary-color)'" [icon]="'location_on'" [size]="'16px'"></icon>
                    <icon *ngIf="property?.datatype === 'date'" [iconColor]="'var(--secondary-color)'" [icon]="'calendar'" [size]="'16px'"></icon>
                    <icon *ngIf="property?.datatype === 'url'" [iconColor]="'var(--secondary-color)'" [icon]="'link'" [size]="'16px'"></icon>
                    <icon *ngIf="property?.datatype === 'bool'" [iconColor]="'var(--secondary-color)'" [icon]="'check_box'" [size]="'16px'"></icon>
                    <span>{{property?.label}}</span>
                  </span>
                  <ng-container *ngIf="property?.datatype === 'bool'">
                    <span><mat-checkbox [checked]="property?.bool_value" (change)="property.bool_value = !property?.bool_value;updateCustomer()"></mat-checkbox></span>
                  </ng-container>
                  <ng-container *ngIf="property?.datatype === 'date'">
                    <span 
                      *ngIf="activeInput !== 'date'" 
                      (click)="activeInput = 'date'" 
                      class="field-value"
                      [ngStyle]="{'color': property?.date_value ? 'var(--primary-color)' : 'var(--secondary-color)'}" 
                    >{{property?.date_value ? (property?.date_value | ddmmyyyy) : 'Set '+property?.label}}</span>
                    <date-picker 
                      [date]="property?.date_value ?? null" 
                      (dateChange)="updateDateProperty($event, property)"
                      style="width: calc(100% - 120px);"
                      *ngIf="activeInput === 'date'"
                    ></date-picker>
                  </ng-container>
                  <ng-container *ngIf="property?.datatype !== 'bool' && property?.datatype !== 'date'">
                    <span *ngIf="activeInput !== property?.field" (click)="activeInput = property?.field ?? ''" class="field-value">
                      <span [ngStyle]="{'color': property?.text_value ? 'var(--primary-color)' : 'var(--secondary-color)'}" *ngIf="property?.datatype === 'text'">{{(property?.text_value && property?.text_value !== '' ) ? property?.text_value : 'Set '+property?.label}}</span>
                      <span [ngStyle]="{'color': property?.address_value ? 'var(--primary-color)' : 'var(--secondary-color)'}" *ngIf="property?.datatype === 'address'">{{(property?.address_value && property?.address_value !== '' ) ? property?.address_value : 'Set '+property?.label}}</span>
                      <span [ngStyle]="{'color': property?.url_value ? 'var(--primary-color)' : 'var(--secondary-color)'}" *ngIf="property?.datatype === 'url'">{{(property?.url_value && property?.url_value !== '' ) ? property?.url_value : 'e.g. https://yoururl.com'}}</span>
                      <span [ngStyle]="{'color': property?.int_value ? 'var(--primary-color)' : 'var(--secondary-color)'}" *ngIf="property?.datatype === 'int'">{{(property?.int_value) ?? 'Set '+property?.label}}</span>
                    </span>
                    <text-input style="width: calc(100% - 120px);" [autofocus]="true" *ngIf="activeInput === property?.field && property?.datatype === 'text'" [inputText]="property?.text_value ?? ''" (textChange)="property.text_value = $event" (focusout)="activeInput = '';((property?.text_value && property?.text_value !== customer!.custom_property[i]?.text_value) ? updateCustomer() : '')"></text-input>
                    <text-input style="width: calc(100% - 120px);" [autofocus]="true" *ngIf="activeInput === property?.field && property?.datatype === 'address'" [inputText]="property?.address_value ?? ''" (textChange)="property.address_value = $event" (focusout)="activeInput = '';((property?.address_value && property?.address_value !== customer!.custom_property[i]?.address_value) ? updateCustomer() : '')"></text-input>
                    <text-input style="width: calc(100% - 120px);" [autofocus]="true" *ngIf="activeInput === property?.field && property?.datatype === 'url'" [inputText]="property?.url_value ?? ''" (textChange)="property.url_value = $event" (focusout)="activeInput = '';((property?.url_value && property?.url_value !== customer!.custom_property[i]?.url_value)  ? updateCustomer() : '')"></text-input>
                    <number-input style="width: calc(100% - 120px);" [autofocus]="true" *ngIf="activeInput === property?.field && property?.datatype === 'int'" [inputNumber]="property?.int_value ?? 0" (numberChange)="property.int_value = $event" (focusout)="activeInput = '';((property?.int_value && property?.int_value !== customer!.custom_property[i]?.int_value)  ? updateCustomer() : '')"></number-input>
                  </ng-container>
                </div>
              </ng-container>
              <div class="add-property">
                <custom-field-edit-delete
                  *ngIf="isAddCustomField"
                  [type]="addCustomFieldDetails.type"
                  (save)="addCustomField($event)"
                  [loader]="loaderAddCustomField"
                  (delete)="closeAddCustomField()"
                  (close)="isAddCustomField = false;"
                ></custom-field-edit-delete>
                <div class="dropdown">
                  <button 
                    class="btn variable-dropdown dropdown-toggle"
                    type="button"
                    id="variableDropdownMenu"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <mat-icon svgIcon="add"></mat-icon> <span>Add a property</span>
                  </button>
                  <ul
                    class="dropdown-menu variable-dropdown-menu"
                    aria-labelledby="variableDropdownMenu"
                  >
                    <li 
                      (click)="selectType('Text')" 
                      class="variable-item"
                    >
                      <icon [icon]="'text'" [size]="'20px'"></icon> Text
                    </li>
                    <li 
                      (click)="selectType('Number')" 
                      class="variable-item"
                    >
                      <icon [icon]="'numbers'" [size]="'20px'"></icon> Number
                    </li>
                    <li 
                      (click)="selectType('Address')" 
                      class="variable-item"
                    >
                      <icon [icon]="'location_on'" [size]="'20px'"></icon> Address
                    </li>
                    <li 
                      (click)="selectType('Date')" 
                      class="variable-item"
                    >
                      <icon [icon]="'calendar'" [size]="'20px'"></icon> Date
                    </li>
                    <li 
                      (click)="selectType('URL')" 
                      class="variable-item"
                    >
                      <icon [icon]="'link'" [size]="'20px'"></icon> URL
                    </li>
                    <li 
                      (click)="selectType('Checkbox')" 
                      class="variable-item"
                    >
                      <icon [icon]="'check_box'" [size]="'20px'"></icon> Checkbox
                    </li>
                  </ul>
                </div>
              </div>
            </ng-container>
            <div class="footer-actions" *ngIf="!customer?.id">
              <save-button [saveButtonText]="'Add contact'" (saveClicked)="addContact()" [loading]="isLoading" [disableSave]="editedCustomer?.mobile === '' || editedCustomer?.name === ''"></save-button>
            </div>
          </div>
          <div class="ver-8px" *ngIf="customer?.id">
            <div class="note-heading">
              <span>Note</span> <span class="count">{{notesList?.length}}</span>
            </div>
            <div class="note-item" *ngFor="let note of notesList; let i=index">
              <div class="note-added-edit-action">
                <span class="note-added-by">
                  <name-tag [name]="note?.added_by?.name ?? ''"></name-tag>
                  <span class="col-sec">{{(note?.updated_at ?? note?.created_at) | ddmmyyyy}}</span>
                </span>
                <variable-dropdown *ngIf="note?.id !== editingNoteId && note?.added_by?.id === user?.id" [values]="['Edit','Delete']" (selected)="noteAction($event,note,i)"></variable-dropdown>
              </div>
              <edit-note [contact]="true" (save)="updateNote($event, note, i)" [isEdit]="note?.id === editingNoteId" [note]="note?.note ?? ''" (close)="editingNoteId = 0" (delete)="deleteNote(note,i)"></edit-note>
            </div>
            <div class="add-note-item">
              <message-input [message]="newNote" [rows]="3" (textChange)="newNote = $event" (sendClicked)="addNote()"></message-input>
              <!-- <textarea-input [inputText]="newNote" (textChange)="newNote = $event" (enterClicked)="addNote()" (focusout)="addNote()"></textarea-input> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </hlm-sheet-content>
</hlm-sheet>


<confirmation-popup
  *ngIf="deleteConfirmation"
  [positiveButtonText]="deletePositiveButton"
  [negativeButtonText]="deleteNegativeButton"
  [heading]="deleteHeading"
  [description]="deleteDescription"
  (close)="deleteConfirmation = false"
  (onSuccess)="deleteType === 'field' ? deleteCustomField() : deleteContact()"
></confirmation-popup>