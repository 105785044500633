<div class="note-container">
  <span class="note-icon" [ngClass]="type+'-icon'">
    <mat-icon *ngIf="type === 'error'" svgIcon="rejected_icon"></mat-icon>
    <mat-icon *ngIf="type === 'refund'" svgIcon="cycle"></mat-icon>
    <mat-icon *ngIf="type === 'warning'" svgIcon="analytics_flaged_yellow"></mat-icon>
    <mat-icon *ngIf="type === 'info'" svgIcon="info_icon_blue"></mat-icon>
    <mat-icon *ngIf="type === 'success'" svgIcon="check_circle"></mat-icon>
  </span>
  <div class="note-body">
    <p class="note-heading" [ngClass]="type+'-heading'">{{heading}}</p>
    <p class="note-subheading">{{description}}</p>
  </div>
  <save-button *ngIf="button !== ''" [disableSave]="disableSave"  [saveButtonText]="button" (saveClicked)="buttonClicked.emit(true)"></save-button>
</div>