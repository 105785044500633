<div class="date-picker-container">
    <div class="dropdown w-100">
        <button class="btn btn-custom-dropdown dropdown-toggle variable-dropdown" type="button"
          id="variableDropdownMenu" data-bs-toggle="dropdown" aria-expanded="false" [ngClass]="date ? 'col-pri' : 'col-sec'">
          <icon [icon]="'calendar'" [size]="'20px'"></icon> {{date ? (date | ddmmyyyy) : 'Select date'}}
        </button>
        <ul class="dropdown-menu custom-dropdown-menu variable-dropdown-menu"
          aria-labelledby="headerDropdownMenu" (click)="$event.stopPropagation()">
          <mat-calendar [selected]="date" (selectedChange)="onDateChange($event)"></mat-calendar>
        </ul>
    </div>
</div>