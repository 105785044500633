import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class APIInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let newUrl = req.url;
    if (!req.url.includes('http') && !req.url.includes('assets')) {
      newUrl = `${environment.https ? 'https' : 'http'}://${environment.API_URL}${req.url}`;
    }
    let  apiReq = req.clone({ url: newUrl });
    apiReq = apiReq.clone({
      setHeaders: {
        'ngrok-skip-browser-warning' : environment.production ? 'false' : 'true'
      },
    });
    return next.handle(apiReq);
  }
}
