import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'tertiary-button',
  templateUrl: './tertiary-button.component.html',
  styleUrls: ['./tertiary-button.component.css']
})
export class TertiaryButtonComponent implements OnInit {

  @Input() buttonText!: string;
  @Input() isDisabled: boolean = false;
  @Input() isHidden: boolean = false;
  @Output() buttonClicked = new EventEmitter<boolean>();
  constructor() { }

  ngOnInit(): void {
  }

}
