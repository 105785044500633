import { Pipe, PipeTransform } from '@angular/core';
import { NumberFormatPipe } from './number-format.pipe';

@Pipe({
  name: 'name',
})
export class NamePipe implements PipeTransform {

  numberFormatPipe = new NumberFormatPipe();

  transform(value: any): string {
    if(value == undefined || value == null) return '';
    if(value?.name) return value.name;
    else if(value?.first_name) return value.first_name;
    else if(value?.whatsapp_name) return value.whatsapp_name;
    else if(value?.mobile) {
      return this.numberFormatPipe.transform(value.mobile);
    }
    else return '';
  }
}
