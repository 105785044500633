import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { CloudNumber } from 'src/app/models/cloud-number';
import { Widget } from 'src/app/models/widget';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { VoiceService } from 'src/app/services/voice.service';
import { WidgetService } from 'src/app/services/widget.service';

@Component({
  selector: 'inbox-dropdown',
  templateUrl: './inbox-dropdown.component.html',
  styleUrls: ['./inbox-dropdown.component.css']
})
export class InboxDropdownComponent implements OnInit {

  currentCloudNumber!: CloudNumber;
  currentWidget!: Widget;
  @Output() number = new EventEmitter<CloudNumber>();
  @Output() all = new EventEmitter<Boolean>();
  @Output() widget = new EventEmitter<Widget>();
  @Input() disabled: boolean = false;
  @Input() selectedNumberId!: number;
  widgetList: Widget[] = [];
  selectedFilter: string = 'all';

  constructor(
    public sharedDataService: SharedDataService,
    private _voiceService: VoiceService,
    private _widgetService: WidgetService,
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
  ) {
    this.matIconRegistry.addSvgIcon(
      'keyboard_arrow_down',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/keyboard_arrow_down.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'calls',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/calls.svg'
      )
    );
  }
  ngOnInit(): void {
    this.all.emit();
    let cloudNumbers = JSON.parse(localStorage.getItem('cloud-numbers')!);
    console.log(cloudNumbers)
    if(cloudNumbers) {
      this.sharedDataService.cloudNumbers = [];
      cloudNumbers?.forEach((element : any) => {
        this.sharedDataService.cloudNumbers.push(new CloudNumber(element));
      });
      // if(this.selectedNumberId) {
      //   for(var index=0;index < this.sharedDataService.cloudNumbers?.length;index++) {
      //     if(this.sharedDataService.cloudNumbers[index].id === this.selectedNumberId) this.numberChanged(this.sharedDataService.cloudNumbers[index]);
      //   }
      // } else this.numberChanged(this.sharedDataService.cloudNumbers[0]);
    } else {
      this._voiceService.getInboxes().subscribe({
        next: resp => {
          if (resp.body?.code == 200) {
            let responseList = resp.body.data;
            this.sharedDataService.setCloudNumbers(responseList);
            // if(this.selectedNumberId) {
            //   for(var index=0;index < this.sharedDataService.cloudNumbers?.length;index++) {
            //     if(this.sharedDataService.cloudNumbers[index].id === this.selectedNumberId) this.numberChanged(this.sharedDataService.cloudNumbers[index]);
            //   }
            // } else this.numberChanged(this.sharedDataService.cloudNumbers[0]);
          }
        }, error: error => {
          console.log(error)
        }
      })
    }
    this._widgetService.getWidgets().subscribe({
      next: resp => {
        this.widgetList = [];
        let responseList = resp.body.data;
        responseList?.forEach((element: any) => {
          this.widgetList.push(new Widget(element));
        })
        if(!this.currentCloudNumber?.id && this.widgetList?.length > 0) this.currentWidget = this.widgetList[0];
      }
    })
  }

  numberChanged(cloudNumber: CloudNumber) {
    this.selectedFilter = 'number';
    this.currentCloudNumber = cloudNumber;
    this.currentWidget = new Widget(null);
    this.number.emit(this.currentCloudNumber);
  }

  widgetChanged(widget: Widget) {
    this.selectedFilter = 'widget';
    this.currentWidget = widget;
    this.currentCloudNumber = new CloudNumber(null);
    this.widget.emit(this.currentWidget);
  }

  allSelected() {
    this.selectedFilter = 'all';
    this.currentWidget = new Widget(null);
    this.currentCloudNumber = new CloudNumber(null);
    this.all.emit(true);
  }
}
