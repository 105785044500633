import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timer'
})
export class TimerPipe implements PipeTransform {

  transform(duration: number | undefined): string {
    if(duration == undefined || duration == 0) return '00:00';
    else {
      let hours = Math.floor(duration/3600);
      let minutes = Math.floor((duration % 3600)/60);
      let seconds = Math.floor(duration % 60);
      if(hours === 0) return this.addZero(minutes)+':'+this.addZero(seconds);
      else return this.addZero(hours)+':'+this.addZero(minutes)+':'+this.addZero(seconds);
    }
  }

  addZero(num: number): string {
    if(num < 10) return '0'+ num;
    else return num.toString();
  }
}
