import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: 'number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.css']
})
export class NumberInputComponent implements OnInit, AfterViewInit{

  @ViewChild('numberInputField') numberInputField!: ElementRef;
  @Input() inputNumber!: number;
  @Input() maxLimit!: number;
  @Input() fieldName!: string;
  @Input() disable: boolean = false;
  @Input() placeholder: string = 'Enter number';
  @Input() dollar: boolean = false;
  @Input() autofocus: boolean = false;
  @Input() upDownArrow: boolean = false;
  @Output() numberChange = new EventEmitter<number>();
  @Output() enterClicked = new EventEmitter<number>();
  @Output() focusout = new EventEmitter<boolean>();

  constructor(private renderer: Renderer2) {}
  
  ngOnInit(): void {
    this.restrictLength();
  }

  ngAfterViewInit(): void {
    if(this.autofocus) this.renderer.selectRootElement(this.numberInputField.nativeElement).focus();
  }

  checkLength(event: any){
    if(this.maxLimit){
      if(this.inputNumber < this.maxLimit) return true;
      else return false;
    } else return true;
  }
  restrictLength(){
    // this.inp = this.inputText?.substring(0,this.maxChars);
    if(this.maxLimit && this.inputNumber > this.maxLimit) this.inputNumber = this.maxLimit;
    this.numberChange.emit(this.inputNumber)
  }

  increase() {
    this.inputNumber++;
    this.restrictLength();
  }

  decrease() {
    if(this.inputNumber < 1) this.inputNumber = 0;
    else {
      this.inputNumber--;
    }
    this.restrictLength();
  }
}
