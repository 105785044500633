import { Component, Input, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'icon',
  templateUrl: './icon.component.html',
  styleUrl: './icon.component.css'
})
export class IconComponent implements OnInit {

  @Input() icon!: string;
  @Input() iconColor: string = 'var(--primary-color)';
  @Input() size: string = '20px';

  constructor(
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
  ) {   
  }

  ngOnInit(): void {
    this.matIconRegistry.addSvgIcon(
      this.icon,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/'+this.icon+'.svg'
      )
    );  
  }
}
