import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'custom-field-edit-delete',
  templateUrl: './custom-field-edit-delete.component.html',
  styleUrl: './custom-field-edit-delete.component.css'
})
export class CustomFieldEditDeleteComponent implements OnInit {

  @Input() isAdd: boolean = true;
  @Input() type: string = '';
  @Input() name: string = '';
  @Input() loader: boolean = false;
  @Output() save = new EventEmitter<string>();
  @Output() delete = new EventEmitter<boolean>();
  @Output() close = new EventEmitter<boolean>();
  placeholder: string = '';

  ngOnInit(): void {
    this.placeholder = this.type.toLowerCase();
    if(this.placeholder === 'int') this.placeholder = 'Enter number';
    else if(this.placeholder === 'bool') this.placeholder = 'Enter checkbox';
    else this.placeholder = 'Enter '+ this.placeholder;
  }

  addCustomField() {
    console.log(this.name);
    this.save.emit(this.name.trim());
  }

  closeAddCustomField() {
    this.delete.emit(true);
  }

  clickedOutside() {
    this.close.emit(true);
  }
}
