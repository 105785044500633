import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'bar-information',
  templateUrl: './bar-information.component.html',
  styleUrls: ['./bar-information.component.css']
})
export class BarInformationComponent implements OnInit {

  constructor(
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
  ) {
    this.matIconRegistry.addSvgIcon(
      'info_icon_blue',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/info_icon_blue.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'rejected_icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/rejected_icon.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'analytics_flaged_yellow',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/analytics_flaged_yellow.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'check_circle',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/check_circle.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'cycle',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/cycle.svg'
      )
    );
   }

  @Output() buttonClicked = new EventEmitter<boolean>();
  @Input() type!: string;
  @Input() heading!: string;
  @Input() description!: string;
  @Input() button: string = '';
  @Input() disableSave: boolean = false;

  ngOnInit(): void {
  }

}
