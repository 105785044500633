import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Address, Customer } from 'src/app/models/customer';
import { ToasterService } from 'src/app/services/toastr.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'edit-address',
  templateUrl: './edit-address.component.html',
  styleUrls: ['./edit-address.component.css']
})
export class EditAddressComponent implements OnInit {

  @Input() customer!: Customer;
  @Input() addressIndex: number = -1;
  @Output() cancelEditEvent = new EventEmitter<boolean>();
  @Output() updateCustomerEvent = new EventEmitter<Customer>();
  line_1!: string;
  line_2: string = '';
  city!: string;
  state!: string;
  pincode!: string;
  isAddressEdit: boolean = false;
  loaderUpdateCustomer: boolean = false;

  constructor(
    private userService: UsersService,
    private toastr: ToasterService
  ) { }

  ngOnInit(): void {
    if(this.addressIndex !== -1) this.isAddressEdit = true;
    else this.isAddressEdit = false; 
    if(this.isAddressEdit){
      this.line_1 = this.customer.address[this.addressIndex]?.line_1;
      if(this.customer.address[this.addressIndex]?.line_2) this.line_2 = this.customer.address[this.addressIndex]?.line_2;
      this.city = this.customer.address[this.addressIndex]?.city;
      this.state = this.customer.address[this.addressIndex]?.state;
      this.pincode = this.customer.address[this.addressIndex]?.pin_code ? this.customer.address[this.addressIndex]?.pin_code.toString() : '';
    } else {
      this.line_1 = '';
      this.line_2 = '';
      this.city = '';
      this.state = '';
      this.pincode = '';
    }
  }

  checkPincode(event: any){
    if(event.keyCode > 47 && event.keyCode < 58 && this.pincode?.length < 6){
      console.log('true');
      this.pincode = this.pincode?.substring(0, 6);
      return true;
    } else {
      console.log('false');
      this.pincode = this.pincode?.substring(0, 6);
      return false;
    }
  }

  cancelEdit(){
    this.cancelEditEvent.emit();
  }

  updateCustomer(){
    let customerData = this.customer;
    let customerObject: Record<string, any> = {
      name: customerData?.name,
      email: customerData?.email,
    };
    if (customerData?.gender > 0 && customerData?.gender < 5) {
      customerObject['gender'] = customerData.gender;
    }
    if (
      customerData?.date_of_birth &&
      customerData?.date_of_birth !== '' &&
      customerData?.date_of_birth.length == 10
    ) {
      customerObject['date_of_birth'] = customerData.date_of_birth;
    }
    if(this.isAddressEdit){
      let address: Record<string,any> = {
        line_1: this.line_1,
        city: this.city,
        state: this.state,
        country: 'India',
        pin_code: Number(this.pincode),
      }
      if(this.line_2 !== '') address['line_2'] = this.line_2;
      customerData.address[this.addressIndex] = new Address(address);
    } else {
      if(!customerData.address) customerData.address = [];
      let address: Record<string,any> = {
        line_1: this.line_1,
        city: this.city,
        state: this.state,
        country: 'India',
        pin_code: Number(this.pincode),
      }
      if(this.line_2 !== '') address['line_2'] = this.line_2;
      customerData.address.push(new Address(address));
    }
    customerObject['address'] = [];
    customerData.address?.forEach((address)=>{
      let input: Record<string,any> = {
        line_1: address?.line_1,
        city: address?.city,
        state: address?.state,
        country: address?.country,
        pin_code: address?.pin_code,
      }
      if(address?.line_2) input['line_2'] = address.line_2;
      customerObject['address'].push(input);
    })
    console.log(customerObject);
    this.loaderUpdateCustomer = true;
    let response = this.userService.updateUser(customerData.id, customerObject);
    response.subscribe((resp)=>{
      if(resp.body.code >= 200 && resp.body.code < 300) {
        this.toastr.success(resp.body.message);
        this.loaderUpdateCustomer = false;
        this.updateCustomerEvent.emit(customerData);
      } else {
        this.toastr.error(resp.body.message);
        this.loaderUpdateCustomer = false;
      }
    },(error)=>{
      console.log(error);
      this.toastr.error(error);
      this.loaderUpdateCustomer = false;
    })
  }
}
