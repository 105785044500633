import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hours'
})
export class HoursPipe implements PipeTransform {

  transform(time: string | undefined): string {
    if(time == undefined) return '00';
    else {
      let value = time.split(':');
      if(value?.length > 0) {
        if(Number(value[0]) > 12) {
          value[0] = this.convertToDouble(Number(value[0]) % 12);
          return value[0]
        } 
        return value[0];
      }
      return '00'
    }
  }

  convertToDouble(value: number) {
    if(value > 9) return value.toString();
    else return '0'+ value;
  }

}
