<button 
  class="container-footer-save" 
  [disabled]="disableSave || loading" 
  [hidden]="hideSave"
  [ngClass]="loading ? 'loading' : ''"
  (click)="saveClicked.emit(true)"
  >
  <ng-container *ngIf="!loading">
    <mat-icon *ngIf="icon" [svgIcon]="icon"></mat-icon>
    <span>{{saveButtonText}}</span>
  </ng-container>
  <mat-progress-spinner
    *ngIf="loading"
    mode="indeterminate"
    diameter="22"
    class="white"
  ></mat-progress-spinner>
</button>