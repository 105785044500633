import { Pipe, PipeTransform } from '@angular/core';
import { GlobalStrings } from '../constants';

@Pipe({
  name: 'boldItalic',
})
export class BoldItalicPipe implements PipeTransform {
  transform(value: string | undefined): string {
    if(value == undefined) return '';
    const regex = new RegExp(GlobalStrings.boldRegex, 'gi');
    value = value?.replace(
      regex,
      (match) => `<b>${match.replace(/\*/g, '')}</b>`
    );
    //value = value.replace(/\n/g, '<br/>');
    const italicRegex = new RegExp(GlobalStrings.italicRegex, 'gi');
    value = value?.replace(
      italicRegex,
      (match) => `<i>${match.replace(/\_/g, '')}</i>`
    );
    const urlRegex = new RegExp(GlobalStrings.newUrlRegex, 'gi');
    let matchedUrls = value?.match(urlRegex);
    if(matchedUrls) {
      matchedUrls?.forEach((url)=>{
        value = value?.replace(url,'<a href="'+url+'" target="_blank">'+url+'</a>');
      });
    }
    const strikeRegex = new RegExp(GlobalStrings.strikeRegex, 'gi');
    return value?.replace(
      strikeRegex,
      (match) => `<del>${match.replace(/\~/g, '')}</del>`
    );
  }
}
