import { Component, Input } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'document-preview',
  templateUrl: './document-preview.component.html',
  styleUrls: ['./document-preview.component.css'],
})

export class DocumentPreviewComponent {

  constructor(
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
  ) {
    this.matIconRegistry.addSvgIcon(
      'excel_icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/excel_icon.svg'
      )
    );
  }
  @Input() docType!: string | undefined;
}