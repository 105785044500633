import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'afterSlash'
})
export class AfterSlashPipe implements PipeTransform {

  transform(value : string | undefined): string {
    if(value == undefined) return '';
    let type = ''+value.split('/').pop();
    return type;
  }

}
