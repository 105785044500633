import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AgencyService } from 'src/app/services/agency.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css']
})
export class PageNotFoundComponent implements OnInit {

  constructor(private router: Router,public agencyService: AgencyService,) { }

  ngOnInit(): void {
  }

  routeToChats(){
    this.router.navigate(['/agents']);
  }

}
