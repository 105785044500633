import { HttpClient } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'map',
})
export class MapPipe implements PipeTransform {

  isMapLoaded: boolean = false;
  constructor(private http: HttpClient) {}
  async transform(url: string) {
    await this.http.get(url, {
      responseType: 'blob',
    }).subscribe((resp)=>{
      console.log(URL.createObjectURL(resp).toString().substring(5));
      return URL.createObjectURL(resp).toString().substring(5);
    });
  }
}
