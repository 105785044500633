import { AfterViewChecked, AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild, ViewEncapsulation } from "@angular/core";

@Component({
  selector: 'text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class TextInputComponent implements OnInit, AfterViewInit {

  @ViewChild('textInputField') textInputField!: ElementRef;
  @Input() inputText!: string;
  @Input() maxChars!: number;
  @Input() fieldName!: string;
  @Input() disable: boolean = false;
  @Input() placeholder: string = 'Enter text';
  @Input() autofocus: boolean = false;
  @Output() textChange = new EventEmitter<string>();
  @Output() enterClicked = new EventEmitter<string>();
  @Output() focusout = new EventEmitter<boolean>();


  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {
    this.restrictLength();
  }

  ngAfterViewInit(): void {
    if(this.autofocus) this.renderer.selectRootElement(this.textInputField.nativeElement).focus();
  }

  checkLength(event: any){
    if(this.maxChars){
      if(this.inputText?.length < this.maxChars) return true;
      else return false;
    } else return true;
  }
  restrictLength(){
    this.inputText = this.inputText?.substring(0,this.maxChars);
    this.textChange.emit(this.inputText)
  }
}
