<div class="input-container">
  <input type="text" 
      matInput
      #textInputField
      id="textInputComponent"
      [ngStyle]="{'margin-right': maxChars ? '50px' : '0'}"
      [(ngModel)]="inputText" 
      (keypress)="checkLength($event)" 
      (keyup)="restrictLength()"
      [placeholder]="placeholder" 
      [disabled]="disable"
      (focusout)="focusout.emit(true)"
      (keyup.enter)="enterClicked.emit(inputText)"
      (ngModelChange)="textChange.emit(inputText)">
    <!-- <span class="text-count" *ngIf="maxChars">{{inputText?.length}}/{{maxChars}}</span> -->
    <div class="outer-border-container">
    </div>
</div>

<!-- <label hlmLabel>
  {{fieldName}}
  <input type="text" 
    hlmInput
    class="text-input"
    [(ngModel)]="inputText" 
    (keypress)="checkLength($event)" 
    (keyup)="restrictLength()"
    [placeholder]="placeholder" 
    [disabled]="disable"
    (focusout)="focusout.emit(true)"
    (keyup.enter)="enterClicked.emit(inputText)"
    (ngModelChange)="textChange.emit(inputText)">
</label> -->