import { Component, Input, Output, EventEmitter } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: 'cancel-button',
  templateUrl: './cancel-button.component.html',
  styleUrls: ['./cancel-button.component.css']
})

export class CancelButtonComponent {

  @Input() cancelButtonText!: string;
  @Input() hideCancel: boolean = false;
  @Input() disableCancel: boolean = false;
  @Output() cancelClicked = new EventEmitter<boolean>();
  @Input() icon!: string
  @Input() align!: string
  @Input() loading: boolean = false;

  constructor(
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
  ) {
    
  }

  ngOnInit(): void {
    if(this.icon) {
      this.matIconRegistry.addSvgIcon(
        this.icon,
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          '../assets/svg/'+this.icon+'.svg'
        )
      );
    }
  }
}