import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { GlobalStrings } from 'src/app/constants';
import { Customer } from 'src/app/models/customer';
import { ToasterService } from 'src/app/services/toastr.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'edit-customer',
  templateUrl: './edit-customer.component.html',
  styleUrls: ['./edit-customer.component.css']
})
export class EditCustomerComponent implements OnInit {

  @Input() customer!: Customer;
  @Output() cancelEditEvent = new EventEmitter<boolean>();
  @Output() updateCustomerEvent = new EventEmitter<Customer>();
  today = new Date();
  customerDOB!: Date;
  dateString: string = '';
  customerName: string = '';
  customerEmail: string = '';
  customerGender: number = 0;
  customerBio: string = '';
  fbUrl: string = '';
  twitterUrl: string = '';
  linkedinUrl: string = '';
  loaderSaveCustomer: boolean = false;
  customFields: any = {};
  customFieldKeys: any[] = [];

  constructor(
    private userService: UsersService,
    private toastr: ToasterService,
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
  ) {
    this.matIconRegistry.addSvgIcon(
      'facebook',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/facebook.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'twitter',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/twitter.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'linkedin',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/linkedin.svg'
      )
    );
  }

  ngOnInit(): void {
    console.log(2);
    console.log(this.customer);
    if(this.customer?.name) this.customerName = this.customer?.name;
    if(this.customer?.email) this.customerEmail = this.customer.email;
    if(this.customer?.bio) this.customerBio = this.customer.bio;
    else this.customerEmail = '';
    if(this.customer?.date_of_birth) {
      this.dateString = this.customer.date_of_birth;
      this.customerDOB = new Date(this.dateString);
    }
    else {
      this.dateString = '';
    }
    if(this.customer?.gender) this.customerGender = this.customer.gender;
    if(this.customer?.fb_profile) this.fbUrl = this.customer.fb_profile;
    if(this.customer?.linkedin_profile) this.linkedinUrl = this.customer.linkedin_profile;
    if(this.customer?.twitter_profile) this.twitterUrl = this.customer.twitter_profile;
    if(this.customer?.custom_field) {
      //add keys and values in array
      this.customFields = Object.assign({},this.customer?.custom_field);
      this.customFieldKeys = Object.keys(this.customFields);
    }
  }

  getGenderValue(id: number) {
    if (id === 1) return 'Male';
    else if (id === 2) return 'Female';
    else if (id === 3) return 'Others';
    else if (id === 4) return 'Prefer not to say';
    else return '';
  }

  cancelEdit(){
    this.cancelEditEvent.emit();
  }
  updateCustomer(){
    let input: Record<string, any> = {
      name: this.customerName,
      email: this.customerEmail == '' ? null : this.customerEmail,
    };
    if (this.customerGender > 0 && this.customerGender < 5) {
      input['gender'] = this.customerGender;
    }
    if (this.dateString != null && this.dateString !== '') {
      input['date_of_birth'] = this.dateString;
    }
    if(this.customerBio !== '') input['bio'] = this.customerBio;
    input['address'] = [];
    if (this.customer?.address?.length > 0) {
      this.customer?.address?.forEach((address)=>{
        let addressObject: Record<string,any> = {
          line_1: address?.line_1,
          city: address?.city,
          state: address?.state,
          country: address?.country,
          pin_code: address?.pin_code,
        }
        if(address?.line_2) addressObject['line_2'] = address.line_2;
        input['address'].push(addressObject);
      });
    }
    let regex1 = new RegExp(GlobalStrings.websiteRegex);
    input['fb_profile'] = null;
    input['twitter_profile'] = null;
    input['linkedin_profile'] = null;
    if(this.fbUrl !== ''){
      if(this.fbUrl?.substring(0,7) === 'http://' || this.fbUrl?.substring(0,8) === 'https://'){
        if(regex1.test(this.fbUrl)){
          input['fb_profile'] = this.fbUrl;
        } else {
          this.toastr.error("Enter valid facebook url");
          return ;
        }
      } else {
        if(regex1.test('https://'+this.fbUrl)){
          input['fb_profile'] = 'https://'+this.fbUrl;
        } else {
          this.toastr.error("Enter valid facebook url");
          return ;
        }
      }
    }
    let regex2 = new RegExp(GlobalStrings.websiteRegex);
    if(this.twitterUrl !== ''){
      if(this.twitterUrl?.substring(0,7) === 'http://' || this.twitterUrl?.substring(0,8) === 'https://'){
        if(regex2.test(this.twitterUrl)){
          input['twitter_profile'] = this.twitterUrl;
        } else {
          this.toastr.error("Enter valid twitter url");
          return ;
        }
      } else {
        this.twitterUrl = 'https://'+this.twitterUrl;
        console.log(this.twitterUrl);
        if(regex2.test(this.twitterUrl)){
          input['twitter_profile'] = this.twitterUrl;
        } else {
          this.toastr.error("Enter valid twitter url");
          return ;
        }
      }
    }
    let regex3 = new RegExp(GlobalStrings.websiteRegex);
    if(this.linkedinUrl !== ''){
      if(this.linkedinUrl?.substring(0,7) === 'http://' || this.linkedinUrl?.substring(0,8) === 'https://'){
        if(regex3.test(this.linkedinUrl)){
          input['linkedin_profile'] = this.linkedinUrl;
        } else {
          this.toastr.error("Enter valid linkedin url");
          return ;
        }
      } else {
        if(regex3.test('https://'+this.linkedinUrl)){
          input['linkedin_profile'] = 'https://'+this.linkedinUrl;
        } else {
          this.toastr.error("Enter valid linkedin url");
          return ;
        }
      }
    }
    if(this.customFieldKeys?.length > 0){
      input['custom_field'] = this.customFields;
    }
    this.loaderSaveCustomer = true;
    let response = this.userService.updateUser(this.customer.id, input);
    response.subscribe((resp)=>{
      if (resp.body.code == 200) {
        this.toastr.success(resp.body.message);
        this.loaderSaveCustomer = false;
        this.updateCustomerEvent.emit(new Customer(resp.body.data));
      } else if (resp.body.code == 400) {
        this.toastr.error(resp.body.message);
        this.loaderSaveCustomer = false;
      }
    },
    (error) => {
      this.toastr.error(error);
      console.log(error);
      this.loaderSaveCustomer = false;
    });
   }

  dateChange(event: Date) {
    this.customerDOB = event;
    const day = String(event.getDate()).padStart(2, '0');
    const month = String(event.getMonth() + 1).padStart(2, '0');
    const year = event.getFullYear();
    this.dateString = `${year}-${month}-${day}`;
    console.log(this.dateString);
  }
}
