import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'variable-dropdown',
  templateUrl: './variable-dropdown.component.html',
  styleUrls: ['./variable-dropdown.component.css']
})
export class VariableDropdownComponent implements OnInit {

  @Output() selected = new EventEmitter<string>();
  @Input() values: string[] = [];

  constructor() { }

  ngOnInit(): void {
  }

}
