import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitText'
})
export class SplitTextPipe implements PipeTransform {

  transform(value: string, chunkSize: number = 160): string[] {
    const result: string[] = [];

    if (!value) return result;

    // Split the text into chunks of 160 characters
    for (let i = 0; i < value.length; i += chunkSize) {
      let newString = value.substring(i, i + chunkSize)
      newString.replaceAll('\n','<br>');
      result.push(newString);
    }

    return result;
  }
}
