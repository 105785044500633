import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minutes'
})
export class MinutesPipe implements PipeTransform {

  transform(time: string | undefined): string {
    if(time == undefined) return '00';
    else {
      let value = time.split(':');
      if(value?.length > 1) return value[1];
      return '00'
    }
  }

}
