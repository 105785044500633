<div class="main-container">
  <div class="expiry-header">
    <span></span>
    <button hlmBtn variant='ghost' (click)="logout()">Logout</button>
  </div>
  <div class="expiry-card">
    <img src="../../../../assets/images/agency-plan-expiry.png" alt="">
    <div class="card-fields">
      <span class="expiry-heading">Access restricted!</span>
      <span class="expiry-description">To continue using {{agencyService.brand}}, contact to customer support</span>
    </div>
    <div class="renew-option">
      <save-button [saveButtonText]="'Contact Us'" *ngIf="agencyService.supportEmail" (saveClicked)="mailTo()"></save-button>
    </div>
  </div>

</div>