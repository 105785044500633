export class Label {
  private _id: number;
  private _name: string;
  private _color: string;
  private _description: string;
  private _isChecked: boolean;
  private _label_customers_count: number;
  private _label_audienceset_count: number;
  private _bg_color: string;

    public get bg_color(): string {
        return this._bg_color;
    }

    public set bg_color(bg_color: string) {
        this._bg_color = bg_color;
    }


  public get label_customers_count(): number {
    return this._label_customers_count;
  }

  public set label_customers_count(label_customers_count: number) {
    this._label_customers_count = label_customers_count;
  }

  public get label_audienceset_count(): number {
    return this._label_audienceset_count;
  }

  public set label_audienceset_count(label_audienceset_count: number) {
    this._label_audienceset_count = label_audienceset_count;
  }

  public get isChecked(): boolean {
      return this._isChecked;
  }
  
  public set isChecked(isChecked: boolean) {
      this._isChecked = isChecked;
  }

  public get id(): number {
    return this._id;
  }

  public set id(id: number) {
    this._id = id;
  }

  public get name(): string {
    return this._name;
  }

  public set name(name: string) {
    this._name = name;
  }

  public get color(): string {
    return this._color;
  }

  public set color(color: string) {
    this._color = color;
  }

  public get description(): string {
    return this._description;
  }

  public set description(description: string) {
    this._description = description;
  }

  constructor(labelJson: any) {
    this._id = labelJson?.id;
    this._name = labelJson?.name;
    this._color = labelJson?.color;
    this._description = labelJson?.description;
    this._isChecked = labelJson?.isChecked ? labelJson?.isChecked : false;
    this._label_audienceset_count = labelJson?.label_audienceset_count ? labelJson?.label_audienceset_count : 0;
    this._label_customers_count = labelJson?.label_customers_count ? labelJson?.label_customers_count : 0;
    this._bg_color = labelJson?.bg_color;
  }
}