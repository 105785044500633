<div class="number-stepper">
  <button class="decrease-number" (click)="decrease()"><icon [size]="'16px'" style="height: 16px;" [icon]="'minus'" [iconColor]="'var(--primary-color)'"></icon></button>
  <input 
    type="number" 
    [(ngModel)]="value" 
    [min]="min" 
    [max]="max"
    (keyup)="restrictLength()"
    (keypress)="checkLength($event)" 
    (input)="onInputChange($event)"
  >
  <button class="increase-number" (click)="increase()"><icon [size]="'16px'" style="height: 16px;" [icon]="'add'" [iconColor]="'var(--primary-color)'"></icon></button>
</div>