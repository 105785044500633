import { Directive, HostListener, Renderer2, ElementRef } from '@angular/core';

@Directive({
  selector: '[outlineOnClick]'
})
export class OutlineOnClickDirective {

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  // On mouse down (click), add outline and change background color
  @HostListener('mousedown') onMouseDown() {
    this.renderer.setStyle(this.el.nativeElement, 'outline', '2px solid var(--primary-color)');
    this.renderer.setStyle(this.el.nativeElement, 'outline-offset', '2px');
    this.renderer.setStyle(this.el.nativeElement, 'border-radius', '4px');
  }

  // On mouse up (release), remove outline but keep the background change
  @HostListener('mouseup') onMouseUp() {
    this.renderer.removeStyle(this.el.nativeElement, 'outline');
    this.renderer.removeStyle(this.el.nativeElement, 'outline-offset');
    this.renderer.removeStyle(this.el.nativeElement, 'border-radius');
  }

  // On mouse enter (hover), change background color
  @HostListener('mouseenter') onMouseEnter() {
    this.renderer.setStyle(this.el.nativeElement, 'background-color', '#F3F4F6');
  }

  // On mouse leave, remove outline and reset background color
  @HostListener('mouseleave') onMouseLeave() {
    this.renderer.removeStyle(this.el.nativeElement, 'outline');
    this.renderer.removeStyle(this.el.nativeElement, 'outline-offset');
    this.renderer.setStyle(this.el.nativeElement, 'background-color', 'transparent');
  }
}
