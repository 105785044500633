export class Note {
  private _id: number
  private _added_by: NoteAddedBy
  private _note: string
  private _note_type: string
  private _created_at: string
  private _updated_at: any
  private _contact: number
  private _call: number

  constructor(noteJson: any) {
    this._id = noteJson?.id;
    this._added_by = noteJson?.added_by;
    this._note = noteJson?.note;
    this._note_type = noteJson?.note_type;
    this._created_at = noteJson?.created_at;
    this._updated_at = noteJson?.updated_at;
    this._contact = noteJson?.contact;
    this._call = noteJson?.call;
  }

    public get id(): number {
        return this._id;
    }

    public set id(id: number) {
        this._id = id;
    }

    public get added_by(): NoteAddedBy {
        return this._added_by;
    }

    public set added_by(added_by: NoteAddedBy) {
        this._added_by = added_by;
    }

    public get note(): string {
        return this._note;
    }

    public set note(note: string) {
        this._note = note;
    }

    public get note_type(): string {
        return this._note_type;
    }

    public set note_type(note_type: string) {
        this._note_type = note_type;
    }

    public get created_at(): string {
        return this._created_at;
    }

    public set created_at(created_at: string) {
        this._created_at = created_at;
    }

    public get updated_at(): any {
        return this._updated_at;
    }

    public set updated_at(updated_at: any) {
        this._updated_at = updated_at;
    }

    public get contact(): number {
        return this._contact;
    }

    public set contact(contact: number) {
        this._contact = contact;
    }

    public get call(): number {
        return this._call;
    }

    public set call(call: number) {
        this._call = call;
    }

}

export class NoteAddedBy {
  private _id: number
  private _name: string

  constructor(noteAddedByJson: any) {
    this._id = noteAddedByJson?.id;
    this._name = noteAddedByJson?.name;
  }

    public get id(): number {
        return this._id;
    }

    public set id(id: number) {
        this._id = id;
    }

    public get name(): string {
        return this._name;
    }

    public set name(name: string) {
        this._name = name;
    }

}