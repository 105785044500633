import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'listTime'
})
export class ListTimePipe implements PipeTransform {

  transform(value: string | Date | undefined): boolean {
    if(value == undefined) return false;
    let msgDate = new Date(value);
    let currDate = new Date();
    let yesDate = new Date(currDate.setDate(currDate.getDate() - 1));
    if (msgDate > yesDate) return true;
    else return false;
  }

}
