import { Component } from '@angular/core';
import { AgencyService } from 'src/app/services/agency.service';

@Component({
  selector: 'downtime',
  templateUrl: './downtime.component.html',
  styleUrl: './downtime.component.css'
})
export class DowntimeComponent {

  constructor(public agencyService: AgencyService) {
    
  }
}
