import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'gender',
})
export class GenderPipe implements PipeTransform {
  transform(id: number | undefined): string {
    if(id == undefined) return '';
    if (id === 1) return 'Male';
    else if (id === 2) return 'Female';
    else if (id === 3) return 'Others';
    else if (id === 4) return 'Prefer not to say';
    else return '';
  }
}
