import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  transform(value: any[], searchTag: string): any {
    if(searchTag === '') return value;
    if(value?.length === 0){
      return value;
    }
    return value?.filter((item)=>{
      return JSON.stringify(item).toLowerCase().match(searchTag.toLowerCase());
    })
  }

}
