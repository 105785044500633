<div class="dropdown">
  <button 
    class="btn custom-dropdown dropdown-toggle"
    type="button"
    id="variableDropdownMenu"
    data-bs-toggle="dropdown"
    aria-expanded="false"
    outlineOnClick
  >
    <icon [icon]="'more_vertical'" [size]="'20px'" [iconColor]="'var(--secondary-color)'"></icon>
  </button>
  <ul
    class="dropdown-menu custom-dropdown-menu"
    aria-labelledby="variableDropdownMenu"
  >
    <li 
      *ngFor="let value of values"
      (click)="selected.emit(value)" 
      class="dropdown-item"
    >{{value}}</li>
  </ul>
</div>