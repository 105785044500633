import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'paiseToRupee',
})
export class PaiseToRupeePipe implements PipeTransform {
  transform(value: string | undefined): string {
    if(value == undefined) return '';
    value = value.replace('.','');
    return value.slice(0,value.length-2)+'.'+value.slice(value.length-2);
  }
}
