<div class="template-selection">
  <div class="audience-set-selection-container">
    <div class="audience-set-selection-heading">
      <span class="audience-set-selection-heading-title">
        {{heading}}
      </span>
      <span class="audience-set-selection-heading-icons">
        <i class="fa-solid fa-xmark" (click)="close.emit(true)"></i>
      </span>
    </div>
    <ng-content></ng-content>
    <div class="audience-set-selection-footer" *ngIf="footer">
      <cancel-button [cancelButtonText]="'Cancel'" (cancelClicked)="close.emit(true)"></cancel-button>
      <save-button [saveButtonText]="'Next'" (saveClicked)="save.emit(true)"></save-button>
    </div>
  </div>
</div>

<div class="shaded-screen"></div>