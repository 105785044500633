import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: 'search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.css'],
})

export class SearchInputComponent {

  @ViewChild("search") inputElement!: ElementRef;
  @Input() searchInput!: string;
  @Input() placeholder: string = 'Search';
  @Input() autofocus: boolean = true;
  @Output() searchInputChange = new EventEmitter<string>();

  constructor(
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
    private el: ElementRef
  ) { 
    this.matIconRegistry.addSvgIcon(
      'search',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/search.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'x',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/x.svg'
      )
    );
  }

  ngAfterViewInit(): void {
    if(this.autofocus){
      //this.inputElement.nativeElement.focus();
    }
  }
  removeSearch(){
    this.searchInput = '';
    this.searchInputChange.emit(this.searchInput);
  }
}