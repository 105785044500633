import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { GlobalStrings } from 'src/app/constants';
import { ToasterService } from 'src/app/services/toastr.service';

@Component({
  selector: 'message-input',
  templateUrl: './message-input.component.html',
  styleUrls: ['./message-input.component.css']
})
export class MessageInputComponent implements OnInit {

  constructor(
    private toastr: ToasterService,
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
  ) {
    this.matIconRegistry.addSvgIcon(
      'send',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/send.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'emoji_icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/emoji_icon.svg'
      )
    );
  }

  @Output() textChange = new EventEmitter<string>();
  @Output() sendClicked = new EventEmitter<string>();
  @Input() message: string = '';
  @Input() maxChar: number = 1024;
  @Input() isButtons: boolean = true;
  @Input() rows: number = 5;
  @ViewChild('body') body!: ElementRef;
  showEmojiPicker: boolean = false;
  messageCursorPosition: number = 0;

  ngOnInit(): void {
  }

  openEmojiPicker() {
    this.showEmojiPicker = !this.showEmojiPicker;
    this.messageCursorPosition = this.body.nativeElement.selectionStart;
  }
  addEmoji(event: any) {
    let updatedString = this.message.substring(0, this.messageCursorPosition) +event.emoji.native +this.message.substring(this.messageCursorPosition);
    this.message = updatedString;
    this.messageCursorPosition++;
    this.emitChange();
  }

  emitChange() {
    this.textChange.emit(this.message);
  }
}
