<div class="number-input-container" [ngClass]="dollar ? 'show-dollar' : ''">
  <span class="dollar">$</span>
  <input type="number" 
    #numberInputField
    matInput
    [(ngModel)]="inputNumber" 
    (keypress)="checkLength($event)" 
    (keyup)="restrictLength()"
    [placeholder]="placeholder" 
    [disabled]="disable"
    (focusout)="focusout.emit(true)"
    (keyup.enter)="enterClicked.emit(inputNumber)"
    (ngModelChange)="numberChange.emit(inputNumber)">
  <span *ngIf="upDownArrow" class="number-up-down">
    <icon outlineOnClick (click)="increase()" [icon]="'sort'" [size]="'20px'"></icon>
    <icon outlineOnClick (click)="decrease()" [icon]="'sort'" style="rotate: 180deg;" [size]="'20px'"></icon>
  </span>
</div>
