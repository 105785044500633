import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hhmmss'
})
export class HhmmssPipe implements PipeTransform {

  transform(value: Date | string | undefined): string {
    if(value == undefined) return '';
    let date = new Date(value)
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const ampm = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    const strHours = hours < 10 ? '0' + hours : hours;
    const strMinutes = minutes < 10 ? '0' + minutes : minutes;
    const strSeconds = seconds < 10 ? '0' + seconds : seconds;

    return `${strHours}:${strMinutes}:${strSeconds} ${ampm}`;
  }

}
