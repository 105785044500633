import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: 'footer-cancel-save',
  templateUrl: './footer-cancel-save.component.html',
  styleUrls: ['./footer-cancel-save.component.css'],
})

export class FooterCancelSaveComponent {

  @Output() saveClicked = new EventEmitter<boolean>();
  @Output() cancelClicked = new EventEmitter<boolean>();
  @Output() deleteClicked = new EventEmitter<boolean>();

  @Input() saveButtonText!: string;
  @Input() cancelButtonText!: string;
  @Input() deleteButtonText!: string;
  @Input() saveDisabledFlag!: boolean;
  @Input() cancelDisabledFlag!: boolean;
  @Input() deleteDisabledFlag!: boolean;
  @Input() saveHiddenFlag!: boolean;
  @Input() cancelHiddenFlag!: boolean;
  @Input() deleteHiddenFlag!: boolean;

  constructor(){}
}