import { Component, Input } from '@angular/core';
import { Label } from 'src/app/models/label';

@Component({
  selector: 'label-component',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.css'],
})

export class LabelComponent {

  @Input() labelElement!: Label;
}