<div class="assign-label-popup" *ngIf="popupOpen">
  <div class="main-container" [ngClass]="currentScreen === 'dialing' ? 'dialing' : ''">
    <div class="white-card" *ngIf="currentScreen === 'dialing'">
      <div class="white-card-heading">
        <span class="card-heading-title">Start a call </span>
        <span class="card-heading-description">Make the call from</span>
      </div>
      <div class="cloud-number-container">
        <div class="dropdown" style="max-width: 420px;">
          <button
          class="btn btn-custom-dropdown dropdown-toggle variable-dropdown"
          type="button"
          id="variableDropdownMenu"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          >
            <span class="current-inbox">
              <span>{{currentInbox?.icon}}</span>
              <span class="inbox-name">{{currentInbox?.name}} - </span>
              <span>{{currentInbox?.number}}</span>
              <ng-container *ngIf="currentInbox?.agent?.id">
                <span><hr></span>
                <span style="display: flex;gap: 4px;"><img [src]="currentAgent?.profile_pic" uiImageLoader alt=""> {{currentInbox?.agent?.agent_name}}</span>
              </ng-container>
            </span>
          </button>
          <ul
            class="dropdown-menu custom-dropdown-menu variable-dropdown-menu"
            aria-labelledby="headerDropdownMenu"
          >
            <ng-container *ngFor="let number of cloudNumberList">
              <li class="variable-type-heading" (click)="currentInbox = number">
                <span class="current-inbox">
                  <span>{{number?.icon}}</span>
                  <span class="inbox-name">{{number?.name}} - </span>
                  <span>{{number?.number}}</span>
                  <ng-container *ngIf="number?.agent?.id">
                    <span><hr></span>
                    <span style="display: flex;gap: 4px;"><img [src]="number?.agent?.profile_pic ?? '../../../../assets/images/avatar.png'" uiImageLoader alt=""> {{number?.agent?.agent_name}}</span>
                  </ng-container>
                </span>
              </li>
            </ng-container>
          </ul>
        </div>
        <!-- <inbox-dropdown (number)="inboxChange($event)"></inbox-dropdown> -->
      </div>
      <div class="call-number">
        <input 
          class="call-number-input" 
          type="text" 
          id="search-input" 
          [(ngModel)]="numberDetails.number"
          (keydown)="searchContactsEvent($event)"
          (keypress)="restrictLength($event)"
          placeholder="Enter the name or phone number"
        >
        <!-- <span [ngClass]="{active: dialerPad}" class="dialpad-icon" (click)="dialerPad = !dialerPad">
          <mat-icon svgIcon="dialer_pad"></mat-icon>
        </span> -->
      </div>
      <div class="search-contacts-list" *ngIf="numberDetails.number.length > 2">
        <span class="card-heading-description">Contacts</span>
        <div class="contact-item" *ngIf="customerList?.length === 0 && numberDetails.number.length > 2">
          <div class="contact-item-description">
            <span>
              <expiry-icon [name]="numberDetails.number"></expiry-icon>
            </span>
            <span>
              <p class="name" style="display: flex;gap: 6px">{{numberDetails.number}}</p>
              <p class="number" style="display: flex;gap: 6px">{{numberDetails.number}}</p>
            </span>
          </div>
          <span [ngClass]="callingIndex === -1 ? 'loading' : ''">
            <button *ngIf="!isLoading" class="contact-item-call-button" (click)="callNumber(null, -1)">
              Start call
            </button>
            <span *ngIf="isLoading" class="contact-item-call-button">
              <mat-progress-spinner
                mode="indeterminate"
                diameter="22"
                class="white-progress"
              ></mat-progress-spinner>
            </span>
          </span>
        </div>
        <div class="contact-item" *ngFor="let customer of customerList; let i=index">
          <div class="contact-item-description">
            <span>
              <expiry-icon [name]="customer | name"></expiry-icon>
            </span>
            <span>
              <p class="name">{{customer | name}}</p>
              <p class="number">{{customer?.mobile | numberFormat}}</p>
            </span>
          </div>
          <span [ngClass]="callingIndex === i ? 'loading' : ''">
            <button *ngIf="!isLoading" [disabled]="isLoading" class="contact-item-call-button" (click)="callNumber(customer,i)">
              Start call
            </button>
            <span *ngIf="isLoading" class="contact-item-call-button">
              <mat-progress-spinner
                mode="indeterminate"
                diameter="22"
                class="white-progress"
              ></mat-progress-spinner>
            </span>
          </span>
        </div>
      </div>
      <!-- <div class="dialer-pad-container" *ngIf="dialerPad">
        <div class="dialer-buttons">
          <span (click)="dialerButtonClick(1)">1</span>
          <span (click)="dialerButtonClick(2)">2</span>
          <span (click)="dialerButtonClick(3)">3</span>
        </div>
        <div class="dialer-buttons">
          <span (click)="dialerButtonClick(4)">4</span>
          <span (click)="dialerButtonClick(5)">5</span>
          <span (click)="dialerButtonClick(6)">6</span>
        </div>
        <div class="dialer-buttons">
          <span (click)="dialerButtonClick(7)">7</span>
          <span (click)="dialerButtonClick(8)">8</span>
          <span (click)="dialerButtonClick(9)">9</span>
        </div>
        <div class="dialer-buttons">
          <span (click)="dialerButtonClick(-2)">+</span>
          <span (click)="dialerButtonClick(0)">0</span>
          <span (click)="dialerButtonClick(-1)"><mat-icon svgIcon="backspace"></mat-icon></span>
        </div>
      </div> -->
    </div>
  </div>
</div>

<div class="shaded-screen" (click)="closePopup()" *ngIf="currentScreen === 'dialing' && popupOpen"></div>