import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pascalCase'
})
export class PascalCasePipe implements PipeTransform {

  transform(value: any): string {
    if(value == undefined || value == null) return '';
    value = value.replace(/(\w)(\w*)/g,
        function(g0: any,g1: string,g2: string){return g1.toUpperCase() + g2.toLowerCase();});
    return value;
  }

}
