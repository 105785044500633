<div class="pagination" *ngIf="listLength > paginationSize">
  <span>Page {{currentPage}} of {{maxPage}}</span>
  <div class="pagination-buttons">
    <button [disabled]="currentPage === 1" (click)="pageFirst()">
      <mat-icon style="rotate: 180deg;" svgIcon="navigate_next2"></mat-icon>
    </button>
    <button [disabled]="currentPage === 1" (click)="pageLeft()">
      <mat-icon style="rotate: 180deg;" svgIcon="navigate_next"></mat-icon>
    </button>
    <button [disabled]="currentPage === maxPage" (click)="pageRight()">
      <mat-icon svgIcon="navigate_next"></mat-icon>
    </button>
    <button [disabled]="currentPage === maxPage" (click)="pageLast()">
      <mat-icon svgIcon="navigate_next2"></mat-icon>
    </button>
  </div>
</div>