<div class="search-bar-container">
  <mat-icon svgIcon="search"></mat-icon>
  <input
    type="text"
    class="search-control"
    id="search"
    [(ngModel)]="searchInput"
    (ngModelChange)="searchInputChange.emit(searchInput)"
    [placeholder]="placeholder"
    #search
  />
  <mat-icon style="cursor: pointer;" *ngIf="searchInput?.length !== 0" svgIcon="x" (click)="removeSearch()"></mat-icon>
</div>