import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'name-tag',
  templateUrl: './name-tag.component.html',
  styleUrl: './name-tag.component.css'
})
export class NameTagComponent implements OnInit{

  @Input() name!: string;
  initials: string = '';

  ngOnInit(): void {
    if(this.name) {
      let words = this.name.split(' ');
      if(words?.length > 1) {
        this.initials = words[0][0] + words[1][0];
        this.initials = this.initials.toUpperCase();
      } else {
        this.initials = words[0].substring(0,2).toUpperCase();
      }
    }
  }
}
