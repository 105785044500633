<div class="main-container">
  <div class="container-heading">
    <img [src]="this.agencyService.darkLogo" alt="">
  </div>
  <div class="expiry-card">
    <img src="../../../../assets/images/downtime.png" alt="">
    <span class="expiry-heading">Temporarily down for maintenance</span>
    <span class="expiry-description">We are currently performing scheduled maintenance to improve our services. We expect to be back online shortly. Thank you for your patience!</span>
  </div>
</div>

