import { Component } from '@angular/core';

@Component({
  selector: 'app-invalid-page',
  templateUrl: './invalid-page.component.html',
  styleUrl: './invalid-page.component.css'
})
export class InvalidPageComponent {

}
