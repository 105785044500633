import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: 'tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.css']
})

export class TagComponent {

  @Input() tagText!: string;
  @Input() tagColor!: string;
  @Output() close = new EventEmitter<boolean>();

}