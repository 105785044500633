export class WhatsappDetails {
  private _display_name: string;
  private _website: string;
  private _website2: string;
  private _category: Category;
  private _meta_business_id: string;
  private _waba_id: string;
  private _waba_phone_number_id: string;
  private _waba_number: string;
  private _about: string;
  private _description: string;
  private _email: string;
  private _profile_picture_url: string;
  private _created_at: string;
  private _update_at: string;
  private _catalog_id: string;
  private _meta_app_id: string;
  private _quality_rating: string;
  private _messaging_limit_tier: string;
  private _status: string;
  private _is_meta_webhook: boolean;
  private _address: string;
  private _provider: string;
  private _template_msg_allowed_at: boolean;
  private _synced_at: Date;
  private _template_namespace: string;
  private _timezone_id: number;
  private _business_verification_status: string;
  private _health_status: string;
  private _welcome_message_enabled: boolean;
  private _charge_on_attempt: boolean;

    public get charge_on_attempt(): boolean {
        return this._charge_on_attempt;
    }

    public set charge_on_attempt(charge_on_attempt: boolean) {
        this._charge_on_attempt = charge_on_attempt;
    }


    public get welcome_message_enabled(): boolean {
        return this._welcome_message_enabled;
    }

    public set welcome_message_enabled(welcome_message_enabled: boolean) {
        this._welcome_message_enabled = welcome_message_enabled;
    }

    public get health_status(): string {
        return this._health_status;
    }

    public set health_status(health_status: string) {
        this._health_status = health_status;
    }


    public get business_verification_status(): string {
        return this._business_verification_status;
    }

    public set business_verification_status(business_verification_status: string) {
        this._business_verification_status = business_verification_status;
    }


    public get timezone_id(): number {
        return this._timezone_id;
    }

    public set timezone_id(timezone_id: number) {
        this._timezone_id = timezone_id;
    }


    public get template_namespace(): string {
        return this._template_namespace;
    }

    public set template_namespace(template_namespace: string) {
        this._template_namespace = template_namespace;
    }


    public get synced_at(): Date {
        return this._synced_at;
    }

    public set synced_at(synced_at: Date) {
        this._synced_at = synced_at;
    }


    public get template_msg_allowed_at(): boolean {
        return this._template_msg_allowed_at ? true : false;
    }

    public set template_msg_allowed_at(template_msg_allowed_at: any) {
        this._template_msg_allowed_at = template_msg_allowed_at ? true : false;
    }


  constructor(whatsappDetailsJson: any) {
    this._display_name = whatsappDetailsJson?.display_name;
    this._website = whatsappDetailsJson?.website;
    this._website2 = whatsappDetailsJson?.website2;
    this._category = new Category(whatsappDetailsJson?.category);
    this._meta_business_id = whatsappDetailsJson?.meta_business_id;
    this._waba_id = whatsappDetailsJson?.waba_id;
    this._waba_phone_number_id = whatsappDetailsJson?.waba_phone_number_id;
    this._waba_number = whatsappDetailsJson?.waba_number;
    this._about = whatsappDetailsJson?.about;
    this._description = whatsappDetailsJson?.description;
    this._email = whatsappDetailsJson?.email;
    this._profile_picture_url = whatsappDetailsJson?.profile_picture_url;
    this._created_at = whatsappDetailsJson?.created_at;
    this._update_at = whatsappDetailsJson?.update_at;
    this._catalog_id = whatsappDetailsJson?.catalog_id;
    this._meta_app_id = whatsappDetailsJson?.meta_app_id;
    this._quality_rating = whatsappDetailsJson?.quality_rating;
    this._messaging_limit_tier = whatsappDetailsJson?.messaging_limit_tier;
    this._status = whatsappDetailsJson?.status;
    this._is_meta_webhook = whatsappDetailsJson?.is_meta_webhook;
    this._address = whatsappDetailsJson?.address;
    this._provider = whatsappDetailsJson?.provider;
    this._template_msg_allowed_at = whatsappDetailsJson?.template_msg_allowed_at ? true : false;
    this._synced_at = whatsappDetailsJson?.synced_at;
    this._template_namespace = whatsappDetailsJson?.template_namespace;
    this._timezone_id = whatsappDetailsJson?.timezone_id;
    this._business_verification_status = whatsappDetailsJson?.business_verification_status;
    this._health_status = whatsappDetailsJson?.health_status?.overall_status;
    this._welcome_message_enabled = whatsappDetailsJson?.welcome_message_enabled;
    this._charge_on_attempt = whatsappDetailsJson?.charge_on_attempt;
  }

  public get display_name(): string {
      return this._display_name;
  }
  public set display_name(display_name: string) {
      this._display_name = display_name;
  }
  public get website(): string {
      return this._website;
  }
  public set website(website: string) {
      this._website = website;
  }
  public get website2(): string {
      return this._website2;
  }
  public set website2(website2: string) {
      this._website2 = website2;
  }
  public get category(): Category {
    return this._category;
  }
  public set category(category: Category) {
    this._category = category;
  }
  public get meta_business_id(): string {
      return this._meta_business_id;
  }
  public set meta_business_id(meta_business_id: string) {
      this._meta_business_id = meta_business_id;
  }
  public get waba_id(): string {
      return this._waba_id;
  }
  public set waba_id(waba_id: string) {
      this._waba_id = waba_id;
  }
  public get waba_phone_number_id(): string {
      return this._waba_phone_number_id;
  }
  public set waba_phone_number_id(waba_phone_number_id: string) {
      this._waba_phone_number_id = waba_phone_number_id;
  }
  public get waba_number(): string {
      return this._waba_number;
  }
  public set waba_number(waba_number: string) {
      this._waba_number = waba_number;
  }
  public get about(): string {
      return this._about;
  }
  public set about(about: string) {
      this._about = about;
  }
  public get description(): string {
      return this._description;
  }
  public set description(description: string) {
      this._description = description;
  }
  public get email(): string {
      return this._email;
  }
  public set email(email: string) {
      this._email = email;
  }
  public get profile_picture_url(): string {
      return this._profile_picture_url;
  }
  public set profile_picture_url(profile_picture_url: string) {
      this._profile_picture_url = profile_picture_url;
  }
  public get created_at(): string {
      return this._created_at;
  }
  public set created_at(created_at: string) {
      this._created_at = created_at;
  }
  public get update_at(): string {
      return this._update_at;
  }
  public set update_at(update_at: string) {
      this._update_at = update_at;
  }
  public get catalog_id(): string {
      return this._catalog_id;
  }
  public set catalog_id(catalog_id: string) {
      this._catalog_id = catalog_id;
  }
  public get meta_app_id(): string {
      return this._meta_app_id;
  }
  public set meta_app_id(meta_app_id: string) {
      this._meta_app_id = meta_app_id;
  }
  public get quality_rating(): string {
      return this._quality_rating;
  }
  public set quality_rating(quality_rating: string) {
      this._quality_rating = quality_rating;
  }
  public get messaging_limit_tier(): string {
      return this._messaging_limit_tier;
  }
  public set messaging_limit_tier(messaging_limit_tier: string) {
      this._messaging_limit_tier = messaging_limit_tier;
  }
  public get status(): string {
      return this._status;
  }
  public set status(status: string) {
      this._status = status;
  }
  public get is_meta_webhook(): boolean {
      return this._is_meta_webhook;
  }
  public set is_meta_webhook(is_meta_webhook: boolean) {
      this._is_meta_webhook = is_meta_webhook;
  }
  public get address(): string {
    return this._address;
  }
  public set address(address: string) {
      this._address = address;
  }
  public get provider(): string {
    return this._provider;
  }
  public set provider(provider: string) {
      this._provider = provider;
  }

}

export class Category {
    private _category: string;
    private _category_code: string;
  
    public get category(): string {
      return this._category;
    }
  
    public set category(category: string) {
      this._category = category;
    }
  
    public get category_code(): string {
      return this._category_code;
    }
  
    public set category_code(category_code: string) {
      this._category_code = category_code;
    }
  
    constructor(categoryJson: any){
      this._category = categoryJson?.category;
      this._category_code = categoryJson?.category_code;
    }
  }