<hlm-dialog [state]="isOpen ? 'open' : 'closed'" (closed)="close.emit(true)">
  <hlm-dialog-content *brnDialogContent="let ctx">
    <div class="card-heading">
      <span>Billing address</span>
    </div>
    <div class="billing-card-body">
      <div class="card-fields">
        <span class="field-name">Address</span>
        <text-input [inputText]="defaultBillingDetails?.address_line_1 ?? ''" (textChange)="defaultBillingDetails.address_line_1 = $event"></text-input>
      </div>
      <div style="display: flex;gap: 1rem">
        <div class="card-fields">
          <span class="field-name">City</span>
          <text-input [inputText]="defaultBillingDetails?.city ?? ''" (textChange)="defaultBillingDetails.city = $event"></text-input>
        </div>
        <div class="card-fields">
          <span class="field-name">State</span>
          <text-input [inputText]="defaultBillingDetails?.state ?? ''" (textChange)="defaultBillingDetails.state = $event"></text-input>
        </div>
      </div>
      <div style="display: flex;gap: 1rem">
        <div class="card-fields">
          <span class="field-name">Country</span>
          <text-input [inputText]="defaultBillingDetails?.country ?? ''" (textChange)="defaultBillingDetails.country = $event"></text-input>
        </div>
        <div class="card-fields">
          <span class="field-name">ZIP code</span>
          <text-input [inputText]="defaultBillingDetails?.zip ?? ''" (textChange)="defaultBillingDetails.zip = $event"></text-input>
        </div>
      </div>
    </div>
    <div class="footer-buttons">
      <save-button 
        [disableSave]="defaultBillingDetails.address_line_1 === '' || defaultBillingDetails.city === '' || defaultBillingDetails.state === '' || defaultBillingDetails.zip === '' || defaultBillingDetails.country === '' || loaderBillingDetails" 
        [saveButtonText]="'Confirm address'" 
        [loading]="loaderBillingDetails"
        (saveClicked)="addBillingDetails()"
      ></save-button>
    </div>
  </hlm-dialog-content>
</hlm-dialog>
