import { Pipe, PipeTransform } from '@angular/core';
import { PhoneNumberUtil } from 'google-libphonenumber';

@Pipe({
  name: 'numberFormat',
})
export class NumberFormatPipe implements PipeTransform {
  phoneNumberUtil = PhoneNumberUtil.getInstance();

  transform(value: string | undefined): string {
    if(value == undefined) return '';
    if(value){
      try {
        const phoneNumber1 = this.phoneNumberUtil.parseAndKeepRawInput(
          '+'+ value
        );
        let callingCode = phoneNumber1?.getCountryCode() ? ''+phoneNumber1.getCountryCode() : '';
        let number = this.phoneNumberUtil.getNationalSignificantNumber(phoneNumber1);
        let num = '('+number.slice(0,3)+') '+number.slice(3,6)+'-'+number.slice(6)
        value = '+' +callingCode + ' ' + num;
      
      } catch (er) {
        console.log(er)
      }
    }
    //return value.substring(2);
    return value;
  }
}
