<div class="assign-label-popup">
  <div class="white-card">
    <div class="white-card-heading">
      <span class="card-heading-title">Info</span>
    </div>
    <div class="card-edit-information">
      <span>
        <p class="edit-field-title">Contact name</p>
        <text-input [maxChars]="100" [inputText]="customerName" (textChange)="customerName = $event"></text-input>
      </span>
      <span>
        <p class="edit-field-title">Email Address</p>
        <email-input [inputEmail]="customerEmail" (emailChange)="customerEmail = $event"></email-input>
      </span>
      <span>
        <textarea-input [fieldName]="'Bio'" [inputText]="customerBio" (textChange)="customerBio = $event" [maxChars]="500"></textarea-input>
      </span>
      <div class="edit-date-gender">
        <span>
          <p class="edit-field-title">Date of Birth</p>
          <date-picker [date]="customerDOB" [endDate]="today" (dateChange)="dateChange($event)"></date-picker>
        </span>
        <span>
          <p class="edit-field-title">Gender</p>
          <div class="dropdown">
            <button
              class="btn btn-custom-dropdown dropdown-toggle gender-dropdown"
              type="button"
              id="genderDropdownMenu"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span class="gender-selected-type" *ngIf="customerGender">{{ getGenderValue(customerGender) }}</span>
              <span class="gender-selected-type" *ngIf="!customerGender">Select</span>
            </button>
            <ul
              class="dropdown-menu custom-dropdown-menu gender-dropdown-menu"
              aria-labelledby="genderDropdownMenu"
            >
              <li class="gender-dropdown-item" 
                (click)="customerGender = 1"
              >Male </li>
              <li class="gender-dropdown-item" 
                (click)="customerGender = 2"
              >Female</li>
              <li class="gender-dropdown-item" 
                (click)="customerGender = 3"
              >Others</li>
            </ul>
          </div>
        </span>
      </div>
      <span class="social-links">
        <p class="edit-field-title">Social links</p>
        <span class="social-links-element">
          <mat-icon svgIcon="facebook"></mat-icon>
          <website-input
            style="width: 100%;"
            [inputText]="fbUrl"
            (textChange)="fbUrl = $event"
            [isOptional]="true"
          ></website-input>
        </span>
        <!-- <span>
          <mat-icon svgIcon="instagram"></mat-icon>
          <website-input
            [inputText]="customer."
          ></website-input>
        </span> -->
        <span class="social-links-element">
          <mat-icon svgIcon="twitter"></mat-icon>
          <website-input
            style="width: 100%;"
            [inputText]="twitterUrl"
            (textChange)="twitterUrl = $event"
            [isOptional]="true"
          ></website-input>
        </span>
        <span class="social-links-element">
          <mat-icon svgIcon="linkedin"></mat-icon>
          <website-input
            style="width: 100%;"
            [inputText]="linkedinUrl"
            (textChange)="linkedinUrl = $event"
            [isOptional]="true"
          ></website-input>
        </span>
      </span>      
      <span *ngFor="let key of customFieldKeys">
        <p class="edit-field-title">{{ key.replaceAll('_',' ') }}</p>
        <text-input [maxChars]="128" [inputText]="customFields[key] ? customFields[key].toString() : ''" (textChange)="customFields[key] = $event"></text-input>
      </span>
    </div>
    <div class="footer-buttons">
      <cancel-button 
        [cancelButtonText]="'Cancel'"
        (cancelClicked)="cancelEdit()"
      ></cancel-button>
      <save-button
        [saveButtonText]="'Save'"
        [disableSave]="customerName == ''"
        [loading]="loaderSaveCustomer"
        (saveClicked)="updateCustomer()"
      ></save-button>
    </div>
  </div>
</div>

<div class="shaded-screen" (click)="cancelEdit()"></div>