import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.css']
})
export class ConfirmationComponent implements OnInit {

  @Input() heading!: string;
  @Input() body!: string;
  @Input() positiveText!: string;
  @Input() positive: boolean = false;
  @Input() negativeText!: string;
  @Output() confirm = new EventEmitter<boolean>();
  @Output() cancel = new EventEmitter<boolean>();
  
  constructor() {
    
  }

  ngOnInit(): void {
    
  }
}
