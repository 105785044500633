<div style="position: relative;">
  <quill-editor 
    id="editor" 
    class="content-editor" 
    [(ngModel)]="textContent" 
    (onContentChanged)="contentChanged($event)" 
    [modules]="modules" 
    [placeholder]="placeholder"
    [styles]="{
      'min-height': '200px',
    }"
    (onFocus)="isEditorActive = true"
    (onBlur)="isEditorActive = false"
  > </quill-editor>
  <div class="outer-border-container" [ngClass]="isEditorActive ? 'editor-active' : ''">
  </div>
</div>
