import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { BusinessService } from 'src/app/services/business.service';
import { CommonService } from 'src/app/services/common.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { ToasterService } from 'src/app/services/toastr.service';

@Component({
  selector: 'notification-bar',
  templateUrl: './notification-bar.component.html',
  styleUrls: ['./notification-bar.component.css']
})
export class NotificationBarComponent implements OnInit {

  loading: boolean = false;

  constructor(
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
    public sharedDataService: SharedDataService,
    private _swUpdate: SwUpdate,
    private _router: Router,
    private _businessService: BusinessService,
    private _toastr: ToasterService,
    private _commonService: CommonService,
  ) {
    this.matIconRegistry.addSvgIcon(
      'facebook_white',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/facebook_white.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'whatsapp_logo',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/whatsapp_logo.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'refresh',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/refresh.svg'
      )
    );
   }

  ngOnInit(): void {
  }

  reload() {
    this._swUpdate.activateUpdate()
      .then(() => document.location.reload())
      .catch((error) => {
        console.log(error);
        document.location.reload();
      })
  }

  routeToSubscription() {
    this._router.navigate(['/settings/subscription']);
  }

  checkHealthStatus() {
    this.loading = true;
    this._businessService.getMessagingHealthStatus(this.sharedDataService.whatsappDetails.waba_id).subscribe({
      next: resp => {
        console.log(resp);
        this._commonService.getWhatsAppDetails();
        this.loading = false;
      }, error: error => {
        this.loading = false;
        this._toastr.error(error)
      }
    })
  }

}
