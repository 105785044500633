<div class="audio-player">
  <span outlineOnClick style="cursor: pointer;" (click)="backward()"><icon [icon]="'replay_10'" [size]="'24px'"></icon></span>
  <button outlineOnClick (click)="toggleAudio()">
    <mat-icon *ngIf="!isPlaying" svgIcon="play_circle"></mat-icon>
    <mat-icon *ngIf="isPlaying" svgIcon="pause_circle"></mat-icon>
  </button>
  <span outlineOnClick style="cursor: pointer;" (click)="forward()"><icon [icon]="'forward_10'" [size]="'24px'"></icon></span>
  <div class="w-100" *ngIf="!waveLoading" #waveform></div>
  <div class="audio-loader" *ngIf="waveLoading">
    <ngx-skeleton-loader count="1" appearance="line"></ngx-skeleton-loader>
  </div>
  <span class="timer">{{ currentTime | timer }} / {{ duration | timer }}</span>
  <span outlineOnClick (click)="downloadAudio()" class="audio-player-download">
    <mat-icon svgIcon="download"></mat-icon>
  </span>
</div>