<hlm-dialog [state]="isOpen ? 'open' : 'closed'" (closed)="close.emit(true)">
  <hlm-dialog-content style="max-width: 645px;" *brnDialogContent="let ctx">
    <div class="card-fields">
      <div class="card-heading">
        <span>Connect your calendar</span>
      </div>
      <span class="description">AI will automatically find available slots and book events.</span>
    </div>
    <div class="card-fields">
      <span class="stepper-count">Step {{stepCount}} of 3</span>
      <span class="stepper-list">
        <div class="step" [ngStyle]="{'background-color': stepCount > 0 ? '#0A0A0A' : '#D4D4D4'}"></div>
        <div class="step" [ngStyle]="{'background-color': stepCount > 1 ? '#0A0A0A' : '#D4D4D4'}" ></div>
        <div class="step" [ngStyle]="{'background-color': stepCount > 2 ? '#0A0A0A' : '#D4D4D4'}" ></div>
      </span>
    </div>
    <div class="calendar-connections">
      <div class="calendar-item">
        <div class="calendar-type-card" (click)="goToIntegrations()">
          <img src="../../../../assets/images/gohighlevel.png" alt="">
          High Level
        </div>
        <div class="calendar-type-card">
          <img src="../../../../assets/images/google_calendar.svg" alt="">
          G Calendar
        </div>
        <div class="calendar-type-card">
          <img src="../../../../assets/images/cal.png" alt="">
          Cal.com
        </div>
        <div class="calendar-type-card">
          <img src="../../../../assets/images/calendly.png" alt="">
          Calendly
        </div>
      </div>
      <div class="calendar-preview">
        
      </div>
    </div>
    <div class="footer-buttons">
      <secondary-button *ngIf="stepCount !== 0" [buttonText]="'Back'" (clicked)="stepCount = stepCount - 1"></secondary-button>
      <save-button [saveButtonText]="'Continue'"
        (saveClicked)="stepCount = stepCount +1"></save-button>
    </div>
  </hlm-dialog-content>
</hlm-dialog>
