import { Pipe, PipeTransform } from '@angular/core';
import { SharedDataService } from '../services/shared-data.service';

@Pipe({
  name: 'currency'
})
export class CurrencyConversationPipe implements PipeTransform {

  DollarToRupee = 82;
  currency = 'USD';

  constructor(private _sharedService: SharedDataService) {
  }

  transform(value: number | string | undefined,decimal: number = 4): string {
    // let country  = this._sharedService.businessCountry;
    // if(country) {
    //   if(country?.toLowerCase() === 'india') this.currency = 'INR';
    //   else this.currency = 'USD';
    // } else {
    //   this.currency = this._sharedService.business?.currency?.code ?? 'USD' ;
    // }
    // if(this.currency === 'INR'){
      //   if(value == undefined) return '₹0.0';
      //   // if(decimal > 2) decimal = 2;
    //   let amount = ((value/100) * this.DollarToRupee).toFixed(decimal);
    //   return '₹'+amount;
    // } else {
      // }
    if(value == undefined) return '$0.0';
    if(typeof value === 'string') value = Number(value);
    let amount = ((value/100)).toFixed(decimal);
    return '$'+amount;
  }

}
