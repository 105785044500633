<hlm-dialog [state]="isOpen ? 'open' : 'closed'" (closed)="close.emit(true)">
  <hlm-dialog-content *brnDialogContent="let ctx">
    <div class="card-fields">
      <div class="card-heading">
        <span>Connect Cal.com</span>
      </div>
      <!-- <span class="description">Only USA phone numbers are available. The fee for the number is $2.00 per month.</span> -->
    </div>
    <div class="billing-card-body">
      <div class="card-fields">
        <span class="field-name">API key</span>
        <text-input [placeholder]="''" [inputText]="apiKey" (textChange)="apiKey = $event"></text-input>
        <span class="warning">Ensure that the API key is set to "Never Expires."</span>
      </div>
    </div>
    <div class="footer-buttons">
      <save-button 
        [disableSave]="isLoading" 
        [loading]="isLoading"
        [saveButtonText]="'Connect'" 
        (saveClicked)="connect()"
      ></save-button>
    </div>
  </hlm-dialog-content>
</hlm-dialog>
