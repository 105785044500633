<div class="shaded-screen"></div>

<div class="confirmation-popup">
  <div class="white-card">
    <div class="card-heading">
      <span>{{heading}}</span>
      <span style="cursor: pointer;" (click)="cancel.emit()"><i class="fa-solid fa-x"></i></span>
    </div>
    <div class="card-body">
      {{body}}
    </div>
    <div class="footer-buttons">
      <cancel-button class="cancel" [cancelButtonText]="negativeText" (cancelClicked)="cancel.emit(true)"></cancel-button>
      <cancel-button class="delete" *ngIf="!positive" [cancelButtonText]="positiveText" (cancelClicked)="confirm.emit(true)"></cancel-button>
      <save-button *ngIf="positive" [saveButtonText]="positiveText" (saveClicked)="confirm.emit(true)"></save-button>
    </div>
  </div>
</div>