import { Component, Input, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ToasterService } from 'src/app/services/toastr.service';

@Component({
  selector: 'toastr',
  templateUrl: './toastr.component.html',
  styleUrls: ['./toastr.component.css']
})
export class ToastrComponent implements OnInit {

  position: string = 'bottom-right';
  timeout: number = 3000;
  show: boolean = false;

  positionTop: number = 0;
  positionRight: number = 0;
  positionBottom: number = 0;
  positionLeft: number = 0;

  constructor(
    public _toastr: ToasterService,
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
  ) {
    this.matIconRegistry.addSvgIcon(
      'toast_warning',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/toast_warning.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'toast_success',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/toast_success.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'toast_info',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/toast_info.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'toast_error',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/toast_error.svg'
      )
    );
   }

  ngOnInit(): void {
    switch (this.position) {
      case 'top-right':
        this.positionTop = 10;
        this.positionRight = 10;
        break;
      case 'top-left':
        this.positionTop = 10;
        this.positionLeft = 10;
        break;
      case 'bottom-left':
        this.positionBottom = 10;
        this.positionLeft = 10;
        break;
      default:
        // bottom-right
        this.positionBottom = 10;
        this.positionRight = 10;
        break;
    }

    setTimeout(() => {
      this.show = true;
    }, 100);

    setTimeout(() => {
      this._toastr.hide();
    }, this._toastr.timeout);
  }

}
